import React, { Fragment, useState, useEffect, useCallback } from "react";
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import SMSContain from '../../Component/SMS';
import api from '../../api/jwt'
import LoadingSpinner from "./../../CommonElements/Loader/LoadingSpinner";

const SMSOverview = () => {
  const [stats, setStats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [business, setBusiness] = useState([]);

  useEffect(() => {
    document.title = 'SMS Overview - Spendo';
    const businesss = JSON.parse(sessionStorage.getItem('business'));
    setBusiness(businesss);

    setIsLoading(true);
    api.get('/sms/statistics/' + businesss.uuid)
      .then(res => {
          setIsLoading(false)
          console.log(res.data.data);
          setStats(res.data.data);
      })
      .catch(err => {
        setIsLoading(false)
        console.log(err.response);
      });
  }, []);

  return (
    <Fragment>
      <Breadcrumbs parent="Services" title="SMS Overview" />
      {isLoading ? <LoadingSpinner /> : <SMSContain business={business} stats={stats} />}
    </Fragment>
  );
};
export default SMSOverview;