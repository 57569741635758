import React, { Fragment, useState, useEffect, useCallback } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import SenderIDContain from "../../Component/SMS/senderIDReport";
import RequestSenderIDContain from "../../Component/SMS/requestSenderID";

import api from "../../api/jwt";
import { Button, Card } from "reactstrap";

export default function SenderReport() {
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const [senderId, setSendId] = useState([]);
  const [business, setBusiness] = useState([]);
  const [isNewID, setIsNewID] = useState(false);
  useEffect(() => {
    document.title = 'Sender ID - Spendo';
    setName(sessionStorage.getItem("Name"));
    const businesss = JSON.parse(sessionStorage.getItem("business"));
    setBusiness(businesss);
    api
      .get("/sms/sender/list/" + businesss.uuid)
      .then((res) => {
        setSendId(res.data.data);
      })
      .catch((err) => {
        
      });
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="Sender ID List" />
      <div className="d-flex justify-content-end my-2 mx-2">
        {" "}
        <Button
          color="primary"
          // className="align-self-center"
          onClick={() => {
            setIsNewID(!isNewID);
          }}
        >
          Request New Sender ID
        </Button>
      </div>

      <SenderIDContain business={business} sms_sendserID={senderId} />
      {isNewID && (
        <RequestSenderIDContain business={business} senderIds={senderId} />
      )}
    </Fragment>
  );
}
