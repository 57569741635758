import React, { Fragment } from 'react';
import { Image } from '../../../AbstractElements';
import { Btn, H5, LI, P, UL } from '../../../AbstractElements';
import { Col, Form, FormGroup,Card, Label, InputGroup, InputGroupAddon, InputGroupText, Input, Row } from 'reactstrap';
import api from '../../../api/jwt'


const ProfileContain = (props) => {
  const { business, businessAccountDetails } = props;

  return (
    <Fragment>
      <Col xl="8" lg="12" md="7" className="xl-65">
        <Row className="row">
          <Col sm="12">
            <Card>
            
              <div className="profile-post">
              <H5 attrH5={{ className: 'p-0' }}>
              Business Account Details
                </H5>
                
                <Row>
          <Col md="12 mb-3">
            <Label htmlFor="validationCustom01">Account Name</Label>
            <input readOnly className="form-control" value={businessAccountDetails?.accountName} type="text" placeholder="*********"/>
                  </Col>
                  
                  <Col md="12 mb-3">
            <Label htmlFor="validationCustom01">Account Number</Label>
            <input readOnly className="form-control" value={businessAccountDetails?.accountNumber} type="text" placeholder="*********"/>
                  </Col>
                  
                  <Col md="12 mb-3">
            <Label htmlFor="validationCustom01">Account Type</Label>
            <input readOnly className="form-control" value={businessAccountDetails?.accountType} type="text" placeholder="*********"/>
                  </Col>
                  

                  <Col md="12 mb-3">
            <Label htmlFor="validationCustom01">Bank Name</Label>
            <input readOnly className="form-control" value={businessAccountDetails?.bankName} type="text" placeholder="*********"/>
                  </Col>

                  <Col md="12 mb-3">
            <Label htmlFor="validationCustom01">Bank Code</Label>
            <input readOnly className="form-control" value={businessAccountDetails?.bankCode} type="text" placeholder="*********"/>
                  </Col>
                  
                
                </Row>
                
               
                
              </div>
            </Card>
          </Col>
        </Row>
      </Col >
    </Fragment >
  );
};
export default ProfileContain;