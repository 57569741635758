import { H5, P, Btn } from "../../AbstractElements";
import React, { Fragment, useState, useEffect } from "react";

import {
  Card,
  CardBody,
    CardHeader,
    CardFooter,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Modal, Button, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { usePaystackPayment } from "react-paystack";
import api from "../../api/jwt";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import axios from 'axios';



const htmlSample =
  `
  <div style="background-color: #f5f5f5; padding: 20px;">
  <h3>loading...</h3>
  </div>
`


const SamplePageContain = (props) => {
  const { business, templates, myTemplates } = props;
  const [modal, setModal] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const toggle = () => setModal(!modal);
  const [pillTab, setpillTab] = useState('1');


  // readHtmlFile 
  function readHtmlFile(url) {

    return htmlSample;
    // var config = {
    //   method: 'get',
    //   url: url,
    //   headers: {
    //     'Content-Type': 'application/json',
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Credentials": "true",
    //     "Access-Control-Allow-Methods": "*",
    //     "Accept": "application/json, text/plain, /", "Content-Type": "multipart/form-data"
    //   }
    // };

    // const response = await 
    //   axios(config)
    //     .then(function (response) {
    //       // console.log(response.data);
          
    //       return response.data;
    //     })
    //     .catch(function (error) {
    //       return error;
    //     })
    

    // return response

  }

  // createTemplate
  const createTemplate = (values) => {
    var self = this;
    api
      .post("/email/initiate/" + business.uuid + "/" + values?.templateName)
      .then((res) => {
        setTimeout(() => {
          toast.success(res.data.message);
          // redirect to template editor
          window.location.href = "/email/template-editor/" + res.data.data.templateId;
        }, 200);
        
      })
      .catch((err) => {
        if (err) {
          setTimeout(() => {
            toast.error("Oppss.." + err.response.data.message);
          }, 200);
        }
      });
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">

              </CardHeader>
              <CardBody>
                      <Nav className="nav-pills">
                        <NavItem>
                          <NavLink href="#javascript" className={pillTab === '1' ? 'active' : ''} onClick={() => setpillTab('1')}>Template Galley</NavLink>
                        </NavItem>
                        <NavItem >
                          <NavLink href="#javascript" className={pillTab === '2' ? 'active' : ''} onClick={() => setpillTab('2')}>My Templates</NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={pillTab}>
                  <TabPane className="fade show" tabId="1">
                  
                    <br/>
                  <div className="d-flex justify-content-end my-2 mx-2">
                                        <Button className="primary" type="button" onClick={() => {
                                                    // setSelectedTemplate(template);
                                                    setModal(true);
                                                  }}>
                        {/* plus icon */}
                                                    <i className="fa fa-plus"></i> New
                      </Button>
                                    </div>
                          <>
                          <Row>
                                  {templates.map((template) => (
                                      <Col sm="4">
                                            <Card>  
                                              <CardHeader>
                                                  <H5>{template?.templateName}</H5>
                                              </CardHeader>
                                              
                                              
                                              {/* cardBody background image */}
                                              <CardBody className="bg-image" style={{
                                                  backgroundImage: `url(${template?.image})`,
                                                  backgroundSize: "cover"
                                                  
                                              }}>
                                                  <br/>
                                                  <br/>
                                                  <br />
                                                {/* onclick select open modal */}
                                                  <Button className="primary" type="button" onClick={() => {
                                                    // setSelectedTemplate(template);
                                                    // setModal(true);
                                                    alert("Coming soon!")
                                                  }}>
                                                    Select
                                                  </Button>
                                                  <br/>
                                                  <br/>
                                                  <br/>
                                                </CardBody>
                                          </Card>
                                      </Col>
                                    ))}
                                </Row>
                            </>
                        </TabPane>
                  <TabPane tabId="2">
                  <>
                          <Row>
                                  {myTemplates.map((template) => (
                                      <Col sm="4">
                                            <Card>  
                                              <CardHeader>
                                                  <H5>{template?.templateName}</H5>
                                              </CardHeader>
                                              
                                        {/* cardBody background image */}
                                        {/* render templateContentUrlHtml */}


                                        {/* <div style={{ width: '300px', height: '200px' }}>
                                          <HtmlAsBackground html={readHtmlFile(template?.templateContentUrlHtml)
                                          } />

                                          
                                        </div> */}
                                        

                                              <CardBody className="bg-image" style={{
                                                  // backgroundImage: `url(${template?.templateContentUrlHtml})`,
                                                  // backgroundSize: "cover"
                                                  
                                              }}
                                        >
                                          <div
                                            style={{
                                              backgroundColor: 'white',
                                              width: '100%',
                                              height: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center'
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: readHtmlFile(template?.templateContentUrlHtml)
                                                
                                            }}
                                          />
                                                  <br/>
                                                  <br/>
                                                  <br />
                                                {/* onclick select open modal */}
                                                  <Button className="primary" type="button" onClick={() => {
                                                    window.location.href = "/email/template-editor/" + template?.templateId;
                                                  }}>
                                                    Select
                                                  </Button>
                                                  <br/>
                                                  <br/>
                                                  <br/>
                                                </CardBody>
                                          </Card>
                                      </Col>
                                    ))}
                                </Row>
                            </>

                        </TabPane>
                      </TabContent>
                              
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
            centered={true}
            fade={true}
            backdropTransition={{ timeout: 700 }}
            modalTransition={{ timeout: 700 }}

            isOpen={modal} toggle={toggle} className="">
          <ModalHeader toggle={toggle}>Create Template</ModalHeader>
              <ModalBody>
                
              <Formik
                  initialValues={{
                    templateName: ""
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.templateName) {
                      errors.templateName = "Required";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    // setTimeout(() => {
                    createTemplate(values);
                    setSubmitting(false);
                    // }, 400);
                  }}
                  
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit} className="mt-4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                        Name
                        <p>Give your template a unique name to reference later</p>
                        </Label>
                        <Input
                          type="input"
                          name="templateName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.templateName}
                          className="form-control"
                          defaultValue="1"
                        />

                        {errors.templateName && touched.templateName && errors.templateName}
                    </FormGroup>
                    
                      <button
                        variant="primary"
                        type="submit"
                        className="btn btn-block btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Loading..." : "Create"}
                      </button>
                    </Form>
                  )}
                </Formik>

            </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '} */}
            {/* <Button color="secondary" onClick={toggle}>Close</Button> */}
          </ModalFooter>
        </Modal>
      </Container>
    </Fragment>
  );
};
export default SamplePageContain;
