import { H5, P } from "../../AbstractElements";
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
} from "reactstrap";
import api from "../../api/jwt";
import { toast } from "react-toastify";
const NGNcurrencyFormat = require('../../utils/NGNcurrencyFormat');
const PageContain = (props) => {
        const { business, order, product } = props;
        const updateOrderStatus = (status) => {
                const data = {
                        status: status
                }
                api
                .post("/product/order/updateOrderStatus/" + order?.orderId + "/" + order?.productId, data)
                .then((res) => {
                  toast.success(res.data);
                  window.location.reload();
                })
                .catch((err) => {
                  toast.error("Error updating order" + err.response.data);
                });
        }

        const updateDeliveyStatus = (status) => {
                const data = {
                        status: status
                }
                api
                        .post("/product/delivery/updateDeliveryStatus/" + order?.orderId + "/" + order?.productId, data)
                        .then((res) => {
                                toast.success(res.data);
                                window.location.reload();
                        })
                        
                        .catch((err) => {
                                toast.error("Error updating order" + err.response.data);
                        });
        }

        const updatePaymentStatus = (status) => {
                const data = {
                        status: status
                }
                api
                        .post("/product/payment/updatePaymentStatus/" + order?.orderId + "/" + order?.productId, data)
                        .then((res) => {
                                toast.success(res.data);
                                window.location.reload();
                        })
                        .catch((err) => {
                                toast.error("Error updating order" + err.response.data);
                        });
        }
        
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-4">
                <H5>Order OrderView</H5>
                <span>New Shopping Experience</span>
              </CardHeader>
              <CardBody>
                
                {/* order detail list by order */}
                <Row>
                        <Col sm="6">
                    <Card>
                            <CardBody>
                                                                                          <h3>Order Detail</h3>
                        <hr/>
                            
                        <p>
                                <b>Recipient Name: </b>
                                {order?.recipientName}
                        </p>

                        <p>
                                <b>Recipient Phone: </b>
                                {order?.recipientPhone}
                        </p>

                        <p>
                                <b>Payment Method: </b>
                                {order?.paymentMethod}
                        </p>

                        <p>
                                <b>Order Status: </b>
                                {order?.orderStatus}
                        </p>

                        <p>
                                <b>Payment Status: </b>
                                {order?.paymentStatus}
                        </p>


                         <p>
                                <b>Delivery Status: </b>
                                {order?.deliveryStatus}
                        </p>
                         <p>
                                <b>Delivery Address: </b>
                                {order?.deliveryAddress}
                        </p>
                         <p>
                                <b>Amount: </b>
                                {NGNcurrencyFormat.default(order?.amount)}
                        </p>

                        

                          <FormGroup>
                                                                                                  <Label for="exampleSelect">Order Status</Label>
                                                                                                  <Input type="select" name="select" id="exampleSelect" onChange={(value) => {
                                                                                                                updateOrderStatus(value.target.value)
                                                                                                  }}>  
                                                                                                          
                                                                                                          <option value={ order?.orderStatus }>{ order?.orderStatus } (default)</option>
                                                                                                          <option>Accepted</option>
                                                                                                          <option>Processed</option>
                                                                                                          <option>Cancelled</option>
                                                                                                  </Input>
                                                                                                  
                                                                                          </FormGroup>
                                                                                          

                                                                                          <FormGroup>
                                                                                                  <Label for="exampleSelect">Delivery Status</Label>
                                                                                                  <Input type="select" name="select" id="exampleSelect" onChange={(value) => {
                                                                                                          updateDeliveyStatus(value.target.value)
                                                                                                  }}>
                                                                                                          <option value={order?.deliveryStatus}>{order?.deliveryStatus} (default)</option>
                                                                                                          <option>Delivered</option>
                                                                                                          <option>Not Delivered</option>
                                                                                                  </Input>
                                                                                          </FormGroup>
                                                                                          
                                                                                          <FormGroup>
                                                                                                  <Label for="exampleSelect">Payment Status</Label>
                                                                                                  <Input type="select" name="select" id="exampleSelect" onChange={(value) => {
                                                                                                          updatePaymentStatus(value.target.value)
                                                                                                  }}>
                                                                                                          <option value={order?.paymentStatus}>{order?.paymentStatus} (default)</option>
                                                                                                          <option>Paid</option>
                                                                                                          <option>Unpaid</option>
                                                                                                  </Input>
                                                                                                </FormGroup>


                        
                      </CardBody>
                    </Card>
                  </Col>

                  <Col sm="6">
                    <Card>
                            <CardBody>
                        <h3>Product Detail</h3>
                        <hr/>
                            
                       <p>
                                <b>Product Name: </b>
                                {product.productName}
                        </p>

                        
                        <p>
                                {/* <b>Product Image: </b> */}
                                <img src={product.productImage} width="200"></img>
                        </p>

                        <p>
                                <b>Product Name: </b>
                                {product.productName}
                        </p>

                        <p>
                                <b>Product Description: </b>
                                {product.productDescription}
                        </p>

                        <p>
                                <b>Product Category: </b>
                                {product.productCategory}
                        </p>
                        <p>
                                <b>Product Price: </b>
                                {NGNcurrencyFormat.default(product.productPrice)}
                        </p>

                    
                        
                      </CardBody>
                    </Card>
                  </Col>
                  </Row>

                

                </CardBody>
              
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default PageContain;
