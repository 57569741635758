import Logins from "../Auth/AuthLogin";
import ResetPassword from "../Auth/ResetPassword";
import SignUp from "../Auth/AuthRegister";
import Verify from "../Auth/Verify";
import Setup from "../Auth/Setup";
// import ErrorPage from "../Pages/errorPage";
import VerifyAccount from "../Auth/VerifyAccount";
import Pay from "../Pay/index";

// admin
import Verify2fa from "../Pages/Admin/Verify2fa/index";
import Validate2fa from "../Pages/Admin/Validate2fa/index";
import Validate2faUser from "../Pages/Validate2fa/index";



export const authRoutes = [
  { path: `${process.env.PUBLIC_URL}/login`, Component: <Logins /> },
  { path: `${process.env.PUBLIC_URL}/reset`, Component: <ResetPassword /> },
  { path: `${process.env.PUBLIC_URL}/`, Component: <Logins /> },
  { path: `${process.env.PUBLIC_URL}/register`, Component: <SignUp /> },
  { path: `${process.env.PUBLIC_URL}/verify`, Component: <Verify /> },
  { path: `${process.env.PUBLIC_URL}/setup`, Component: <Setup /> },
  { path: `${process.env.PUBLIC_URL}/external/email/verify`, Component: <VerifyAccount /> },
  { path: `${process.env.PUBLIC_URL}/pay`, Component: <Pay /> },
  
  // admin
  { path: `${process.env.PUBLIC_URL}/admin/verify2fa`, Component: <Verify2fa /> },
  { path: `${process.env.PUBLIC_URL}/admin/validate2fa`, Component: <Validate2fa /> },
  { path: `${process.env.PUBLIC_URL}/validate2fa`, Component: <Validate2faUser /> },


  // { path: `${process.env.PUBLIC_URL}/error`, Component: <ErrorPage /> },
  // { path: ``, Component: <ErrorPage /> },
  // { path: `*`, Component: <ErrorPage /> },
  // { Component: <ErrorPage /> },
];
