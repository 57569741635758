import { H5, P } from "../../AbstractElements";
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
} from "reactstrap";
import api from "../../api/jwt";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { phoneNumber } from "../../Constant";

const WhatsappSendContain = (props) => {
  const { business, senderIds } = props;
  const [isLoading, setIsLoading] = useState(false);

  const sendMessage = (phoneNumber, message) => {
    var self = this;
    setIsLoading(true);
    const payload = {
      businessId: business.uuid,
      message: message,
      phoneNumber: phoneNumber,
    };

    api
      .post("/whatsapp/message", payload)
      .then((res) => {
        setIsLoading(false);

        console.log(res);

        setTimeout(() => {
          toast.success("Message sent successfully");
        }, 200);
      })
      .catch((err) => {
        if (err) {
          setIsLoading(false);

          setTimeout(() => {
            toast.error("Oppss.." + err.response.data.message);
          }, 200);
        }
        console.log(err);
      });
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Send Whatsapp Message</H5>
                <span>Use our WhatsApp API to send Notifications</span>
              </CardHeader>
              <CardBody>
                <Formik
                  initialValues={{
                    message: "",
                    phoneNumber: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.message) {
                      errors.message = "Required";
                    } else if (values.message.length < 10) {
                      errors.message = "Must be at least 10 characters";
                    }
                    if (!values.phoneNumber) {
                      errors.phoneNumber = "Required";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    // setTimeout(() => {
                    // window.alert(JSON.stringify(values, null, 2));
                    sendMessage(values.phoneNumber, values.message);
                    setSubmitting(false);
                    // }, 400);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit} className="mt-4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                          Phone Number
                        </Label>
                        <Input
                          type="text"
                          name="phoneNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phoneNumber}
                          className="form-control"
                          // id="exampleFormControlTextarea1"
                          // rows="3"
                          placeholder="Enter phone number with country code"
                        />
                        {/* {errors.message && touched.message && errors.message} */}
                        {errors.phoneNumber &&
                          touched.phoneNumber &&
                          errors.phoneNumber}
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="exampleFormControlTextarea1">
                          Message
                        </Label>
                        <Input
                          type="textarea"
                          name="message"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.message}
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          placeholder="Enter message"
                        />
                        {errors.message && touched.message && errors.message}
                      </FormGroup>
                      <button
                        variant="primary"
                        type="submit"
                        className="btn btn-block btn-primary"
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading..." : "Send"}
                      </button>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default WhatsappSendContain;
