import { H5, P, Btn } from '../../AbstractElements';
import React, { Fragment, useState, useEffect } from 'react';

import { Card, CardBody, CardHeader, Container, Row, Col, Form, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, Input,  Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { usePaystackPayment } from 'react-paystack';
import api from '../../api/jwt'
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';


const SamplePageContain = (props) => {
  const { business, contactList } = props;
  const { history } = props;
  const [modal, setModal] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const toggle = () => setModal(!modal);
  const toggle2 = () => setModal2(!modal2);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // get id from url
  const id = window.location.pathname.split('/')[3];

  const createContact = (values) => {
    var self = this;
    const payload = {
      contactAddress: values?.contactAddress,
      contactBirthday: values?.contactBirthday,
      contactCity: values?.contactCity,
      contactCountry: values?.contactCountry,
      contactEmail: values?.contactEmail,
      contactName: values?.contactName,
      contactPhone: values?.contactPhone,
      contactState: values?.contactState,
      contactZipCode: values?.contactZipCode,
    };
    api
      .post("/email/contact/create/" + business.uuid + '/' + id, payload)
      .then((res) => {
        setTimeout(() => {
          toast.success(res.data.message);
          window.location.reload();
        }, 200);
        
      })
      .catch((err) => {
        if (err) {
          setTimeout(() => {
            toast.error("Oppss.." + err.response.data.message);
          }, 200);
        }
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
      const headers = {
      headers: { "Content-Type": "multipart/form-data" },
      }
    api
    .post("/email/contact/upload/" + business.uuid + "/" + id, formData, headers)
      .then((res) => {
        setIsLoading(false);
        setTimeout(() => {
          toast.success(res.data.message);
          window.location.reload();
        }, 200);
    })
      .catch((err) => {
        setIsLoading(false);
        if (err) {
          setTimeout(() => {
            toast.error("Oppss.." + err.response.data.message);
          }, 200);
        }
    });


  }
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0])
  }

  const columns = [
    {
      name: "Name",
      selector: (row) => row.contactName,
      sortable: true
    },
    {
      name: "Phone",
      selector: (row) => row.contactPhone,
      // sortable: true
    },
    {
      name: "Email",
      selector: (row) => row.contactEmail,
      sortable: true,
      right: true
    },
    {
      name: "Address",
      selector: (row) => row.contactAddress,
      sortable: true,
      right: true
    },
    {
      name: "DOB",
      selector: (row) => row.contactBirthday,
      sortable: true,
      right: true
    },
    {
      name: "Country",
      selector: (row) => row.contactCountry,
      sortable: true,
      right: true
    },
    {
      name: "State",
      selector: (row) => row.contactState,
      sortable: true,
      right: true
    },
    {
      name: "City",
      selector: (row) => row.contactCity,
      sortable: true,
      right: true
    },
    {
      name: "ZipCode",
      selector: (row) => row.contactZipCode,
      sortable: true,
      right: true
    },
    {
      name: "DATE",
      selector: (row) => new Date(row.createdAt).toLocaleString(),

      sortable: true,
      right: true
    },
    {
      button: true,
      cell: (row) => (
        <>
          {/* delete contact */}
          <Button
            className="btn-icon btn-2"
            color="danger"
            type="button"
            onClick={() => {
              api
                .delete("/email/contact/delete/" + row.uuid)
                .then((res) => {
                  setTimeout(() => {
                    toast.success(res.data.message);
                    window.location.reload();
                  }, 200);
                })
                .catch((err) => {
                  if (err) {
                    setTimeout(() => {
                      toast.error("Oppss.." + err.response.data.message);
                    }, 200);
                  }
                });
            }}
          >
            <span className="btn-inner--icon">
              <i className="fa fa-trash" />
            </span>
          </Button>
          
        </>
      )
    }
  ];

    return (
      <Fragment>
        <Container fluid={true}>
          <Row>
            <Col sm="12">

              <div className="d-flex justify-content-end my-2 mx-2">
                <Button className="primary mr-10" type="button" onClick={() => {
                   setModal(true);
                }}>
                 Create a contact
                </Button>
                <Button className="info" type="button" onClick={() => {
                   setModal2(true);
                }}>
                 Import CSV
                </Button>
              </div>


              <Card>
                <CardHeader className="pb-0">   
                <div className="table-responsive support-table">
                  <DataTable
          // title="Transactions"
          columns={columns}
          data={contactList}
          defaultSortFieldID={1}
                      pagination
          striped={true}
          center={true}
          // paginationComponent={BootyPagination}
          // selectableRows
          // selectableRowsComponent={BootyCheckbox}
                    />
                    </div>  
    
                </CardHeader>
                <CardBody>
<p>-</p>
                </CardBody>
              </Card>
            </Col>
          </Row>


          <Modal
            centered={true}
            fade={true}
            backdropTransition={{ timeout: 700 }}
            modalTransition={{ timeout: 700 }}

            isOpen={modal} toggle={toggle} className="">
          <ModalHeader toggle={toggle}>Create a new Contact</ModalHeader>
              <ModalBody>
                
              <Formik
                  initialValues={{
                    contactName: "",
                    contactEmail: "",
                    contactPhone: "",
                    contactAddress: "",
                    contactBirthday: "",
                    contactCity: "",
                    contactCountry: "",
                    contactState: "",
                    contactZipCode: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.contactName) {
                      errors.contactName = "Required";
                    }
                    if (!values.contactEmail) {
                      errors.contactEmail = "Required";
                    }
                    if (!values.contactPhone) {
                      errors.contactPhone = "Required";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    // setTimeout(() => {
                    createContact(values);
                    setSubmitting(false);
                    // }, 400);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit} className="mt-4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                        Name
                        </Label>
                        <Input
                          type="input"
                          name="contactName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactName}
                          className="form-control"
                          defaultValue="1"
                        />

                        {errors.contactName && touched.contactName && errors.contactName}
                    </FormGroup>
                    
                    <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                        Phone Number
                        </Label>
                        <Input
                          type="input"
                          name="contactPhone"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactPhone}
                          className="form-control"
                          defaultValue="1"
                        />

                        {errors.contactPhone && touched.contactPhone && errors.contactPhone}
                    </FormGroup>
                    
                    <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                        Email
                        </Label>
                        <Input
                          type="input"
                          name="contactEmail"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactEmail}
                          className="form-control"
                          defaultValue="1"
                        />

                        {errors.contactEmail && touched.contactEmail && errors.contactEmail}
                    </FormGroup>
                    

                    <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                        Address
                        </Label>
                        <Input
                          type="input"
                          name="contactAddress"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactAddress}
                          className="form-control"
                          defaultValue="1"
                        />

                        {errors.contactAddress && touched.contactAddress && errors.contactAddress}
                    </FormGroup>
                    
                    <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                        Birthday
                        </Label>
                        <Input
                          type="input"
                          name="contactBirthday"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactBirthday}
                          className="form-control"
                          defaultValue="1"
                        />

                        {errors.contactBirthday && touched.contactBirthday && errors.contactBirthday}
                    </FormGroup>
                    
                    <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                        City
                        </Label>
                        <Input
                          type="input"
                          name="contactCity"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactCity}
                          className="form-control"
                          defaultValue="1"
                        />

                        {errors.contactCity && touched.contactCity && errors.contactCity}
                    </FormGroup>
                    
                    <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                        Country
                        </Label>
                        <Input
                          type="input"
                          name="contactCountry"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactCountry}
                          className="form-control"
                          defaultValue="1"
                        />

                        {errors.contactCountry && touched.contactCountry && errors.contactCountry}
                    </FormGroup>
                    
                    <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                        State
                        </Label>
                        <Input
                          type="input"
                          name="contactState"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactState}
                          className="form-control"
                          defaultValue="1"
                        />

                        {errors.contactState && touched.contactState && errors.contactState}
                    </FormGroup>
                    
                    <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                        ZipCode
                        </Label>
                        <Input
                          type="input"
                          name="contactZipCode"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactZipCode}
                          className="form-control"
                          defaultValue="1"
                        />

                        {errors.contactZipCode && touched.contactZipCode && errors.contactZipCode}
                      </FormGroup>

                      <button
                        variant="primary"
                        type="submit"
                        className="btn btn-block btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Loading..." : "Create"}
                      </button>
                    </Form>
                  )}
                </Formik>

            </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '} */}
            {/* <Button color="secondary" onClick={toggle}>Close</Button> */}
          </ModalFooter>
          </Modal>
          



          <Modal
            centered={true}
            fade={true}
            backdropTransition={{ timeout: 700 }}
            modalTransition={{ timeout: 700 }}
            isOpen={modal2} toggle={toggle2} className="">
          <ModalHeader toggle={toggle2}>Upload Contacts</ModalHeader>
            <ModalBody>

              <p>
                <strong>Upload Contacts from CSV files</strong>
              </p>

              <Link
                href="https://docs.google.com/spreadsheets/d/1F35NsOBswHRkdwZF4Hr7KRS53DzZ3N4M03uxuuyLby8/edit?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Sample Contact List
              </Link>

              
            <form onSubmit={handleSubmit} className="mt-4">
                  <input type="file" onChange={handleFileSelect} accept="csv/*" className="form-control" />
                  <br/>
                  <button
                        variant="primary"
                        type="submit"
                        className="btn btn-block btn-primary"
                        disabled={isLoading}
                      >
                        {isLoading ? "Uploading..." : "Upload"}
                      </button>
            </form>
                
              

            </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '} */}
            {/* <Button color="secondary" onClick={toggle}>Close</Button> */}
          </ModalFooter>
        </Modal>

        </Container>
      </Fragment>
    );
};
export default SamplePageContain;