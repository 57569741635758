import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import ListContain from '../../Component/Contacts/list';
import api from '../../api/jwt'


export default function Report() {
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('');
  const [contactList, setContactList] = useState([]);
  const [business, setBusiness] = useState([]);
  useEffect(() => {
    document.title = 'Contact List - Spendo';
    setName(sessionStorage.getItem('Name')); 
    const businesss = JSON.parse(sessionStorage.getItem('business'));
    setBusiness(businesss);
    const authenticated = JSON.parse(sessionStorage.getItem('authenticated'));
    const auth0_profile = JSON.parse(sessionStorage.getItem('auth0_profile'));
    const business_list = JSON.parse(sessionStorage.getItem('business_list'));
    const token = sessionStorage.getItem('token');

    api.get('/email/contactlists/' + businesss.uuid)
      .then(res => {
        setContactList(res.data.data);
      })
      .catch(err => {
      });

  }, []);
  return (
<Fragment>
      <Breadcrumbs parent="Pages" title="Contact List" />
      <ListContain business={business} contactList={contactList} />
    </Fragment>
  )
}
