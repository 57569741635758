import { H5, P } from '../../AbstractElements';
import React, { Fragment } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import {
  Chart as ChartJS,ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Pie, Line } from 'react-chartjs-2';
// import faker from 'faker';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// ChartJS.register(ArcElement, Tooltip, Legend);

const SmsPageContain = (props) => {
  const { business, stats } = props;
  const pieData = {
    labels: ['Failed SMS', 'Sent SMS',  'Pending SMS'],
    datasets: [
      {
        label: '# of SMS',
        data: [stats?.totalSmsFailedToday || 0, 19, 3, stats?.totalSmsSentToday || 0, 2, stats?.totalSmsPendingToday || 0],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
  };
  
  const labels = ['Today', 'This Week', 'This Month', 'This Year'];
  
  const lineData = {
    labels,
    datasets: [
      {
        label: 'Failed',
        data: [stats?.totalSmsFailedToday || 0, stats?.totalSmsFailedThisWeek || 0, stats?.totalSmsFailedThisMonth || 0, stats?.totalSmsFailedThisYear || 0],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Sent',
        data: [stats?.totalSmsSentToday || 0, stats?.totalSmsSentThisWeek || 0, stats?.totalSmsSentThisMonth || 0, stats?.totalSmsSentThisYear || 0],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };
    return (
      <Fragment>
        <Container fluid={true}>

          <Row>
            <Col sm="6">
            <Card>
                <CardHeader className="pb-0">
                <H5>Quick Overview{" "}</H5>
                </CardHeader>
                <CardBody>
                  {/* list all the stats */}
                  <Row>
                    <Col sm="6">
                      <P>Total SMS Sent</P>
                      <P>Total SMS Failed</P>
                      <P>Total SMS Pending</P>
                    </Col>
                    <Col sm="6">
                      <P>{stats?.totalSmsSent || 0}</P>
                      <P>{stats?.totalSmsFailed || 0}</P>
                      <P>{stats?.totalSmsPending || 0}</P>
                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </Col>
            <Col sm="6">
            <Card>
                <CardHeader className="pb-0">
                <H5>Today's{" "}</H5>
                </CardHeader>
                <CardBody>
                <Pie data={pieData} />
                </CardBody>
              </Card>
            </Col>
            </Row>
          <Row>
            <Col sm="12">
              <Card>
                {/* <CardHeader className="pb-0">
                </CardHeader> */}
                <CardBody>
                <Line options={options} data={lineData} />

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
};
export default SmsPageContain;