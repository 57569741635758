import React, { Fragment } from 'react';
import { Container, Row, Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import WalletBalance from './WalletBalance';
import Greeting from './Greeting';
import AccountActivity from './AccountActivity';
import TotalTrasactions from './TotalTrasactions';
import Yearly from './Yearly';
import microHub from '../../assets/images/MS_Startups_FH_lockup_hrz_1C_Blk.png';
import { Link } from 'react-router-dom';

const DefaultContain = (props) => {
  const val = true;
  const { business, name, wallet, dashboardStart, user } = props;
  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);

  React.useEffect(() => {
    if (dashboardStart) {
      setModal(true);
    }
    
  }, [dashboardStart]);

  return (
    <Fragment>
       
      <Container fluid={true} className="default-dash">
        <div className='user-profile'>
        {!user.twoFactorAuth ?
              <>
                {/* Important alaert about 2fa activation */}
                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>Important!</strong> Please activate two factor authentication for your account.  
                <Link to={`${process.env.PUBLIC_URL}/user/account`} className="btn btn-outline-white_color" >
                Enable 2factor now
                </Link>
                </div>
              </>
              :
              <></>
          }
        <Row>
          <Greeting name={ name } business={business} />
          <WalletBalance wallet={wallet} business={business} />
          <AccountActivity user={user} />
          <TotalTrasactions dashboardStart={dashboardStart} />
          <Yearly dashboardStart={dashboardStart} />
          <Modal
            centered={true}
            fade={true}
            backdropTransition={{ timeout: 700 }}
            modalTransition={{ timeout: 700 }}

            isOpen={modal} toggle={toggle} className="">
          <ModalHeader toggle={toggle}>🎉 Celebrating 1 Year of Spendo's Customer Experience Platform! 🚀✨
</ModalHeader>
              <ModalBody>
              {/* 🎉🎉  */}
          
              Thank you to our incredible clients and partners who have trusted us to enhance their customer relationships. 🤝💼 From automated, personalized messages to impactful promotions, our platform has made a difference in driving customer engagement and satisfaction.
<br/><br/>
📲💬 Join us in celebrating this milestone as we continue to empower businesses with seamless integration through our APIs and SDKs.
                <br /><br />
                🎈🥳 Here's to many more years of success and meaningful customer connections with Spendo! 🎉🚀

            </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '} */}
            <Button color="secondary" onClick={toggle}>Close</Button>
          </ModalFooter>
        </Modal>
        </Row>
  
          </div>
      </Container>
    </Fragment>
  );
};
export default DefaultContain;