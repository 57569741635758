import { H5, P, Btn } from '../../AbstractElements';
import React, { Fragment, useState, useEffect } from 'react';

import { Card, CardBody, CardHeader, Container, Row, Col, Form, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, Input, } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { usePaystackPayment } from 'react-paystack';
import api from '../../api/jwt'
import { Formik } from 'formik';
import { toast } from 'react-toastify';
const NGNcurrencyFormat = require('../../utils/NGNcurrencyFormat');

const WalletContain = (props) => {
  const { business, wallet, user } = props;
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [amount, setAmount] = React.useState(0);
  
  const onSuccess = (reference) => {
    setTimeout(() => {
      toast.success('Payment Successful');
      window.location.reload();
  }, 200);
  };
  const onClose = () => {
    setTimeout(() => {
      toast.error('Oppss.. Closed!');
  }, 200);
    console.log('closed')
  }
  const onSubmit = data => {
    if (data !== '') {
      setAmount(data.amount);
    } else {
      errors.showMessages();
    }
  };

  const handleChange2 = (event) => {
    setAmount(event.target.value);
  }

  const config = {
    reference: (new Date()).getTime().toString(),
    email: user?.email,
    amount: amount * 100,
    publicKey: process.env.REACT_APP_paystack_publicKey,
    metadata: {
      custom_fields: [
        {
          display_name: "Customer Name",
          variable_name: "mobile_number",
          value: "09876554321"
        }
      ],
      walletId: business?.walletId,
      amount: amount,
      fullname: business?.businessName,
      email: user?.email,
    }
  };
  
  const PaystackHookExample = () => {
    const initializePayment = usePaystackPayment(config);
    return (
      <div>
        
        <button className="btn btn-primary" onClick={() => {
          // minimum amount is 1000
              if (amount < 1000) {
                toast.error('Minimum amount is ₦1000');
              } else {
                initializePayment(onSuccess, onClose)
              }
            }}>Fund Now</button>
      </div>
    );
};
    return (
      <Fragment>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="pb-0">
                  <H5>Fund Wallet</H5>
                  <span>Current Balance: {NGNcurrencyFormat.default(wallet?.balance || 0.00)}</span>
                  <Formik
         initialValues={{ amount: ''}}
         validate={values => {
           const errors = {};
           if (!values.amount) {
             errors.amount = 'Amount is Required';
           }
           return errors;
         }}
         onSubmit={(values, { setSubmitting }) => {
           setTimeout(() => {
             setAmount(values.amount);
             setSubmitting(false);
           }, 400);
         }}
       >
         {({
           values,
           errors,
           touched,
           handleChange,
           handleBlur,
           handleSubmit,
           isSubmitting,
           /* and other goodies */
         }) => (
<Form onSubmit={handleSubmit} className="needs-validation">  
        <Row>
          <Col md="12 mb-3">
            <Label htmlFor="validationCustomUsername">Amount</Label>
            <InputGroup>
              <InputGroupText>{'₦'}</InputGroupText>
              <input className="form-control" name="amount" type="number" onChange={handleChange2}
                placeholder="amount"
                onBlur={handleBlur}
                value={amount} />
                            </InputGroup>
                            {/* {errors.amount && touched.amount && errors.amount} */}

          </Col>
        </Row>
                    
                    <PaystackHookExample />

                      </Form>
                      
                      )}
                      </Formik>
                </CardHeader>
                <CardBody>
<p></p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
};
export default WalletContain;