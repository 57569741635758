import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import ReportContain from '../../Component/SMS/report';
import api from '../../api/jwt'
import LoadingSpinner from "./../../CommonElements/Loader/LoadingSpinner";


export default function Report() {
  const [business, setBusiness] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = 'SMS Report - Spendo';
    const businesss = JSON.parse(sessionStorage.getItem('business'));
    setBusiness(businesss);
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="SMS Report" />
      {isLoading ? <LoadingSpinner /> : <ReportContain business={business} />}
    </Fragment>
  )
}
