import React, { Fragment, useState, useEffect } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import BsuinessPricingContain from "../../../Component/Admin/Business/pricing";
import api from "../../../api/jwt";
import LoadingSpinner from "./../../../CommonElements/Loader/LoadingSpinner";

const PricingUpdate = () => {
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const [pricing, setPricing] = React.useState([]);
  const [business, setBusiness] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const pathname = window.location.pathname;
  const businessId = pathname.split('/').pop();
  useEffect(() => {
    setName(sessionStorage.getItem("Name"));
    setIsLoading(true);
    api
      .get("/admin/pricing/" + businessId)
      .then((res) => {
        setPricing(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    
    // get business details
    api
      .get("/admin/business/" + businessId)
      .then((res) => {
        setBusiness(res.data.data.business);
        setIsLoading(false);
      })
      .catch((err) => {
        // setIsLoading(false);
       });
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Business" title="Pricing Update" />
      {isLoading ? <LoadingSpinner /> : <BsuinessPricingContain pricing={pricing} business={business} />}
    </Fragment>
  );
};
export default PricingUpdate;
