import React, { Fragment, useState, useEffect, useCallback } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import ProfileContain from "../../../Component/Admin/Users/Profile";
import api from "../../../api/jwt";
import LoadingSpinner from "../../../CommonElements/Loader/LoadingSpinner";

export default function Profile() {
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // get pathname from url
  const pathname = window.location.pathname;
  const uuid = pathname.split("/").pop();

  useEffect(() => {
    setName(sessionStorage.getItem("Name"));
    setIsLoading(true);
    api
      .get("/admin/user/" + uuid)
      .then((res) => {
        setIsLoading(false);
        setUser(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setIsLoading(false);
      });
  }, []);

  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="Users Profile" />
      {isLoading ? <LoadingSpinner /> : <ProfileContain user={user} />}
    </Fragment>
  );
}
