import React, { useState, Fragment, useEffect } from "react";
import { Container, Row, Col, TabContent, TabPane } from "reactstrap";
import ResetPasswordForm from "./ResetPasswordForm";

const ResetPassword = () => {
    useEffect(() => {
        document.title = "Reset Password - Spendo";
    }, []);
  return (
    <Fragment>
    <section>
        <Container fluid={true} className="p-0">
            <Row className="m-0">
                <Col xs='12' className="p-0">
                    <div className='login-card1'>
                        <ResetPasswordForm />
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
</Fragment >
  );
};

export default ResetPassword;
