import { H5, P } from '../../AbstractElements';
import React, { Fragment } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';

const SmsPageContain = () => {
    return (
      <Fragment>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="pb-0">
                  <H5>Whatsapp for Business{" "}</H5>
                  <span>  Use our WhatsApp API to send Notifications/Alerts and
                    Respond/Engage your Customers</span>
                  
                  <br />
                  <span>
                  Setup and get approved for your whatsapp Business APIs
                  </span>
                </CardHeader>
                <CardBody>
                <p>
              <b>Verified Facebook Business Manager ID</b>
            </p>
            <ul className="docs-list">
              <li>
                Don't have a Facebook Business Manager?{" "}
                {/* <Card.Link href="#" target="_blank"> */}
                  Crete Busienss ID
                {/* </Card.Link> */}
              </li>
              <li>
                Facebook Business Manager not verified?{" "}
                {/* <Card.Link href="#" target="_blank"> */}
                  Verify Now
                {/* </Card.Link> */}
              </li>
            </ul>

            <p>
              <b>Setup Mobile Number</b>
            </p>
            <ul className="docs-list">
              <li>
                Mobile number must not have an active WhatsApp account.{" "}
                {/* <Card.Link href="#" target="_blank"> */}
                  React
                {/* </Card.Link> */}
              </li>
              <li>
                Want to delete the WhatsApp account with the number?{" "}
                {/* <Card.Link href="#" target="_blank"> */}
                  Learn more
                {/* </Card.Link> */}
              </li>
            </ul>

            <p>
              <b>Whatsapp Display Name</b>
            </p>
            <ul className="docs-list">
              <li>
                Display Name must have a clear relation with the business.
              </li>
              <li>
                Display Name must adhere to WhatsApp Display Name Guidelines,{" "}
                {/* <Card.Link href="#" target="_blank"> */}
                  Read Guidelines{" "}
                {/* </Card.Link> */}
              </li>
            </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
};
export default SmsPageContain;