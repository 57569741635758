import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { H4, Image } from '../../AbstractElements';
import sidebar from '../../assets/images/side-bar.png';
import { Documentation } from '../../Constant';

const SidebarSection = () => {
    return (
        <Fragment>
            <div className="sidebar-img-section">
                <div className="sidebar-img-content">
                    <Image attrImage={{ className: 'img-fluid', src: `${sidebar}`, alt: '' }} />
                    <H4>Need Help ?</H4>
                    <a to='mailto:hi@getspendo.com' className='txt'>
                        Raise ticket at "hi@getspendo.com"
                    </a>
                    <a href='https://docs.getspendo.com' target="_blank" className="btn btn-secondary">
                        {Documentation}
                    </a>
                </div>
            </div>
        </Fragment>
    );
};
export default SidebarSection;