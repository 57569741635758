import React, { Fragment, useState, useEffect } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import SubscriptionsContain from "../../Component/Cart/subscriptions";
import api from "../../api/jwt";
import LoadingSpinner from "./../../CommonElements/Loader/LoadingSpinner";

const Subscriptions = () => {
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const [subscriptions, setSubscriptions] = React.useState([]);
  const [subscriptions_plans, setSubscriptionPlans] = React.useState([]);
  const [business, setBusiness] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    document.title = "Subscriptions - Spendo";
    setName(sessionStorage.getItem("Name"));
    const businesss = JSON.parse(sessionStorage.getItem("business"));
    setBusiness(businesss);
    const authenticated = JSON.parse(sessionStorage.getItem("authenticated"));
    const auth0_profile = JSON.parse(sessionStorage.getItem("auth0_profile"));
    const business_list = JSON.parse(sessionStorage.getItem("business_list"));
    const token = sessionStorage.getItem("token");
    setIsLoading(true);
    api
      .get("/subscriptions/check/" + businesss.uuid)
      .then((res) => {
        setSubscriptions(res.data);
        setIsLoading(false);
      })
      .catch((err) => { 
        setIsLoading(false);
      });
    
    // get subscriptions plans
    api
      .get("/subscriptions/plans/")
      .then((res) => {
        setIsLoading(false);
        setSubscriptionPlans(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Cart" title="Subscriptions" />
      {isLoading ? <LoadingSpinner /> : <SubscriptionsContain business={business} subscriptions={subscriptions} subscriptions_plans={subscriptions_plans} />}
    </Fragment>
  );
};
export default Subscriptions;
