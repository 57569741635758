import React, { Fragment, useEffect } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import PageContain from '../../Component/Email';

const EmailOverview = () => {
  useEffect(() => {
    document.title = 'Email Overview - Spendo';
  }, []);

  return (
    <Fragment>
      <Breadcrumbs parent="Services" title="Email Overview" />
      <PageContain />
    </Fragment>
  );
};
export default EmailOverview;