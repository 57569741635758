import { H5, P } from "../../AbstractElements";
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
} from "reactstrap";
import api from "../../api/jwt";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { phoneNumber } from "../../Constant";
import ReactFileReader from 'react-file-reader';

const SmsSendContain = (props) => {
  const { business, senderIds } = props;
  const [loading, isLoading] = React.useState(true);
  const [csvStatus, setCsvStatus] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [nlp, setNlp] = useState(false);
  // handle phoneNumber
  const handlePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleFiles = files => {
    var reader = new FileReader();
    reader.onload = function(e) {
      const csvArray = reader.result.split('\n');

      setCsvStatus(" Total Imported Number(s):  " + csvArray.length);

      csvArray.forEach((item, index) => {
        const trimmedItem = item.trim();
        const csvItem = trimmedItem.split(',');
      });

      setPhoneNumber(csvArray.toString());      
    }
    reader.readAsText(files[0]);
}

  const sendMessage = (message, senderId) => {
    var self = this;

    const payload = {
      businessId: business.uuid,
      sms: message,
      to: phoneNumber,
      from: senderId,
    };

    let baseUrl = "/sms/bulk/message";

    if (nlp)
      baseUrl = "/ai/sms/bulk";

    api
      .post(baseUrl, payload)
      .then((res) => {
        setTimeout(() => {
          toast.success("Message sent successfully");
          window.location.href = '/sms/report';
        }, 200);

        self.setState({
          isLoading: false,
          progress: 100,
          success: res.data.message,
          phoneNumber: "",
          message: "",
        });
      })
      .catch((err) => {
        if (err) {
          setTimeout(() => {
            toast.error("Oppss.." + err.response.data.message);
          }, 200);
        }
        console.log(err);
      });
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Send Bulk Message </H5>
                <span>
                  Send direct sms to individual numbers across any mobile
                  network
                </span>
                <P className="mb-0">
                    <strong>Important!</strong> SMS sending is now from 8am to 8pm
                    due to Nigerian regulations.
                  </P>
              </CardHeader>
              <CardBody>
                <Formik
                  initialValues={{
                    message: "",
                    phoneNumber: "",
                    senderId: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.message) {
                      errors.message = "Required";
                    } else if (!values.senderId) {
                      errors.senderId = "Select sender";
                    } else if (values.message.length < 10) {
                      errors.message = "Must be at least 10 characters";
                    }
                    if (!phoneNumber) {
                      errors.phoneNumber = "Required";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                    sendMessage(
                      // values.phoneNumber,
                      values.message,
                      values.senderId
                    );
                    setSubmitting(false);
                    }, 400);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit} className="mt-4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                          Sender ID
                        </Label>
                        <Input
                          type="select"
                          name="senderId"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.senderId}
                          className="form-control"
                          defaultValue="1"
                        >
                          <option defaultValue>Select Sender ID</option>
                          {senderIds.map((senderId, index) => {
                            return (
                              <option key={index} value={senderId.senderId}>
                                {senderId.senderId}
                              </option>
                            );
                          })}

                          {/* {senderIds.length === 0 && (
                            <option value="Spendoware">Spendoware (default)</option>
                          )} */}

                        </Input>
                        {errors.senderId && touched.senderId && errors.senderId}

                        {senderIds.length === 0 && (
                          <span className="text-danger">
                            You have not created any sender ID. Please create
                            sender ID to send message or use our default senderId
                          </span>
                            
                          )}
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                          Recipients
                        </Label>
                        <Input
                          type="textarea"
                          name="phoneNumber"
                          onChange={handlePhoneNumber}
                          onBlur={handleBlur}
                          value={phoneNumber}
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          placeholder="Enter phone numbers with country code seperated by commas"
                        />
                        {errors.phoneNumber &&
                          touched.phoneNumber &&
                          errors.phoneNumber}
                        <br/>
                        <ReactFileReader handleFiles={handleFiles} fileTypes={'.csv'}>
              <button  variant="outlined"
                        className="btn btn-block btn-info">Import CSV File</button>
              { " " }{csvStatus}
                        </ReactFileReader>
                        <span className="text-muted">
                          {phoneNumber.length > 0 ? `Total Recipients: ${phoneNumber.split(',').length}` : ''}
                        </span>
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="exampleFormControlTextarea1">
                          Message
                        </Label>
                        <Input
                          type="textarea"
                          name="message"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.message}
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          placeholder="Enter message"
                        />
                        
                        {/* count message length */}
                        <span className="text-muted">
                          {values.message.length} / 160{"   "}
                        </span>


                        {/* total message by count length */}
                        <span className="text-muted">
                          {values.message.length > 160
                            ? "    Total message: " +
                            Math.ceil(values.message.length / 160)
                            : ""}
                        </span>

                        {errors.message && touched.message && errors.message}
                      </FormGroup>
                        {/* check box to use Natural Language Processing */}
                        <FormGroup>
                        <Row>

                          <Col
                            className="d-flex justify-content-start ml-2">
                              <Input
                          type="checkbox"
                          name="nlp"
                          onChange={() => setNlp(!nlp)}
                          onBlur={handleBlur}
                          value={nlp}
                          className="form-control"
                            
                          />
                            <p>
                              { "  " } Use Natural Language Processing
                            </p>
                            </Col>
                        </Row>
                      </FormGroup>
                      <button
                        variant="primary"
                        type="submit"
                        className="btn btn-block btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Loading..." : "Send Message"}
                      </button>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default SmsSendContain;
