import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import TransactionContain from '../../Component/Transactions/index';
import api from '../../api/jwt'
import LoadingSpinner from "./../../CommonElements/Loader/LoadingSpinner";

export default function Transactions() {
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [business, setBusiness] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    document.title = 'Transactions - Spendo';
    setName(sessionStorage.getItem('Name')); 
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="Transactions" />
      {isLoading ? <LoadingSpinner /> : <TransactionContain />}
      
    </Fragment>
  )
}
