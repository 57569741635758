import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import OrdersContain from '../../../Component/Admin/Cart/orders';
import api from '../../../api/jwt'
import LoadingSpinner from "../../../CommonElements/Loader/LoadingSpinner";
import { Button, Card } from "reactstrap";

export default function Products() {
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('');
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setName(sessionStorage.getItem('Name')); 

    setIsLoading(true);
    api.get('/admin/mall/getAllOrders')
      .then(res => {
          setIsLoading(false)
          setOrders(res.data.data);
      })
      .catch(err => {
        setIsLoading(false)
      });

  }, []);
  return (
    <Fragment>
          <Breadcrumbs parent="Pages" title="Orders" />
      {isLoading ? <LoadingSpinner /> : <OrdersContain orders={orders} />}
      
    </Fragment>
  )
}
