import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs';
import UsersProfileContain from '../../../../Component/Admin/AdminUsers';
import api from '../../../../api/jwt'

const UsersProfile = () => {
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('');
  const [user, setUser] = useState([]);
  const [business, setBusiness] = useState([]);
  useEffect(() => {
    setName(sessionStorage.getItem('Name')); 
    api.get('/user')
      .then(res => {
        setUser(res.data);
      })
      .catch(err => {

      });

  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="User" title="User Account" />
      <UsersProfileContain user={user} />
    </Fragment>
  );
};
export default UsersProfile;