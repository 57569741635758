import React, { Fragment, useState, useEffect, useCallback, Component, useRef } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";

import api from "../../api/jwt";
import { Button, Card } from "reactstrap";
import { Link } from 'react-router-dom';
import EmailEditor from "react-email-editor";
import axios from 'axios';
import sample from '../../Data/emailtemplates/sample.json';
import { toast } from 'react-toastify';



const TemplateEditor = (props) => {
  const emailEditorRef = useRef(null);
  const [template, setTemplate] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [business, setBusiness] = useState([]);

  useEffect(() => {
    document.title = 'Email Template - Spendo';
    const businesss = JSON.parse(sessionStorage.getItem("business"));
    setBusiness(businesss);
    // get id from url
    const url = window.location.href;
    const id = url.substring(url.lastIndexOf('/') + 1);


  }, []);

  const exportHtml = async () => {
    emailEditorRef.current.editor.exportHtml(data => {
      const { design, html } = data
      // console.log('exportHtml', JSON.stringify(design))

    })
  }
  const sendEmail = async () => {
    const url = window.location.href;
    const id = url.substring(url.lastIndexOf('/') + 1);
    const businesss = JSON.parse(sessionStorage.getItem("business"));
    await emailEditorRef.current.editor.exportHtml(async data => {
    const { html } = data

    // create a html file from html
    const file = new Blob([html], { type: 'text/html' });

    const formData = new FormData();
      formData.append("file", file);
        const headers = {
        headers: { "Content-Type": "multipart/form-data" },
        }
      api
      .post(`/email/use/${businesss.uuid}/${id}`, formData, headers)
        .then((res) => {
          // setIsLoading(false);
          setTimeout(() => {
            toast.success(res.data.message);
            window.location.href = "/email/campaigns";
          }, 200);
      })
        .catch((err) => {
          // setIsLoading(false);
          if (err) {
            setTimeout(() => {
              toast.error("Oppss.." + err.response.data.message);
            }, 200);
          }
      });
    })

  }
  const saveDesign = () => {
    const url = window.location.href;
    const id = url.substring(url.lastIndexOf('/') + 1);
    const businesss = JSON.parse(sessionStorage.getItem("business"));

    emailEditorRef.current.editor.saveDesign(design => {
      delete design.counters;

      // create a json file from design
      const file = new Blob([JSON.stringify(design)], { type: 'application/json' });
      const templateSubject = null;
      const templateSenderName = null;
      const templateSenderEmail = null;
      const templateReplyToEmail = null;

      const formData = new FormData();
      formData.append("file", file);
        const headers = {
        headers: { "Content-Type": "multipart/form-data" },
        }
      api
      .post(`/email/file/${businesss.uuid}/${id}/${templateSubject}/${templateSenderName}/${templateSenderEmail}/${templateReplyToEmail}`, formData, headers)
        .then((res) => {
          // setIsLoading(false);
          setTimeout(() => {
            toast.success(res.data.message);
            // window.location.reload();
          }, 200);
      })
        .catch((err) => {
          // setIsLoading(false);
          if (err) {
            setTimeout(() => {
              toast.error("Oppss.." + err.response.data.message);
            }, 200);
          }
      });
      

    })
  }
  const onLoad = () => {
    // get id from url
    const url = window.location.href;
    const id = url.substring(url.lastIndexOf('/') + 1);
    const businesss = JSON.parse(sessionStorage.getItem("business"));

    api
    .get("/email/template/" + businesss.uuid + "/" + id)
    .then((res) => {
      setTemplate(res.data.data);
      var url = res.data.data.templateContentUrlJson;

      var config = {
        method: 'get',
        url: url,
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "*",
          "Accept":"application/json, text/plain, /","Content-Type": "multipart/form-data"
        }
      };
      
      axios(config)
        .then(function (response) {
          // load template
          emailEditorRef.current.editor.loadDesign(response.data);
      })
      .catch(function (error) {
        // console.log(error);
      });

    })
    .catch((err) => {
      setTimeout(() => {
        toast.error("Oppss.." + err.response.data.message);
        window.location.href = "/email/templates";

      }, 200);
    });
  }
  const onDesignLoad = (data) => {
    console.log('onDesignLoad', data)
  }

  return (
    <>
      <br/>
      <br/>
      <div className="d-flex justify-content-end my-2 mx-2">
        {/* div with margin */}
        <div
          className="d-flex justify-content-end my-2 mx-2"
          // style={{ width: "100%" }}
        >
          <Button className="primary" onClick={saveDesign}>
            {/* icon save */}
            <i className="fa fa-save" aria-hidden="true"></i> Save Progress
          </Button>
          </div>
        <Button className="success" onClick={sendEmail}>
          {/* icon sned */}
          <i className="fa fa-paper-plane" aria-hidden="true"></i> Send Mail
        </Button>
      </div>
      <br/>
      <EmailEditor
          ref={emailEditorRef}
          onLoad={onLoad}
          onDesignLoad={onDesignLoad}
          // options={{ locale: 'es-ES' }}
      />
    </>
  );
};
export default TemplateEditor;