import React, { Fragment, useState, useEffect } from 'react';
import { Form, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { Btn, H5, UL, H4, H6, P } from '../../../AbstractElements';
import { EmailAddress, CreateAccount, Password, SignIn, FirstName, LastName, Phone, PhoneNumber } from '../../../Constant';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from "react-router-dom";
import { firebase_app, Jwt_token } from '../../../Config/Config';
import man from '../../../assets/images/dashboard/1.png';
import { handleResponse } from '../../../Services/Fack.Backend';
import api from '../../../api/jwt'
import { Formik } from 'formik';
import PinInput from "react-pin-input";

const VerifyForm = ({ selected }) => {
    const [loading, setLoading] = useState(false);
    const [timeRemain, setTimeRemain] = useState(60);
    const [togglePassword, setTogglePassword] = useState(false);
    const history = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (timeRemain > 0) {
            setTimeout(() => {
                setTimeRemain(timeRemain - 1);
            }, 1000);
        }
    }, [timeRemain]);

    const verifyOTP = async (otp) => {
      setLoading(true);
      const phoneNumber = sessionStorage.getItem("phoneNumber");
        const payload = {
          otp: otp,
          phoneNumber: phoneNumber,
          };
        api.post('/auth/verify2fa', payload)
            .then(res => {
                setLoading(false);
                setTimeout(() => {
                    toast.success(
                        'Verification Successful. Please login to access your account'
                    );
                    var redirect = location.search.split("=")[1];
                    window.location.href = redirect == null ? "/admin/dashboard" : redirect;
                    // window.location.href = `${process.env.PUBLIC_URL}/admin/dashboard`;
                }, 200);
                return res.data;
            })
            .catch(err => {
                if (err) {
                    setLoading(false);
                    setTimeout(() => {
                        toast.error('Oppss..' + err.response.data.message);
                    }, 200);
                }
            });   
    }

    const resendOTP = async () => {
        setLoading(true);
        const phoneNumber = sessionStorage.getItem("phoneNumber");

        const payload = {
          phoneNumber: phoneNumber,
          };
        api.post('/auth/resendOTP', payload)
            .then(res => {
                setLoading(false);
                setTimeout(() => {
                    toast.success(
                        'OTP has been sent to your phone number / whatsapp number'
                    );
                    setTimeRemain(60);
                }, 200);
                return res.data;
            })
            .catch(err => {
                if (err) {
                    setLoading(false);
                    setTimeout(() => {
                        toast.error('Oppss..' + err.response.data.message);
                    }, 200);
                }
            });   
        
    }
    return (
        <Fragment>
<Form className="theme-form login-form">
          <br/>
          <br/>
                <center>
                <H4>{"Admin 2factor Authentication"}</H4>
        <H6>
          {"Enter the OTP sent to your whatsapp number and email"}
                    </H6>
                    <br />
                    <PinInput
                      length={6}
                      initialValue=""
                      secret
                      onChange={(value, index) => {}}
                      type="numeric"
                      inputMode="number"
                      style={{ padding: "10px" }}
                      inputStyle={{
                        width: 40,
                        height: 40,
                        borderColor: "#036672",
                        borderRadius: "100px",
                        border: "2px solid #036672",
                      }}
                      inputFocusStyle={{ borderColor: "#036672" }}
                      onComplete={(value, index) => {
                        verifyOTP(value);
                      }}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                    <br />
                    <br />

                     {timeRemain <= 0 ? (
                        <Btn
            type="submit"
            attrBtn={{
              color: "primary",
            //   className: "btn-block",
              disabled: loading ? loading : loading,
              onClick: () => resendOTP(),
            }}
          >
            {loading ? "LOADING..." : "Resend OTP"}
          </Btn>
                  ) : (
                      <P>
                        {"00:"}{timeRemain}
                      </P>
                    )}

                  


                    

                </center>
                
                </Form>
            <br/>
        </Fragment>
    );
};

export default VerifyForm;