import { H5, P } from '../AbstractElements';
import { Hometxt, Inbox, MaterialLeftTab, Profile, Settings } from '../Constant/index';
import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import CreditCard from './CreditCard'
const LeftTab = (props) => {
  const { order } = props;
  const [LeftLineTab, setLeftLineTab] = useState('1');
  return (
    <Col sm="12">
      <Card>
        {/* <CardHeader className='pb-0'>
          <H5>{MaterialLeftTab}</H5><span>{'Add'} <code>{'.border-tab nav-left'}</code> {'class with nav class'}</span>
        </CardHeader> */}
        <CardBody>
          <Row>
            <Col sm="3" className="tabs-responsive-side">
              <Nav className="flex-column nav-pills border-tab nav-left" >
                <NavItem>
                  <NavLink href="#javascript" className={LeftLineTab === '1' ? 'active' : ''} onClick={() => setLeftLineTab('1')}>
                    <i className="icofont icofont-credit-card"></i>
                  </NavLink>
                </NavItem>
                <NavItem >
                  <NavLink href="#javascript" className={LeftLineTab === '2' ? 'active' : ''} onClick={() => setLeftLineTab('2')}>
                    <i className="icofont icofont-ui-home"></i>
                  </NavLink>
                </NavItem>
               
              </Nav>
            </Col>
            <Col>
              <TabContent activeTab={LeftLineTab}>
                <TabPane className="fade show" tabId="1">
                  {/* <CreditCard order={order} /> */}
                </TabPane>
                <TabPane tabId="2">
                  <P>tab 2</P>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default LeftTab;