import { H5, P, Btn } from "../../../AbstractElements";
import React, { Fragment, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import QuickLinks from './QuickLinks';
import ProfileContain from './ProfileContain';
import ProfileHeader from './ProfileHeader';

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";

const UsersContain = (props) => {
  const { user } = props;
  return (
    <Fragment>
    <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <ProfileHeader user={user}/>
            <Col xl="4" md="5" lg="12" className="xl-35">
              <div className="default-according style-1 faq-accordion job-accordion">
                <Row>
                  <QuickLinks user={user} />
                </Row>
              </div>
            </Col>
            <ProfileContain user={user}/>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};
export default UsersContain;
