import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import UsersContain from '../../../Component/Admin/SmppUsers/index';
import api from '../../../api/jwt'
import LoadingSpinner from "../../../CommonElements/Loader/LoadingSpinner";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname === '/admin/smppUsers') {
    setIsLoading(true);
    api.get('/admin/smpp/users')
      .then(res => {
          setIsLoading(false)
        setUsers(res.data.data);
      })
      .catch(err => {
        setIsLoading(false)
      });
    } else {
      const uuid = pathname.split('/').pop();
      setUsers([])
      // setIsLoading(true);
      // api.get('/admin/smpp/' + uuid)
      //   .then(res => {
      //     setIsLoading(false)
      //     setUsers(res.data.data);
      //   })
      //   .catch(err => {
      //     console.log(err.response.data)
      //     setIsLoading(false)
      //   });
    }

  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="SMPP Users" />
      {isLoading ? <LoadingSpinner /> : <UsersContain users={users} />}
      
    </Fragment>
  )
}
