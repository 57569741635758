import React, { Fragment } from 'react';
import { MoreHorizontal } from 'react-feather';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Media, Table } from 'reactstrap';
import { H5, Image, LI, P, UL } from '../../AbstractElements';
import teacher from '../../assets/images/avtar/teacher.png';

const AccountActivity = (props) => {
  const { user } = props;
  return (
    <Fragment>
      <Col xl='4' md='6' className='dash-31 dash-xl-50 box-col-12'>
        <Card className="recent-activity">
          <CardHeader className="card-no-border">
            <Media className="media-dashboard">
              <Media body>
                <H5 attrH5={{ className: 'mb-0' }}>Account Activity</H5>
              </Media>
            </Media>
          </CardHeader>
          <CardBody className="pt-0">
            <Table responsive className="table-dashboard">
              <tbody>
                <tr>
                  <td>
                    <div className="d-inline-block align-middle">
                      {/* <Image attrImage={{ src: teacher, className: 'img-radius align-top m-r-15', alt: 'activity-user' }} /> */}
                      <div className="d-inline-block">
                        <h6>{user.name}</h6>
                        <P attrP={{ className: 'text-muted m-b-0' }}>Email Verified</P>
                      </div>
                    </div>
                  </td>
                  <td>
                    <i className="fa fa-check-circle text-success" aria-hidden="true"></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="d-inline-block align-middle">
                      {/* <Image attrImage={{ src: teacher, className: 'img-radius align-top m-r-15', alt: 'activity-user' }} /> */}
                      <div className="d-inline-block">
                        <h6>{user.name}</h6>
                        <P attrP={{ className: 'text-muted m-b-0' }}>Phone Number Verified</P>
                      </div>
                    </div>
                  </td>
                  <td>
                    <i className="fa fa-check-circle text-success" aria-hidden="true"></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="d-inline-block align-middle">
                      {/* <Image attrImage={{ src: teacher, className: 'img-radius align-top m-r-15', alt: 'activity-user' }} /> */}
                      <div className="d-inline-block">
                        <P attrP={{ className: 'text-muted m-b-0' }}>2FA Authentication</P>
                      </div>
                    </div>

                  </td>
                  <td>
                    {user.twoFactorAuth ?
                    <i className="fa fa-check-circle text-success" aria-hidden="true"></i>
                    :
                    <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>
                    }
                    
                  </td>
                </tr>


              </tbody>
            </Table>
            
          </CardBody>
        </Card>
      </Col >
    </Fragment >
  );
};
export default AccountActivity;