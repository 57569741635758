import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from "reactstrap";
import { Btn, H5, UL, H4, H6 } from "../AbstractElements";
import {
  EmailAddress,
  CreateAccount,
  Password,
  SignIn,
  Business,
  Bus,
  Phone,
  PhoneNumber,
} from "../Constant";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { firebase_app, Jwt_token } from "../Config/Config";
import man from "../assets/images/dashboard/1.png";
import { handleResponse } from "../Services/Fack.Backend";
import api from "../api/jwt";
import { Formik } from "formik";
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import logo from '../assets/images/SpendoColor.png';
// tab
import Tab from './tab'
// document.title = "Spendo Pay";

const PayCard = (props) => {
    const { order } = props;
    const [loading, setLoading] = useState(false);
    const history = useNavigate();
    return (
        <Fragment>
            <div className="theme-form login-form mb-50"
                style={{ marginBottom: 50 }}> 
                 <div className="login-header text-left">
                    <img src={logo} alt="logo" style={{ "width": 150, "marginBottom": 20 }} />
                    <p>{order.length}</p>
                </div>
                <Tab order={order} />
            </div>
        </Fragment>

    );
};
export default PayCard;