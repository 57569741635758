import React, { Fragment, useEffect } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import Contain from "../../Component/Cart";

const CartOverview = () => {
  useEffect(() => {
    document.title = "Cart Overview - Spendo";
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Cart" title="Cart Overview" />
      <Contain />
    </Fragment>
  );
};
export default CartOverview;
