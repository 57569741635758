import React, { Fragment, useState } from 'react';
import { Lock, Mail, User } from 'react-feather';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input, InputGroup, Label } from 'reactstrap';
import { Btn, H4, H5, H6, LI, P, UL } from '../AbstractElements';
import { CreateAccount, EmailAddress, PrivacyPolicy, YourName, Password, SignIn, FirstName, LastName, Phone } from '../Constant';
import { FaceBookSVG, GoogleSVG, InstagramSVG, TwitterSVG } from '../Data/svgIcons';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { firebase_app, Jwt_token } from '../Config/Config';
import man from '../assets/images/dashboard/1.png';
import { handleResponse } from '../Services/Fack.Backend';
import api from '../api/jwt'
import { Formik } from 'formik';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const RegisterForm = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

    const [togglePassword, setTogglePassword] = useState(false);
    const history = useNavigate();
  const registerUser = async (firstName, lastName, email, password) => {
        setLoading(true);
        const payload = {
            email: email,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            password: password,
          };
        api.post('/auth/signup', payload)
            .then(res => {
                setLoading(false);

                setTimeout(() => {
                    toast.success(
                        'Registration Successful. Please check your email for verification.'
                    );
                    sessionStorage.setItem('phoneNumber', phoneNumber);
                    window.location.href = `${process.env.PUBLIC_URL}/verify`;
                }, 200);
                return res.data;
            })
            .catch(err => {
                if (err) {
                    setLoading(false);
                    setTimeout(() => {
                        toast.error('Oppss..' + err.response.data.message);
                    }, 200);
                }
            });   
    }
    return (
        <Fragment>
             <Formik
                    initialValues={{
                      email: "",
                      password: "",
                      firstName: "",
                      lastName: "",
                    }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.email) {
                        errors.email = "Required";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      }
                        
                        if (!values.firstName) {
                            errors.firstName = "Required";
                        }

                        if (!values.lastName) {
                            errors.lastName = "Required";
                        }
                      
                        if (!values.password) {
                          errors.password = "Required";
                      }

                        if (!phoneNumber) {
                            errors.phoneNumber = "Required";
                        }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        registerUser(
                          values.firstName,
                          values.lastName,
                          values.email,
                          values.password
                        );
                        setSubmitting(false);
                      }, 400);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <Form onSubmit={handleSubmit} className="theme-form login-form">
                  
                <div className="login-header text-center">
                    <H4>Create your account</H4>
                    <H6>Enter your personal details to create account</H6>
                </div>
                
                {/* <div className="login-social-title">
                    <H5>Register in with Email</H5>
                </div> */}
                <FormGroup className="form-group">
                    <Label>First Name</Label>
                        <InputGroup><span className="input-group-text"><User /></span>
                        <Input className="form-control" type="text"
                                    name="firstName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                      value={values.firstName} placeholder={FirstName} />
                            
                  </InputGroup>
                  {errors.firstName &&
                            touched.firstName &&
                  errors.firstName}
                
                <br />
                <Label>Last Name</Label>
                        <InputGroup><span className="input-group-text"><User /></span>
                            <Input className="form-control" type="text"
                                    name="lastName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.lastName} placeholder={LastName} />
                            
                  </InputGroup>
                  {errors.lastName &&
                            touched.lastName &&
                            errors.lastName}
                </FormGroup>
                <FormGroup>
                    <Label>{EmailAddress}</Label>
                    <InputGroup><span className="input-group-text"><Mail /></span>
                    <Input className="form-control" type="email"
                                    name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email} placeholder={EmailAddress} />
                            </InputGroup>
                            {errors.email && touched.email && errors.email}
              </FormGroup>
              <FormGroup>
              <Label>{'Phone Number'}</Label>
                <PhoneInput
                  country={'ng'}
                  value={phoneNumber}
                  inputClass="form-control"
                  inputStyle={{ width: "90%", height: "48px", marginLeft: "40px" }}
                  onChange={phone => setPhoneNumber(phone)}
                  // onChange={handleChange}
                  placeholder={"Enter phone number"}
                />
                {errors.phoneNumber &&
                            touched.phoneNumber &&
                            errors.phoneNumber}
            </FormGroup>
                <FormGroup>
                    <Label>{Password}</Label>
                    <InputGroup><span className="input-group-text"><Lock /></span>
                        <Input className="form-control" type={togglePassword ? 'text' : 'password'}
                                    name="password"
                                    // onChange={(e) => setPassword(e.target.value)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                    // value={values.password}
                    defaultValue={password}
                    placeholder={Password} required="true" />
                         <div
              className="show-hide"
              onClick={() => setTogglePassword(!togglePassword)}
            >
              <span className={togglePassword ? "" : "show"}></span>
            </div>
                            </InputGroup>
                            {errors.password &&
                            touched.password &&
                            errors.password}
                </FormGroup>
                <FormGroup className="form-group">
                  <div className="checkbox">
                  <Input id="checkbox1" type="checkbox" />
                    <Label className="text-muted" for="checkbox1"> Agree with our <a href="https://spendoware.com/privacy-policy" target="_blank">{PrivacyPolicy}</a></Label>
                    </div>
                </FormGroup>
                <FormGroup>
                <button
                          variant="primary"
                          type="submit"
                          className="btn btn-block btn-primary"
                          disabled={isSubmitting}
                        >
                            {loading ? 'Loading...' : CreateAccount}
                        </button>                </FormGroup>
                <P>Already have an account?
                    <Link to={`${process.env.PUBLIC_URL}/login`} className="ms-2">
                        {SignIn}
                    </Link>
                </P>
                    </Form>
                     )}
                     </Formik>
        </Fragment>
    );
};

export default RegisterForm;