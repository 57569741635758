import React, { Fragment } from 'react';
import ReactDOM from 'react-dom'
import './App.css';
import Routers from './Routes';
import ContactProvider from './_helper/Contact/ContactProvider';
import CustomProvider from './_helper/customizer/CustomizerProvider';
import ChartistProvider from './_helper/Chartist/ChartistProvider';
import ChartjsProvider from './_helper/Chartjs/ChartProvider';
import GoogleChartProvider from './_helper/GoogleChart/GoogleChartProvider';
import ProductProvider from './_helper/ecommerce/product/ProductProvider';

import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './_helper/customizer/CustomizerProvider';
import { UserbackProvider } from '@userback/react';
function App() {
      return (
            <Fragment>

            <UserbackProvider token="39358|78184|WTKX6BGtNFINikctRZpB3BrlW">
                  <CustomizerProvider>
                              {/* <GoogleChartProvider> */}
                                    <ChartjsProvider>
                                          <ChartistProvider>
                                                                        <CustomProvider>
                                                <ContactProvider>
                                                      
                                                <ProductProvider> <AnimationThemeProvider>                                                                                                                  <Routers />
                                                      </AnimationThemeProvider> 
                                                            </ProductProvider>
                                                                                                                             
                                                                                                                  
                                                                                                           
                                                                                    </ContactProvider>
                                                                        </CustomProvider>
                                                                  
                                          </ChartistProvider>
                                    </ChartjsProvider>
                              {/* </GoogleChartProvider> */}
                  </CustomizerProvider>
                  </UserbackProvider>
            </Fragment >
      );
}
export default App;