import { H5, P, Btn } from '../../AbstractElements';
import React, { Fragment, useState, useEffect } from 'react';

import { Card, CardBody, CardHeader, Container, Row, Col, Form, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, Input,  Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { usePaystackPayment } from 'react-paystack';
import api from '../../api/jwt'
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';

const SamplePageContain = (props) => {
  const { business, campaigns, templates, contactList } = props;
  const { history } = props;
  const [modal, setModal] = React.useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const toggle = () => setModal(!modal);


  const createCampaign = (values) => {
    var self = this;
    const payload = {
      campaignName: values?.campaignName,
      campaignContactListId: values?.campaignContactListId,
      campaignTemplateId: values?.campaignTemplateId,
      isScheduled: values?.isScheduled,
    };

    if (payload.isScheduled) {
      // convert it to 2023-02-15 18:28:39.000000
      payload.campaignScheduleDate = new Date(values?.campaignScheduleDate).toISOString().slice(0, 19).replace('T', ' ');
    }
    api
      .post("/email/campaign/create/" + business.uuid, payload)
      .then((res) => {
        setTimeout(() => {
          toast.success(res.data.message);
          window.location.reload();
        }, 200);
        
      })
      .catch((err) => {
        if (err) {
          setTimeout(() => {
            toast.error("Oppss.." + err.response.data.message);
          }, 200);
        }
      });
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.campaignName,
      sortable: true
    },
    {
      name: "isScheduled",
      selector: (row) => row.isScheduled,
      // sortable: true
    },
    {
      name: "Schedule Date",
      selector: (row) => row.campaignScheduleDate,
      sortable: true,
      right: true
    },
    {
      name: "Status",
      selector: (row) => row.campaignStatus,
      sortable: true,
      right: true
    },
    {
      name: "createdAt",
      selector: (row) => new Date(row.createdAt).toLocaleString(),
      sortable: true,
      right: true
    },
    {
      button: true,
      cell: (row) => (
        <>
          <Link
            to={{
              pathname: '/campain/view/' + row.campaignId
            }}
          >
            <Btn className="btn btn-primary btn-sm" type="button">
              <i className="fa fa-eye"></i>
            </Btn>
          </Link>
        </>
      )
    }
  ];

  const handleIsScheduled = (e) => {
    setIsScheduled(e.target.value);
  }

    return (
      <Fragment>
        <Container fluid={true}>
          <Row>
            <Col sm="12">

              <div className="d-flex justify-content-end my-2 mx-2">
                <Button className="primary" type="button" onClick={() => {
                   setModal(true);
                }}>
                 Create a new Campaign
                </Button>
              </div>


              <Card>
                <CardHeader className="pb-0">   
                <div className="table-responsive support-table">
                  <DataTable
          // title="Transactions"
          columns={columns}
          data={campaigns}
          defaultSortFieldID={1}
                      pagination
          striped={true}
          center={true}
          // paginationComponent={BootyPagination}
          // selectableRows
          // selectableRowsComponent={BootyCheckbox}
                    />
                    </div>  
    
                </CardHeader>
                <CardBody>
                </CardBody>
              </Card>
            </Col>
          </Row>


          <Modal
            centered={true}
            fade={true}
            backdropTransition={{ timeout: 700 }}
            modalTransition={{ timeout: 700 }}

            isOpen={modal} toggle={toggle} className="">
          <ModalHeader toggle={toggle}>Create a new Email Campaign</ModalHeader>
              <ModalBody>
                
              <Formik
                  initialValues={{
                    campaignName: "",
                    campaignContactListId: "",
                    campaignTemplateId: "",
                    campaignScheduleDate: "",
                    isScheduled: ""
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.campaignName) {
                      errors.campaignName = "Required";
                    }
                    if (!values.campaignContactListId) {
                      errors.campaignContactListId = "Required";
                    }
                    if (!values.campaignTemplateId) {
                      errors.campaignTemplateId = "Required";
                    }
                    if (!values.isScheduled) {
                      errors.isScheduled = "Required";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    // setTimeout(() => {
                    createCampaign(values);
                    setSubmitting(false);
                    // }, 400);
                  }}
                  
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit} className="mt-4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                        Name
                        <p>Give your campaign list a unique name to reference later</p>
                        </Label>
                        <Input
                          type="input"
                          name="campaignName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.campaignName}
                          className="form-control"
                          defaultValue="1"
                        />

                        {errors.campaignName && touched.campaignName && errors.campaignName}
                    </FormGroup>
                    
                    <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                          Contacts
                        </Label>
                        <Input
                          type="select"
                          name="campaignContactListId"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.campaignContactListId}
                          className="form-control"
                          // defaultValue="1"
                        >
                          <option>Select Contact</option>
                          {contactList.map((contactlist, index) => {
                            return (
                              <option key={index} value={contactlist.contactListId}>
                                {contactlist.contactListName}
                              </option>
                            );
                          })}
                        </Input>
                        {errors.campaignContactListId &&
                          touched.campaignContactListId &&
                          errors.campaignContactListId}
                    </FormGroup>
                    
                    <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                          Email Template
                        </Label>
                        <Input
                          type="select"
                          name="campaignTemplateId"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.campaignTemplateId}
                          className="form-control"
                          // defaultValue="1"
                        >
                          <option>Select Template</option>
                          {templates.map((templateslist, index) => {
                            return (
                              <option key={index} value={templateslist.templateId}>
                                {templateslist.templateName}
                              </option>
                            );
                          })}
                        </Input>
                        {errors.campaignTemplateId &&
                          touched.campaignTemplateId &&
                          errors.campaignTemplateId}
                    </FormGroup>
                    
                    
                    <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                         isScheduled?
                        </Label>
                        <Input
                        type="select"
                        name="isScheduled"
                        // onChange={handleChange}
                        onChange={(e) => { handleChange(e); handleIsScheduled(e)}}
                        onBlur={handleBlur}
                        value={values.isScheduled}
                        className="form-control"
                        // defaultValue="1"
                        // onValueChange={(itemValue) => {
                        //   alert("yesss")
                        //   setIsScheduled(itemValue);
                        //   handleChange(itemValue);
                        // }}
                        >
                        <option>Select isScheduled</option>
                        <option key={1} value={true}>
                          Yes
                        </option>
                        <option key={2} value={false}>
                          No
                        </option>
                        </Input>
                        {errors.campaignTemplateId &&
                          touched.campaignTemplateId &&
                          errors.campaignTemplateId}
                    </FormGroup>

                    {
                      isScheduled ? (
                        <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                          Schedule Date
                          </Label>
                          <Input
                            // type="date"
                            // date and time
                            type="datetime-local"
                            name="campaignScheduleDate"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.campaignScheduleDate}
                            className="form-control"
                            // defaultValue="1"
                          />
                          {errors.campaignScheduleDate &&
                            touched.campaignScheduleDate &&
                            errors.campaignScheduleDate}
                        </FormGroup>
                      ) : null
                    }

                      <button
                        variant="primary"
                        type="submit"
                        className="btn btn-block btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Loading..." : "Create"}
                      </button>
                    </Form>
                  )}
                </Formik>

            </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '} */}
            {/* <Button color="secondary" onClick={toggle}>Close</Button> */}
          </ModalFooter>
        </Modal>

        </Container>
      </Fragment>
    );
};
export default SamplePageContain;