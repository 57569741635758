import { H5, P } from "../../../AbstractElements";
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
} from "reactstrap";
import api from "../../../api/jwt";
import { Formik } from "formik";
import { toast } from "react-toastify";
import 'draft-js/dist/Draft.css';
import CKEditors from 'react-ckeditor-component';

const SendMailContain = (props) => {
  const { user } = props;
  const [isLoading, setIsLoading] = useState(false);
  
  const [content, setContent] = useState('');
  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    setContent(newContent);
  };

  const sendMail = (
    subject
  ) => {
    var self = this;
    setIsLoading(true);
    const payload = {
      subject: subject,
      userId: user.uuid,
      message: content,
      // message: content.replace(/(<([^>]+)>)/gi, ""),
      // message: content.replace(/&nbsp;/g, ' '),

    };

    api
      .post("/admin/sendEmailToUser", payload)
      .then((res) => {
        setIsLoading(false);
        setTimeout(() => {
          toast.success("Email sent successfully");
          window.location.href = "/admin/users/" + user.uuid;
        }, 200);

        
      })
      .catch((err) => {
        if (err) {
          setIsLoading(false);

          setTimeout(() => {
            toast.error("Oppss.." + err.response.data);
          }, 200);
        }
      });
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Send Email to ({ user?.firstName + " " + user?.lastName })</H5>
                <span>
                  <P>
                    Send email to user. You can send email to user from here.
                  </P>
                </span>
              </CardHeader>
              <CardBody>
                <Formik
                  initialValues={{
                    subject: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.subject) {
                      errors.subject = "Subject is Required";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    // setTimeout(() => {
                    sendMail(
                      values.subject,
                    );
                    setSubmitting(false);
                    // }, 400);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit} className="mt-4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">Subject</Label>
                        <Input
                          type="text"
                          name="subject"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.subject}
                          className="form-control"
                          // id="exampleFormControlTextarea1"
                          // rows="3"
                          placeholder="Enter Subject"
                        />
                        {errors.subject &&
                          touched.subject &&
                          errors.subject}
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlTextarea1">
                          Message
                        </Label>

                        <CKEditors
                          activeclassName="p10"
                          content={content}
                          events={{
                            'change': onChange
                          }}
                        />
                      </FormGroup>
                    

                      <button
                        variant="primary"
                        type="submit"
                        className="btn btn-block btn-primary"
                        disabled={isLoading}
                      >
                        {isLoading ? "Mailing..." : "Send Mail"}
                      </button>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default SendMailContain;
