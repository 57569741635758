import React, { Fragment, useState, useEffect } from 'react';
import { Image } from '../../AbstractElements';
import { Btn, H5, LI, P, UL } from '../../AbstractElements';
import { Col, Form, FormGroup,Card, Label, InputGroup, InputGroupAddon, InputGroupText, Input, Row } from 'reactstrap';
import api from '../../api/jwt'
import { useForm } from "react-hook-form";

import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

const ProfileContain = (props) => {
  const { user } = props;
  const [code, setCode] = React.useState('');
  const [loading, setLoading] = useState(false);

  const verify2fa = async (e) => {
    setLoading(true);
      const payload = {
        code: code,
      };      
      api.post('/auth/validate2fa',payload)
          .then(res => {
              setLoading(false);
              setTimeout(() => {
                  toast.success(
                      '2factor Authentication enabled successfully!'
                  );
                window.location.reload();
              }, 200);
              return res.data;
          })
          .catch(err => {
            if (err) {
                  setLoading(false);
                  setTimeout(() => {
                      toast.error('Oppss..' + err.response.data.message);
                  }, 200);
              }
          });   
  }

  return (
    <Fragment>
      <Col xl="8" lg="12" md="7" className="xl-65">
        <Row className="row">
          <Col sm="12">
            <Card>
            
              <div className="profile-post">
              <H5 attrH5={{ className: 'p-0' }}>
              Scan to Get Started
                </H5>
                
                <Row>
          <Col md="12 mb-3">
                    <p>
                      <center>
                        <img width={180} src={user?.twoFactorAuthQrCode} alt="QR Code" />
                        </center>
                    </p>

                    <p>
                      Install <b>Google Authenticator</b> or any Authenticator app on your phone.
                      Scan the barcode on the left hand side.
                    </p>

                    {/* or user setupkey */}
                    <p>
                      If you can't scan the barcode, use the following key and enter it manully in the authentication app:
                      <br />
                      <br />
                      <b style={{"color": "green"}}>{user?.twoFactorAuthSecret}</b>
                    </p>

                    {user?.twoFactorAuth ?
                      <>
                      </>
                      :
                      <>
                         <Form>   
                  <FormGroup>
                    <Label>Enter the 6 digit code generated by the app.</Label>
                        <div className="input-group"><span className="input-group-text">
                        <i className="fa fa-lock" />
                    </span>
                        <Input
                         type="number"
                         name="securityCode"
                         placeholder="Security Code"
                         required
                        //  {...verify("securityCode", { required: true })}
                         onChange={(e) => setCode(e.target.value)}
                         defaultValue={code}/>
                    </div>
                      </FormGroup>
                      <Btn
              type="submit"
              attrBtn={{
                textTransform:'capitalize',
                color: "primary",
                className: "btn-block",
                disabled: loading ? loading : loading,
                onClick: (e) => {
                  if (code === "") {
                    setTimeout(() => {
                      toast.error("Code are required");
                    }, 200);
                  } else {
                    verify2fa(e);
                  }
                },
              }}
            >
              {loading ? "Loading..." : "Verify & Enable Two-Factor"}
            </Btn>

                      </Form>
                      </>
                    }
                    
                    <p>
                      <b>Important:</b> If you lose your phone, you will lose access to your account.
                    </p>

                    

                    </Col>
                  
                </Row>
                

                <hr />
                
        
              </div>
            </Card>
          </Col>
        </Row>
      </Col >
    </Fragment >
  );
};
export default ProfileContain;