import React, { Fragment, useEffect, useState } from 'react';
import { FileText, LogIn, Mail, Settings, User } from 'react-feather';
import { Media } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { H6, Image, LI, UL } from '../../AbstractElements';
import UserImg from '../../assets/images/avtar/man.png';
import Businesses from './Businesses';
import MaxMiniSize from './MaxMiniSize';
import MoonLight from './MoonLight';
import Language from './Langauge';
import { firebase_app } from '../../Config/Config';
import Bookmark from './Bookmark/index';
import { Account, Inbox, LogOut, Taskboard, Switch } from '../../Constant';

const HeaderContain = () => {
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('');

  const authenticated = JSON.parse(sessionStorage.getItem('authenticated'));
  const auth0_profile = JSON.parse(sessionStorage.getItem('auth0_profile'));
  const business_list = JSON.parse(sessionStorage.getItem('business_list'));
  const business = JSON.parse(sessionStorage.getItem('business'));


  const history = useNavigate();
  useEffect(() => {
    setProfile(sessionStorage.getItem('profileURL') || UserImg);
    setName(sessionStorage.getItem('Name'));

  }, []);
  const Logout = () => {
    sessionStorage.removeItem('profileURL');
    sessionStorage.removeItem('Name');
    sessionStorage.removeItem('token');
    firebase_app.auth().signOut();
    history(`${process.env.PUBLIC_URL}/login`);
    sessionStorage.setItem('authenticated', false);
  };
  return (
    <Fragment>
      <div className="nav-right col-10 col-sm-6 pull-right right-header p-0 dash-76">
        <UL attrUL={{ className: `simple-list flex-row nav-menus` }}>
          <MoonLight />
          {/* <Businesses business_list={business_list} business={business} /> */}
          {/* <Notification /> */}
          <MaxMiniSize />
          <LI attrLI={{ className: 'profile-nav onhover-dropdown pe-0 pt-0 me-0' }} >
            <Media className="profile-media">
              <Image attrImage={{
                className: 'rounded-circle', src: `${authenticated ? auth0_profile.picture : profile}`, alt: '',
              }}
              />
              <Media body>
                <span>{authenticated ? auth0_profile.name : name}</span>
              </Media>
            </Media>
            <UL attrUL={{ className: `simple-list profile-dropdown onhover-show-div` }}>
              <LI attrLI={{ onClick: Logout }}>
                <Link to={`${process.env.PUBLIC_URL}/login`}>
                  <LogIn /><span>{LogOut}</span>
                </Link>
              </LI>
            </UL>
          </LI>
        </UL>
      </div >
    </Fragment >
  );
};
export default HeaderContain;
