import { H5, P } from "../../AbstractElements";
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
} from "reactstrap";
import api from "../../api/jwt";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { phoneNumber } from "../../Constant";

const RequestSenderIDContain = (props) => {
  const { business, senderIds } = props;
  
  const requestSenderId = (senderId, usecase) => {
    var self = this;
    const payload = {
      businessId: business.uuid,
      senderId: senderId,
      useCase: usecase,
      company: business.businessName,
    };

    api
      .post("/sms/sender/request", payload)
      .then((res) => {
        setTimeout(() => {
          toast.success("Your request has been sent successfully");
        }, 200);
        window.location.reload();
      })
      .catch((err) => {
        if (err) {
          setTimeout(() => {
            toast.error("Oppss.." + err.response.data.message);
          }, 200);
        }
      });
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Request Sender ID </H5>
                <span>
                  Represents the ID of the sender which can be alphanumeric or
                  numeric. Alphanumeric sender ID length should be between 3 and
                  11 characters (Example:CompanyName)
                </span>


                <hr/>
                {/* note */}
                <P className="mb-0">
                The process for approving Sender IDs can vary by country and by provider. Some providers may approve Sender IDs instantly, while others may take up to 48 hours. If you have questions about the approval process for your Sender ID, you can reach out to your SMS provider's support team for more information.                </P>
                <br/>
                <P className="mb-0">
                Currently, our Sender IDs are approved during weekdays and may take up to 48 hours to activate across all telecommunications providers in your country. While you wait for your Sender ID to be approved, you can register and use our default Sender ID, "Spendoware," as a temporary substitute. If you have any questions or concerns about the approval process, please don't hesitate to reach out to our support team.                </P>
              </CardHeader>
              <CardBody>
                <Formik
                  initialValues={{
                    usecase: "",
                    senderId: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.senderId) {
                      errors.senderId = "Required";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    // setTimeout(() => {
                    // window.alert(JSON.stringify(values, null, 2));
                    requestSenderId(values.senderId, values.usecase);
                    setSubmitting(false);
                    // }, 400);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit} className="mt-4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                          Sender ID
                        </Label>
                        <Input
                          type="input"
                          name="senderId"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.senderId}
                          className="form-control"
                          defaultValue="1"
                        />

                        {errors.senderId && touched.senderId && errors.senderId}
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="exampleFormControlTextarea1">
                          Use Case
                          <p>A sample of the type of message sent.</p>
                        </Label>
                        <Input
                          type="textarea"
                          name="usecase"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.usecase}
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          placeholder="Enter message"
                        />
                        {errors.usecase && touched.usecase && errors.usecase}
                      </FormGroup>
                      <button
                        variant="primary"
                        type="submit"
                        className="btn btn-block btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Loading..." : "Send"}
                      </button>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default RequestSenderIDContain;
