import React, { Fragment, useEffect } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import Contain from '../../Component/Verification';

const VerificationOverview = () => {
  useEffect(() => {
    document.title = 'Verification Overview - Spendo';
  }, []);

  return (
    <Fragment>
      <Breadcrumbs parent="Services" title="Verification Overview" />
      <Contain />
    </Fragment>
  );
};
export default VerificationOverview;