import React, { Fragment, useState, useEffect, useCallback } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import SenderIDContain from "../../../Component/Admin/SMS/senderIDReport";
import RequestSenderIDContain from "../../../Component/SMS/requestSenderID";
import LoadingSpinner from "../../../CommonElements/Loader/LoadingSpinner";
import api from "../../../api/jwt";
import { Button, Card } from "reactstrap";

export default function SenderReport() {
  const [senderId, setSendId] = useState([]);
  const [business, setBusiness] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    api
      .get("/admin/sms/senderIds/0/100/DESC/createdAt")
      .then((res) => {
        setIsLoading(false);
        setSendId(res.data.data.content);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="Sender ID List" />
      {isLoading ? <LoadingSpinner /> : <SenderIDContain sms_sendserID={senderId} />}
    </Fragment>
  );
}
