import { H5, P, Btn } from "../../../AbstractElements";
import React, { Fragment, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import QuickLInksSidebar from './QuickLInksSidebar';
import ProfileContain from './ProfileContain';
import ProfileHeader from './ProfileHeader';
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
const BusinessContain = (props) => {
  const { business, wallet, businessAccountDetails } = props;
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <ProfileHeader business={business} apiKeys={[]} wallet={wallet} />
            <Col xl="4" md="5" lg="12" className="xl-35">
              <div className="default-according style-1 faq-accordion job-accordion">
                <Row>
                  <QuickLInksSidebar business={business}  />
                </Row>
              </div>
            </Col>
            <ProfileContain business={business} businessAccountDetails={businessAccountDetails} />
          </Row>
        </div>
      </Container>
      </Fragment>
  );
};
export default BusinessContain;
