import React, { Fragment, useState, useEffect, useCallback } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import UsersContain from "../../../Component/Admin/Users/index";
import api from "../../../api/jwt";
import LoadingSpinner from "../../../CommonElements/Loader/LoadingSpinner";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    api
      .get("/admin/users/0/10/DESC/createdAt")
      .then((res) => {
        setIsLoading(false);
        setUsers(res.data.data.content);
      })
      .catch((err) => {
        console.log("rrrr", err);
        setIsLoading(false);
      });
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="Users" />
      {isLoading ? <LoadingSpinner /> : <UsersContain users={users} />}
    </Fragment>
  );
}
