import React, { Fragment, useState, useEffect } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import UpdateProductContain from "../../Component/Cart/updateProduct";
import api from "../../api/jwt";
import LoadingSpinner from "./../../CommonElements/Loader/LoadingSpinner";

const UpdateProduct = () => {
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const [product, setProduct] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [business, setBusiness] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const pathname = window.location.pathname;
  const productId = pathname.split('/').pop();
  useEffect(() => {
    document.title = "Update Product - Spendo";
    setName(sessionStorage.getItem("Name"));
    const businesss = JSON.parse(sessionStorage.getItem("business"));
    setBusiness(businesss);
    const authenticated = JSON.parse(sessionStorage.getItem("authenticated"));
    const auth0_profile = JSON.parse(sessionStorage.getItem("auth0_profile"));
    const business_list = JSON.parse(sessionStorage.getItem("business_list"));
    const token = sessionStorage.getItem("token");

    setIsLoading(true);
    api
      .get("/product/" + productId)
      .then((res) => {
        setProduct(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
       });
    
      api
      .get("/products/categories")
      .then((res) => {
        setCategories(res.data);
        setIsLoading(false);
      })
        .catch((err) => {
          setIsLoading(false);
        
      });
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Cart" title="Update Product" />
      {isLoading ? <LoadingSpinner /> : <UpdateProductContain business={business} categories={categories} product={product} />}
    </Fragment>
  );
};
export default UpdateProduct;
