import {
  BlogSvg,
  BonusUISvg,
  ButtonsSvg,
  CalanderSvg,
  ChartsSvg,
  ChatSvg,
  ContactSvg,
  EcomTempSVG,
  EcommerceSvg,
  EditorsSvg,
  EmailSvg,
  FAQSvg,
  FilemanagerSvg,
  FormsSvg,
  GallarySvg,
  HeaderBookmarkSvg,
  HomeSvg,
  IconsSvg,
  JobsearchSvg,
  KanbanSvg,
  DevelopersSvg,
  KnowledgebaseSvg,
  LearningSvg,
  MapsSvg,
  OthersSvg,
  ProjectSvg,
  SamplePageSvg,
  SearchResultSvg,
  SocialappSvg,
  SupportTicketSvg,
  TablesSvg,
  TaskSvg,
  TodoSvg,
  UiKitsSvg,
  UsersComponentSvg,
  WidgetsSvg,
  WhatsappSvg,
  ShoppingCart,
} from "../../Data/svgIcons";

export const MENUITEMS = [
  {
    menutitle: "DashBoard",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard`,
        bookmark: true,
        icon: HomeSvg,
        title: "Dashboard",
        type: "link",
      },
    ],
  },
  {
    menutitle: "SMS",
    Items: [
      {
        title: "Sms",
        icon: EmailSvg,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/sms/overview`,
            title: "Overview",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/sms/send`,
            title: "Send Sms",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/sms/bulk`,
            title: "Send Bulk Sms",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/sms/senderId`,
            title: "Sender ID",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/sms/report`,
            title: "Report",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Whatsapp",
    Items: [
      {
        title: "Whatsapp",
        icon: WhatsappSvg,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/whatsapp/overview`,
            title: "Overview",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/whatsapp/subscription`,
            title: "Subscription",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/whatsapp/message/send`,
            title: "Whatsapp Send",
            type: "link",
          },

          {
            path: `${process.env.PUBLIC_URL}/whatsapp/templates`,
            title: "Whatsapp Templates",
            type: "link",
          },
          // {
          //   path: `${process.env.PUBLIC_URL}/whatsapp/messages`,
          //   title: "Messages",
          //   type: "link",
          // },
          // {
          //   path: `${process.env.PUBLIC_URL}/whatsapp/report`,
          //   title: "Report",
          //   type: "link",
          // },
        ],
      },
    ],
  },

  {
    menutitle: "Voice",
    Items: [
      {
        title: "Voice",
        icon: ProjectSvg,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/voice/overview`,
            title: "Overview",
            type: "link",
          },
          // {
          //   path: `${process.env.PUBLIC_URL}/voice/otp/send`,
          //   title: "Voice OTP Send",
          //   type: "link",
          // },
          // {
          //   path: `${process.env.PUBLIC_URL}/voice/report`,
          //   title: "Report",
          //   type: "link",
          // },
        ],
      },
    ],
  },
  {
    menutitle: "Verification",
    Items: [
      {
        title: "Verification",
        icon: KnowledgebaseSvg,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/verification/overview`,
            title: "Overview",
            type: "link",
          },
          // {
          //   path: `${process.env.PUBLIC_URL}/verification/nin`,
          //   title: "NIN Verification",
          //   type: "link",
          // },
          // {
          //   path: `${process.env.PUBLIC_URL}/verification/bvn`,
          //   title: "BVN Verification",
          //   type: "link",
          // },
          // {
          //   path: `${process.env.PUBLIC_URL}/verification/report`,
          //   title: "Report",
          //   type: "link",
          // },
        ],
      },
    ],
  },
  {
    menutitle: "Email",
    Items: [
      {
        title: "Email",
        icon: EmailSvg,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/email/overview`,
            title: "Overview",
            type: "link",
          },
          // {
          //   path: `${process.env.PUBLIC_URL}/email/templates`,
          //   title: "Templates",
          //   type: "link",
          // },
          // {
          //   path: `${process.env.PUBLIC_URL}/email/campaigns`,
          //   title: "Campaigns",
          //   type: "link",
          // },
          // {
          //   path: `${process.env.PUBLIC_URL}/email/contacts`,
          //   title: "Contacts",
          //   type: "link",
          // },
          // {
          //   path: `${process.env.PUBLIC_URL}/email/report`,
          //   title: "Report",
          //   type: "link",
          // },
        ],
      },
    ],
  },
  {
    menutitle: "Contacts",
    Items: [
      {
        title: "Contacts",
        icon: ContactSvg,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/contacts/overview`,
            title: "Overview",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/contacts/list`,
            title: "Contact List",
            type: "link",
          },
        ],
      },
    ],
  },

  {
    menutitle: "Cart",
    Items: [
      {
        title: "Cart",
        icon: ShoppingCart,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/cart/overview`,
            title: "Overview",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/cart/products`,
            title: "Products",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/cart/orders`,
            title: "Orders",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/cart/subscriptions`,
            title: "Subscriptions",
            type: "link",
          },

          {
            path: `${process.env.PUBLIC_URL}/cart/settings`,
            title: "Settings",
            type: "link",
          },
        ],
      },
    ],
  },

  {
    menutitle: "Transactions",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/transactions`,
        bookmark: true,
        icon: EditorsSvg,
        title: "Transactions",
        type: "link",
      },
    ],
  },

  {
    menutitle: "Pricing",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/pricing`,
        icon: SupportTicketSvg,
        type: "link",
        active: false,
        title: "Pricing",
      },
    ],
  },

  {
    menutitle: "User Account",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/user/account`,
        icon: SupportTicketSvg,
        type: "link",
        active: false,
        title: "User Account",
      },
    ],
  },

  {
    menutitle: "Developers",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/developers`,
        icon: DevelopersSvg,
        type: "link",
        active: false,
        title: "Developers",
      },
    ],
  },
];
