import React, { Fragment } from 'react';
import { Image } from '../../AbstractElements';
import { Btn, H5, LI, P, UL } from '../../AbstractElements';
import { Col, Form, FormGroup,Card, Label, InputGroup, InputGroupAddon, InputGroupText, Input, Row } from 'reactstrap';
import api from '../../api/jwt'
import { toast } from "react-toastify";

const ProfileContain = (props) => {
  const { business, apiKeys } = props;

  return (
    <Fragment>
      <Col xl="12" lg="12" md="12" className="xl-65">
        <Row className="row">
          <Col sm="12">
            <Card>
              <div className="profile-post">
              <H5 attrH5={{ className: 'p-0' }}>
              API Keys
                </H5>
                
                <Row>
          <Col md="12 mb-3">
                    <Label htmlFor="validationCustom01">Public Test Key</Label>
                      <Row>
                      <Col>
                    <input readOnly className="form-control" value={apiKeys.testPublicKey} type="text" placeholder="*********" />
                    </Col>
                    
                      <Col>
                      <button
                      onClick={() => {
                            navigator.clipboard.writeText(apiKeys.testPublicKey)
                            toast.success('Copied to clipboard')
                      }}
                      className="btn btn-primary"
                    >
                      Copy Key
                        </button>
                      </Col>
                    </Row>
                  </Col>
                  
                  <Col md="12 mb-3">
                    <Label htmlFor="validationCustom01">Secret Test Key</Label>
                    <Row>
                      <Col>
                        <input readOnly className="form-control" value={apiKeys.testSecretKey} type="text" placeholder="*********" />
                        </Col>
                    
                      <Col>
                      <button
                      onClick={() => {
                            navigator.clipboard.writeText(apiKeys.testSecretKey)
                            toast.success('Copied to clipboard')
                      }}
                      className="btn btn-primary"
                    >
                      Copy Key
                        </button>
                      </Col>
                    </Row>
                  </Col>
                  
                </Row>
                

                <hr />
                
                <Row>
          <Col md="12 mb-3">
                <Label htmlFor="validationCustom01">Public Live Key</Label>
                <Row>
                      <Col>
                <input readOnly className="form-control" value={apiKeys.livePublicKey} type="text" placeholder="*********" />
                </Col>
                    
                      <Col>
                      <button
                      onClick={() => {
                            navigator.clipboard.writeText(apiKeys.livePublicKey)
                            toast.success('Copied to clipboard')
                      }}
                      className="btn btn-primary"
                    >
                      Copy Key
                        </button>
                      </Col>
                    </Row>
                  </Col>
                  
                  <Col md="12 mb-3">
                    <Label htmlFor="validationCustom01">Secret Live Key</Label>
                    <Row>
                      <Col>
                    <input readOnly className="form-control" value={apiKeys.liveSecretKey} type="text" placeholder="*********"/>
                      </Col>
                    
                      <Col>
                      <button
                      onClick={() => {
                            navigator.clipboard.writeText(apiKeys.liveSecretKey)
                            toast.success('Copied to clipboard')
                      }}
                      className="btn btn-primary"
                    >
                      Copy Key
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                
              </div>

              <br />

              <center>
                <a href="https://docs.getspendo.com" target="_blank" rel="noopener noreferrer">
                  <Btn attrBtn={{ className: 'btn btn-primary' }}>
                    Explore API Documentation
                  </Btn>
                </a>
              </center>

              <br/>
              <br/>
              
            </Card>
          </Col>
        </Row>
      </Col >
    </Fragment >
  );
};
export default ProfileContain;