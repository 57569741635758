import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import ListContain from '../../Component/Whatsapp/templates';
import api from '../../api/jwt'


export default function ContainPage() {
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('');
  const [campaigns, setCampaigns] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [business, setBusiness] = useState([]);
  useEffect(() => {
    document.title = 'Campaign List - Spendo';
    setName(sessionStorage.getItem('Name')); 
    const businesss = JSON.parse(sessionStorage.getItem('business'));
    setBusiness(businesss);

      // api.get('/email/campaigns/' + businesss.uuid)
      // .then(res => {
      //   setCampaigns(res.data.data);
      // })
      // .catch(err => {
      // });
    
      api.get('/whatsapp/message/template/' + businesss.uuid)
        .then(res => {
        setTemplates(res.data.data);
      })
        .catch(err => {
      });
    
      // api.get('/email/contactlists/' + businesss.uuid)
      // .then(res => {
      //   setContactList(res.data.data);
      // })
      // .catch(err => {
      // });

  }, []);
  return (
<Fragment>
      <Breadcrumbs parent="Pages" title="Template List" />
      <ListContain business={business} templates={templates} />
    </Fragment>
  )
}
