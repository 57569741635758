import React, { Fragment, useState, useEffect } from "react";
import api from "../../api/jwt";

import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import Contain from "../../Component/Whatsapp/subscribe";

const WhatsappSubscription = () => {
  const [business, setBusiness] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    document.title = "Subscribe to new plan - Spendo";

    const businesss = JSON.parse(sessionStorage.getItem("business"));
    setBusiness(businesss);
    const authenticated = JSON.parse(sessionStorage.getItem("authenticated"));
    const auth0_profile = JSON.parse(sessionStorage.getItem("auth0_profile"));
    const business_list = JSON.parse(sessionStorage.getItem("business_list"));
    const token = sessionStorage.getItem("token");
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="WhatsApp" title="Subscribe to Whatsapp Plan" />
      <Contain business={business} />
    </Fragment>
  );
};
export default WhatsappSubscription;
