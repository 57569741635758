import { H5, P, Btn } from '../../AbstractElements';
import React, { Fragment, useState, useEffect } from 'react';

import { Card, CardBody, CardHeader, Container, Row, Col, Form, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, Input,  Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { usePaystackPayment } from 'react-paystack';
import api from '../../api/jwt'
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';

const SamplePageContain = (props) => {
  const { business, contactList } = props;
  const { history } = props;
  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);


  const createContactList = (contactListName, contactListDescription) => {
    var self = this;
    const payload = {
      contactListName: contactListName,
      contactListDescription: contactListDescription,
    };

    api
      .post("/email/contactlist/create/" + business.uuid, payload)
      .then((res) => {
        setTimeout(() => {
          toast.success(res.data.message);
          window.location.reload();
        }, 200);
        
      })
      .catch((err) => {
        if (err) {
          setTimeout(() => {
            toast.error("Oppss.." + err.response.data.message);
          }, 200);
        }
      });
  };

  const columns = [
    {
      name: "List Name",
      selector: (row) => row.contactListName,
      sortable: true
    },
    {
      name: "Description",
      selector: (row) => row.contactListDescription,
      // sortable: true
    },
    {
      name: "Subscribers",
      // selector: (row) => row.len,
      selector: (row) => row.length,
      sortable: true,
      right: true
    },
    {
      name: "DATE",
      selector: (row) => new Date(row.createdAt).toLocaleString(),

      sortable: true,
      right: true
    },
    {
      button: true,
      cell: (row) => (
        <>
          <Link
            to={{
              pathname: '/contacts/view/' + row.contactListId
            }}
          >
            <Btn className="btn btn-primary btn-sm" type="button">
              <i className="fa fa-eye"></i>
            </Btn>
          </Link>
        </>
      )
    }
  ];

    return (
      <Fragment>
        <Container fluid={true}>
          <Row>
            <Col sm="12">

              <div className="d-flex justify-content-end my-2 mx-2">
                <Button className="primary" type="button" onClick={() => {
                   setModal(true);
                }}>
                 Create a contact List
                </Button>
              </div>


              <Card>
                <CardHeader className="pb-0">   
                <div className="table-responsive support-table">
                  <DataTable
          // title="Transactions"
          columns={columns}
          data={contactList}
          defaultSortFieldID={1}
                      pagination
          striped={true}
          center={true}
          // paginationComponent={BootyPagination}
          // selectableRows
          // selectableRowsComponent={BootyCheckbox}
                    />
                    </div>  
    
                </CardHeader>
                <CardBody>
<p>-</p>
                </CardBody>
              </Card>
            </Col>
          </Row>


          <Modal
            centered={true}
            fade={true}
            backdropTransition={{ timeout: 700 }}
            modalTransition={{ timeout: 700 }}

            isOpen={modal} toggle={toggle} className="">
          <ModalHeader toggle={toggle}>Create a new Contact List</ModalHeader>
              <ModalBody>
                
              <Formik
                  initialValues={{
                    contactListName: "",
                    contactListDescription: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.contactListName) {
                      errors.contactListName = "Required";
                    }
                    if (!values.contactListDescription) {
                      errors.contactListDescription = "Required";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    // setTimeout(() => {
                    createContactList(values.contactListName, values.contactListDescription);
                    setSubmitting(false);
                    // }, 400);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit} className="mt-4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                        Name
                        <p>Give your contact list a unique name to reference later</p>
                        </Label>
                        <Input
                          type="input"
                          name="contactListName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactListName}
                          className="form-control"
                          defaultValue="1"
                        />

                        {errors.contactListName && touched.contactListName && errors.contactListName}
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="exampleFormControlTextarea1">
                        Description
                          {/* <p></p> */}
                        </Label>
                        <Input
                          type="textarea"
                          name="contactListDescription"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.usecase}
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          placeholder="Enter a description of the list"
                        />
                        {errors.contactListDescription && touched.contactListDescription && errors.contactListDescription}
                      </FormGroup>
                      <button
                        variant="primary"
                        type="submit"
                        className="btn btn-block btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Loading..." : "Create"}
                      </button>
                    </Form>
                  )}
                </Formik>

            </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '} */}
            {/* <Button color="secondary" onClick={toggle}>Close</Button> */}
          </ModalFooter>
        </Modal>

        </Container>
      </Fragment>
    );
};
export default SamplePageContain;