import React, { Fragment, useState, useEffect } from "react";
import api from "../../api/jwt";
import LoadingSpinner from "./../../CommonElements/Loader/LoadingSpinner";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import Contain from "../../Component/Whatsapp/subscription";

const WhatsappSubscription = () => {
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const [currentSubscriptions, setCurrentSubscriptions] = React.useState([]);
  const [subscriptions_plans, setSubscriptionPlans] = React.useState([]);
  const [business, setBusiness] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    document.title = "Subscriptions - Spendo";
    setName(sessionStorage.getItem("Name"));
    const businesss = JSON.parse(sessionStorage.getItem("business"));
    setBusiness(businesss);
    const authenticated = JSON.parse(sessionStorage.getItem("authenticated"));
    const auth0_profile = JSON.parse(sessionStorage.getItem("auth0_profile"));
    const business_list = JSON.parse(sessionStorage.getItem("business_list"));
    const token = sessionStorage.getItem("token");
    setIsLoading(true);
    api
      .get("/business/whatsapp-credit/subscription/" + businesss.uuid)
      .then((res) => {
        setCurrentSubscriptions(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });

    // get subscriptions plans
    api
      .get("/business/whatsapp-credit/plans")
      .then((res) => {
        setIsLoading(false);
        setSubscriptionPlans(res.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="WhatsApp" title="Whatsapp Subscriptions" />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Contain
          business={business}
          currentSubscriptions={currentSubscriptions}
          subscriptions_plans={subscriptions_plans}
        />
      )}
    </Fragment>
  );
};
export default WhatsappSubscription;
