import { H5, P, Btn } from "../../../AbstractElements";
import React, { Fragment, useState, useEffect } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { usePaystackPayment } from "react-paystack";
import api from "../../../api/jwt";
import { Formik } from "formik";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";

const SamplePageContain = (props) => {
  const { sms_sendserID } = props;

  const updateStatus = async (senderId, status) => {
    try {
      api
        .put("/admin/sms/senderIds/status", {
          senderId: senderId,
          status: status,
        })
        .then((res) => {
          toast.success("Sender ID " + status);
          window.location.reload();
        })
        .catch((err) => {
          toast.error("Oppss.." + err.response.data.message);
        });
    } catch {
      toast.error("Oppss..");
    }
  };
  const columns = [
    {
      name: "Sender ID",
      selector: (row) => row.senderId,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => {
        const date = new Date(row.createdAt);
        return date.toLocaleDateString();
      },
      // sortable: true,
      // right: true,
    },

    {
      name: "Approv",
      button: true,
      // sortable: true,
      right: true,
      cell: (row) => (
        <>
          {row.status !== "active" && (
            <p
              className="btn btn-info"
              onClick={() => {
                updateStatus(row.senderId, "active");
              }}
            >
              <i className="fa fa-check"></i>
            </p>
          )}
        </>
      ),
    },
    {
      name: "Block",
      button: true,
      // sortable: true,
      right: true,
      cell: (row) => (
        <>
          {row.status === "active" ? (
            <p
              className="btn btn-danger"
              onClick={() => {
                updateStatus(row.senderId, "blocked");
              }}
            >
              <i className="fa fa-close"></i>
            </p>
          ) : (
            <p
              className="btn btn-danger"
              onClick={() => {
                updateStatus(row.senderId, "active");
              }}
            >
              <i className="fa fa-close"></i>
            </p>
          )}
        </>
      ),
    },
  ];
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              {/* <CardHeader className="pb-0">
              </CardHeader> */}
              <CardBody>
                <DataTable
                  // title="Transactions"
                  columns={columns}
                  data={sms_sendserID}
                  defaultSortFieldID={1}
                  pagination
                  striped={true}
                  center={true}
                  // paginationComponent={BootyPagination}
                  // selectableRows
                  // selectableRowsComponent={BootyCheckbox}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default SamplePageContain;
