import React, { Fragment, useState, useEffect } from 'react';
import { Lock, Mail } from 'react-feather';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input, Label,InputGroup, InputGroupText, } from 'reactstrap';
import { Btn, H4, H5, P, H6, UL, LI } from '../AbstractElements';
import { CreateAccount, EmailAddress, ForgotPassword, Login, Password, RememberPassword, SignIn } from '../Constant';
import { FaceBookSVG, GoogleSVG, InstagramSVG, TwitterSVG } from '../Data/svgIcons';
import logo from '../assets/images/SpendoColor.png';
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import man from "../assets/images/dashboard/1.png";
import api from "../api/jwt";
import { useForm } from "react-hook-form";
import axios from 'axios'
const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [browser, setBrowser] = useState('');
  const [device, setDevice] = useState('');
  const [system, setSystem] = useState('');
  const [ip, setIp] = useState('');
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState(
    sessionStorage.getItem("profileURL" || man)
  );
  const [name, setName] = useState(sessionStorage.getItem("Name"));

  const getData = async()=>{
    let apiKey = 'a50bc4ddfa834b64888625fc1d6c5792';
    const res = await axios.get('https://ipgeolocation.abstractapi.com/v1/?api_key=' + apiKey)
    console.log(res.data)
    setIp(res.data.ip_address)
}

  useEffect(() => {
    // get user browser
    var browser = navigator.userAgent;
    setBrowser(browser);
    // get user device
    var device = navigator.platform;
    setDevice(device);
    // get user system
    var system = navigator.appVersion;
    setSystem(system);
    // get system ip
    getData();
    

    sessionStorage.setItem("profileURL", value);
    sessionStorage.setItem("Name", name);
  }, [value, name]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (data !== "") {
      loginWithJwt(data);
    } else {
      errors.showMessages();
    }
  };
  const loginWithJwt = (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      emailOrPhone: email,
      password: password,
      device: {
        "browser": browser,
        "device": device,
        "ip": ip || '',
        "system": system
      },
    };

    api
      .post("/auth/signin", payload)
      .then((res) => {
        setLoading(false);
        if (res.data.user.accountVerify === "VERIFY") {
          var roles = res.data.user.roles;
          var adminRole = roles.find(role => role.name === "ROLE_ADMIN");
          if (adminRole) {
            if (res.data.accessToken !== undefined && res.data.accessToken !== null) {
              sessionStorage.setItem('role', "ROLE_ADMIN");
              sessionStorage.setItem('phoneNumber', res.data.user.phoneNumber);
              setTimeout(() => {
                toast.success("Login Successful");
              }, 200);
              setValue(man);
              setName(res.data.user.firstName + " " + res.data.user.lastName);
              sessionStorage.setItem("token", res.data.accessToken);
              sessionStorage.setItem("user_data", res.data.user);

              // check if user has 2fa enabled
              if (res.data.user.twoFactorAuth) {
                var redirect = location.search.split("=")[1];
                sessionStorage.setItem("tempToken", res.data.accessToken);
                
                window.location.href = `${process.env.PUBLIC_URL}/admin/validate2fa?redirectTo=${redirect == null ? "/admin/dashboard" : redirect}`;
              } else {
                var redirect = location.search.split("=")[1];
                  window.location.href = "/admin/dashboard";
                // window.location.href = `${process.env.PUBLIC_URL}/admin/verify2fa?redirectTo=${redirect == null ? "/admin/dashboard" : redirect}`;
                // history("/admin/dashboard");
              }


              }
          } else {
            redirect = `${redirect}?email=${res.data.user.email}`
            setTimeout(() => {
              toast.success("Login Successful");
            }, 200);
            setValue(man);
            setName(res.data.user.firstName + " " + res.data.user.lastName);
            sessionStorage.setItem('role', "ROLE_USER");
            sessionStorage.setItem("token", res.data.accessToken);
            sessionStorage.setItem("user_data", res.data.user);
            
            if (res.data.user.twoFactorAuth) {
              var redirect = location.search.split("=")[1];
              sessionStorage.setItem("tempToken", res.data.accessToken);
              window.location.href = `${process.env.PUBLIC_URL}/validate2fa?redirectTo=${redirect == null ? "/dashboard" : redirect}`;
            } else {
              getBusiness(res.data.accessToken, res.data.user);
            }

          }
        } else {
          sessionStorage.setItem('phoneNumber', res.data.user.phoneNumber);
          setTimeout(() => {
            toast.error(
              "Please verify your account. We have sent you an email with a verification link."
            );
            window.location.href = `${process.env.PUBLIC_URL}/verify`;
          }
          , 200);
        }
        return res.data;
      })
      .catch((err) => {
          if (err) {
          setLoading(false);
          if (err) {
            setTimeout(() => {
              toast.error("Oppss.." + err.response.data.message);
            }, 200);
          }
        }
      });
  };

  const getBusiness = (token, user) => {
    var self = this;
    setLoading(true);
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "*",
      },
    };

    api
      .get("/business", axiosConfig)
      .then((res) => {
        setLoading(false);
        if (res.data.success === true) {
          if (res.data.data.length > 0) {
            sessionStorage.setItem("business", JSON.stringify(res.data.data[0]));
            sessionStorage.setItem(
              "business_list",
              JSON.stringify(res.data.data)
            );
        
            const redirect = location.search.split("=")[1];
            // if (redirect != null && redirect.includes("auth/spendo/callback")) {
            //     redirect = `${redirect}?email=${user?.email}&token=${token}&name=${user?.name || user?.email}&uuid=${user?.uuid}`
            // } else {
              window.location.href = redirect == null ? "/dashboard" : `${redirect}?email=${user?.email}&token=${token}&name=${user?.name || user?.email}&uuid=${user?.uuid}`;
            // }
            
          } else {
            sessionStorage.setItem("tempToken", token);
            window.location.href = `${process.env.PUBLIC_URL}/setup`;
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err) {
          sessionStorage.setItem("tempToken", token);
          window.location.href = `${process.env.PUBLIC_URL}/setup`;
        }
      });
  };
    return (
        <Fragment>
            <Form className="theme-form login-form">
                <div className="login-header text-center">
                    <img src={logo} alt="logo" style={{"width":200, "marginBottom":20}} />
                    <H6>Welcome back! Log in to your account.</H6>
                </div>
            
                <div className="login-social-title">
                    <H5>Sign in with Email</H5>
                </div>
                <FormGroup>
                    <Label>{EmailAddress}</Label>
                    <div className="input-group"><span className="input-group-text"><Mail /></span>
                        <Input
                         type="email"
                         name="EmailAddress"
                         placeholder="Email Address"
                         required
                         {...register("EmailAddress", { required: true })}
                         onChange={(e) => setEmail(e.target.value)}
                         defaultValue={email}/>
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label>{Password}</Label>
                    <InputGroup>
            <InputGroupText>
              <i className="icon-lock"></i>
            </InputGroupText>
            <Input
              className="form-control"
              type={togglePassword ? "text" : "password"}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              defaultValue={password}
              required
            />
            <div
              className="show-hide"
              onClick={() => setTogglePassword(!togglePassword)}
            >
              <span className={togglePassword ? "" : "show"}></span>
            </div>
          </InputGroup>
                </FormGroup>
                <FormGroup className="login-btn form-group">
                    <div className="checkbox">
                        <Input id="checkbox1" type="checkbox" />
                        <Label className="text-muted" for="checkbox1">{RememberPassword}</Label>
                    </div>
                    <Link to={`${process.env.PUBLIC_URL}/reset`} className="link">
                        {ForgotPassword}
                    </Link>
                </FormGroup>
                <FormGroup>
                <Btn
              type="submit"
              attrBtn={{
                textTransform:'capitalize',
                color: "primary",
                className: "btn-block",
                disabled: loading ? loading : loading,
                onClick: (e) => {
                  if (email === "" || password === "") {
                    setTimeout(() => {
                      toast.error("Email and Password are required");
                    }, 200);
                  } else {
                    loginWithJwt(e);
                  }
                },
              }}
            >
              {loading ? "Loading..." : SignIn}
            </Btn>
                </FormGroup>
                <P>Don't have account?
                    <Link to={`${process.env.PUBLIC_URL}/register`} className="ms-2">
                        {CreateAccount}
                    </Link>
                </P>
            </Form>
        </Fragment>
    );
};

export default LoginForm;