import React, { Fragment, useEffect } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import Contain from '../../Component/Whatsapp';

const WhatsappOverview = () => {
  useEffect(() => {
    document.title = 'Whatsapp Overview - Spendo';
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Services" title="Whatsapp Overview" />
      <Contain />
    </Fragment>
  );
};
export default WhatsappOverview;