import React, { useEffect, useState } from 'react';
import Loader from '../Layout/Loader';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../api/jwt";
import LoadingSpinner from "./../CommonElements/Loader/LoadingSpinner";

const VerifyAccount = () => {
  const history = useNavigate();
  const location = useLocation();
  const otp_hash = location.search.split("=")[1];
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    document.title = 'Verify Account - Spendo';
    verifyAccount(otp_hash)
  }, []);

  const verifyAccount = (otp_hash) => {
    if (otp_hash === undefined) {
      history.push("/");
    } else {
      setIsLoading(true);
      api.post("/auth/verify/otp/" + otp_hash + "==")
        .then(res => {
          setIsLoading(false);
          setTimeout(() => {
            toast.success("Account verified Successful");
            window.location.href = `${process.env.PUBLIC_URL}/login`;
          }, 500);

        }).catch(err => {
          setIsLoading(false);
          setTimeout(() => {
            toast.error(err.response.data.message);
            window.location.href = `${process.env.PUBLIC_URL}/login`;
          }, 500);
        }
        );
    }
  }

  return (
    <div>
      <Loader />
      {isLoading ? <LoadingSpinner /> : <Loader />}
    </div>
  );

};

export default VerifyAccount;