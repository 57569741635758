import React, { Fragment, useEffect } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import Contain from '../../Component/Voice';

const VoiceOverview = () => {
  useEffect(() => {
    document.title = 'Voice Overview - Spendo';
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Services" title="Voice Overview" />
      <Contain />
    </Fragment>
  );
};
export default VoiceOverview;