import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from "reactstrap";
import { Btn, H5, UL, H4, H6 } from "../AbstractElements";
import {
  EmailAddress,
  CreateAccount,
  Password,
  SignIn,
  Business,
  Bus,
  Phone,
  PhoneNumber,
} from "../Constant";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { firebase_app, Jwt_token } from "../Config/Config";
import man from "../assets/images/dashboard/1.png";
import { handleResponse } from "../Services/Fack.Backend";
import api from "../api/jwt";
import { Formik } from "formik";
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


const SetupForm = ({ selected }) => {
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const [businessList, setBusinessList] = useState([])
  const [countryList, setCountryList] = useState([])
  const [businessPhone, setBusinessPhone] = useState("")

  useEffect(() => {
    getBusinessList();
  }, [])

  const getBusinessList = () => {
    const businessList = require("../Data/businessList.json");
    const countryList = require("../Data/countryList.json");
    setBusinessList(businessList);
    setCountryList(countryList);
  }

  const registerUser = async (
    businessName,
    businessRegNo,
    businessType,
    country
  ) => {
    setLoading(true);
    const payload = {
      businessName,
      businessPhone,
      businessRegNo,
      businessType,
      country,
      businessDescription: "test",
      businessAddress: "test",
    };
    api
      .post("/business/create", payload)
      .then((res) => {
        setLoading(false);

        getBusiness();
        return res.data;
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
          setTimeout(() => {
            toast.error("Oppss.." + err.response.data.message);
          }, 200);
        }
      });
  };

  const getBusiness = () => {
    var self = this;
    setLoading(true);
    api
      .get("/business")
      .then((res) => {
        setLoading(false);
        if (res.data.data !== undefined && res.data.data !== null) {
          var latestBusiness = res.data.data[0];
          sessionStorage.setItem("business", JSON.stringify(latestBusiness));
          sessionStorage.setItem(
            "business_list",
            JSON.stringify(res.data.data)
          );
            setTimeout(() => {
              toast.success(
                "Business has been setup successfully."
              );
              window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
            }, 200);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err) {
          sessionStorage.setItem("tempToken", sessionStorage.getItem("token"));
          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      });
  };
  return (
    <Fragment>
      <Formik
        initialValues={{
          businessName: "",
          businessRegNo: "",
          businessType: "",
          country: "",
        }}
        validate={(values) => {
          const errors = {};

          if (!values.businessName) {
            errors.businessName = "Required";
          }

          if (!businessPhone) {
            errors.businessPhone = "Required";
          }

          if (!values.businessRegNo) {
            errors.businessRegNo = "Required";
          }
          if (!values.businessType) {
            errors.businessType = "Required";
          }
          if (!values.country) {
            errors.country = "Required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            registerUser(
              values.businessName,
              values.businessRegNo,
              values.businessType,
              values.country
            );
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form
            onSubmit={handleSubmit}
            className="theme-form login-form mb-50"
            style={{ marginBottom: 50 }}
          >
             <H4>{"Set up your Business"}</H4>
        <H6>
          {"Before start using your account, you have to setup a business"}
        </H6>
            <FormGroup>
              {/* <Label>{FirstName}</Label> */}
              <InputGroup>
                <InputGroupText>
                  <i className="icon-user"></i>
                </InputGroupText>
                <Input
                  className="form-control"
                  type="text"
                  name="businessName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.businessName}
                  placeholder="Business Name"
                />
              </InputGroup>
              {errors.businessName &&
                touched.businessName &&
                errors.businessName}
            </FormGroup>
            {/* <FormGroup>
            <PhoneInput
      placeholder="Enter phone number"
      value={value}
      onChange={setValue}/>
            </FormGroup> */}
            <FormGroup>
              {/* <Label>{LastName}</Label> */}
              <InputGroup>
                {/* <InputGroupText>
                  <i className="icon-mobile"></i>
                </InputGroupText> */}
                {/* <Input
                  className="form-control"
                  type="tel"
                  name="businessPhone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.businessPhone}
                  placeholder="Business Phone"
                /> */}
              <PhoneInput
                  country={'ng'}
                  value={businessPhone}
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                  inputClass="form-control"
                  inputStyle={{ width: "90%", height: "48px", marginLeft: "40px" }}
                  onChange={phone => setBusinessPhone(phone)}
                  // onChange={handleChange}
                  placeholder={"Business phone number"}
                />

              </InputGroup>
              {errors.businessPhone &&
                touched.businessPhone &&
                errors.businessPhone}
            </FormGroup>
            <FormGroup>
              {/* <Label>{EmailAddress}</Label> */}
              <InputGroup>
                <InputGroupText>
                  <i className="icon-key"></i>
                </InputGroupText>
                <Input
                  className="form-control"
                  type="text"
                  name="businessRegNo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.businessRegNo}
                  placeholder="Business Registration Number (CAC)"
                />
              </InputGroup>
              {errors.businessRegNo &&
                touched.businessRegNo &&
                errors.businessRegNo}
            </FormGroup>
            
            <FormGroup>
            <InputGroup>
            <InputGroupText>
                  <i className="icon-briefcase"></i>
                </InputGroupText>
            <Input
                          type="select"
                          name="businessType"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.businessType}
                          className="form-control"
                          defaultValue="1"
                        >
                  <option defaultValue>Select Business Type</option>
                  {businessList.map((business) => (
                    <option key={business} value={business}>
                      {business}
                    </option>
                  ))}
                </Input>
              </InputGroup>
              {errors.businessType &&
                touched.businessType &&
                errors.businessType}
            </FormGroup>


            <FormGroup>
            <InputGroup>
            <InputGroupText>
                  <i className="icon-list"></i>
                </InputGroupText>
            <Input
                          type="select"
                          name="country"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.country}
                          className="form-control"
                          defaultValue="1"
                        >
                  <option defaultValue>Select Country</option>
                  {countryList.map((country) => (
                    <option key={country.code} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </Input>
              </InputGroup>
              {errors.businessType &&
                touched.businessType &&
                errors.businessType}
            </FormGroup>

            <button
              style={
                {textTransform:'capitalize'}
              }
              variant="primary"
              type="submit"
              className="btn btn-block btn-primary"
              disabled={isSubmitting}
            >
              {loading ? "LOADING..." : "Setup Business"}
            </button>

            <br/>
          <div className="text-center">
            <Link to="/login">
              {"Back to Login "}
            </Link>
          </div>
        </Form>
        )}
      </Formik>

      <br />
    </Fragment>
  );
};

export default SetupForm;
