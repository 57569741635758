import React, { Fragment, useState, useEffect } from 'react';
import { Image } from '../../../AbstractElements';
import { Btn, H5, H3, LI, P, UL } from '../../../AbstractElements';
import { Col, Form, FormGroup,Card, Label, InputGroup, InputGroupAddon, InputGroupText, Input, Row } from 'reactstrap';
import api from '../../../api/jwt'
import { useForm } from "react-hook-form";

import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

const ChangePassword = (props) => {
  const { user } = props;
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [loading, setLoading] = useState(false);

  const changePass = async (e) => {
    setLoading(true);
    const payload = {
        newPassword: newPassword,
        oldPassword: oldPassword,
      };      
      api.post('/auth/change',payload)
          .then(res => {
              setLoading(false);
              setTimeout(() => {
                  toast.success(
                      'Password change successfully!'
                  );
                window.location.reload();
              }, 200);
              return res.data;
          })
          .catch(err => {
            if (err) {
                  setLoading(false);
                  setTimeout(() => {
                      toast.error('Oppss..' + err.response.data.message);
                  }, 200);
              }
          });   
  }

  return (
    <Fragment>
      <Col xl="12" lg="12" md="7" className="xl-65">
        <Row className="row">
          <Col sm="12">
            <Card>
            
              <div className="profile-post">
              <H3 attrH5={{ className: 'p-0' }}>
              Change Password
                </H3>
                
                <Row>
          <Col md="12 mb-3">
                    
                         <Form>   
                  <FormGroup>
                    <Label>Enter old Password</Label>
                        <div className="input-group"><span className="input-group-text">
                        <i className="fa fa-lock" />
                    </span>
                        <Input
                         type="password"
                         name="oldPassword"
                         placeholder="Old password"
                         required
                        //  {...changePass("oldPassword", { required: true })}
                         onChange={(e) => setOldPassword(e.target.value)}
                         defaultValue={oldPassword}/>
                    </div>
                      </FormGroup>
                      <FormGroup>
                    <Label>Enter new Password</Label>
                        <div className="input-group"><span className="input-group-text">
                        <i className="fa fa-lock" />
                    </span>
                        <Input
                         type="password"
                         name="newPassword"
                         placeholder="New password"
                         required
                        //  {...changePass("newPassword", { required: true })}
                         onChange={(e) => setNewPassword(e.target.value)}
                         defaultValue={newPassword}/>
                    </div>
                      </FormGroup>
                      <Btn
              type="submit"
              attrBtn={{
                textTransform:'capitalize',
                color: "primary",
                className: "btn-block",
                disabled: loading ? loading : loading,
                onClick: (e) => {
                  if (oldPassword === "") {
                    setTimeout(() => {
                      toast.error("old Password are required");
                    }, 200);
                  } else if (newPassword === "") {
                    setTimeout(() => {
                      toast.error("new Password are required");
                    }, 200);
                  }
                  else {
                    changePass(e);
                  }
                },
              }}
            >
              {loading ? "Loading..." : "Change Password"}
            </Btn>

                      </Form>
                    </Col>
                  
                </Row>
                
        
              </div>
            </Card>
          </Col>
        </Row>
      </Col >
    </Fragment >
  );
};
export default ChangePassword;