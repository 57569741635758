import { H5, P } from "../../AbstractElements";
import React, { Fragment, useEffect, useState } from "react";
import LoadingSpinner from "./../../CommonElements/Loader/LoadingSpinner";
import {
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
} from "reactstrap";
import api from "../../api/jwt";
import { Formik } from "formik";
import { toast } from "react-toastify";

const NGNcurrencyFormat = require("../../utils/NGNcurrencyFormat");

const WhatsAppSubPageContain = (props) => {
  const { business, currentSubscriptions } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptions_plans, setSubscriptionPlans] = React.useState([]);
  const [planName, setPlanName] = useState("");

  useEffect(() => {
    const fetchSubscriptions = () => {
      setSubscriptionPlans([]);
      setIsLoading(true);
      api
        .get(
          "/business/whatsapp-credit/plans/" +
            planName +
            "/0/100/DESC/createdAt"
        )
        .then((res) => {
          setIsLoading(false);
          setSubscriptionPlans(res.data.data.content);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    };
    fetchSubscriptions();
  }, [planName]);

  const subscribeToPlan = (planId) => {
    // confirmation alert
    var confirmation = window.confirm(
      "Are you sure you want to subscribe to this plan?"
    );
    if (confirmation) {
      var self = this;
      setIsLoading(true);
      const payload = {
        planId: planId,
      };

      api
        .post("/business/whatsapp-credit/" + business.uuid, payload)
        .then((res) => {
          setIsLoading(false);
          setTimeout(() => {
            if (res.status === 200) {
              toast.success(res.data.message);
            } else {
              toast.error(res.data.message);
            }

            // window.location = "/whatsapp/subscription";
          }, 200);
        })
        .catch((err) => {
          if (err) {
            setIsLoading(false);
            setTimeout(() => {
              toast.error("Oppss.." + err.response.data.message);
              console.log(err);
            }, 200);
          }
          // console.log(err);
        });
    }
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>New Subscription</H5>
                <span>
                  <P>Subscribe to a new plan</P>
                </span>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="12">
                    <select
                      className="form-control mb-3"
                      name="productDescription"
                      onChange={(e) => setPlanName(e.target.value)}
                    >
                      <option value="">Select Plan Type</option>
                      <option value="Authentication">Authentication</option>
                      <option value="Marketing">Marketing</option>
                      <option value="Service">Service</option>
                      <option value="Utility">Utility</option>
                    </select>
                  </Col>
                  {isLoading && <LoadingSpinner />}
                  {planName !== "" ? (
                    subscriptions_plans.map((subscription_plan) => (
                      <Col sm="4">
                        <Card
                          style={{
                            backgroundColor: "#0694A2",
                            color: "white",
                          }}
                        >
                          <CardBody>
                            <h3>{subscription_plan.planName}</h3>

                            <p>
                              <b>Description: </b>
                              {subscription_plan.planDescription}
                            </p>
                            <p>
                              <b>Plan Type: </b>
                              {subscription_plan.planType}
                            </p>
                            <p>
                              <b>Price: </b>
                              {NGNcurrencyFormat.default(
                                subscription_plan.price
                              )}
                            </p>
                            <p>
                              <b>Number of Messages: </b>
                              {subscription_plan.messageCount}
                            </p>

                            <p>
                              <b>Currency: </b>
                              {subscription_plan.currency}
                            </p>

                            <hr />
                            <button
                              variant="default"
                              onClick={() => {
                                subscribeToPlan(subscription_plan.id);
                              }}
                              type="button"
                              className="btn btn-block btn-default "
                              style={{
                                backgroundColor: "#fff",
                                color: "#0694A2",
                              }}
                              disabled={isLoading}
                            >
                              {isLoading ? "Subscribing..." : "Subscribe"}
                            </button>
                          </CardBody>
                        </Card>
                      </Col>
                    ))
                  ) : (
                    <Col sm="12">
                      <h5>No subscriptions available</h5>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default WhatsAppSubPageContain;
