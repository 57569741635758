import { H5, P, Btn } from "../../AbstractElements";
import React, { Fragment, useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import { useForm } from "react-hook-form";
import api from "../../api/jwt";
import { Formik } from "formik";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";

const SamplePageContain = (props) => {
  const { business, products } = props;

  // check subscription
  const [subscription, setSubscription] = useState(false);

  useEffect(() => {
    // check subscription api
    api
      .get("/subscriptions/check/" + business.uuid)
      .then((res) => {
        // console.log(res.data);
        if (res.data === "No subscription found") {
          setSubscription(false);
        } else {
          setSubscription(true);
        }
      })
      .catch((err) => {
        if (err) {
          // console.log(err);
          setSubscription(false);
        }
      });
  }, []);

  const deleteProduct = (productId) => {
    api
      .delete("/product/delete/" + productId)
      .then((res) => {
        toast.success("Product deleted successfully");
        window.location.reload();
      })
      .catch((err) => {
        toast.error("Error deleting product");
      });
  };

  const copyProductLink = (productId) => {
    const el = document.createElement("textarea");
    el.value = "https://api.whatsapp.com/send?phone=2349069019514&text=product_" + productId;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    toast.success("Product link copied to clipboard");
  };


  const columns = [
    {
      name: "productName",
      selector: (row) => row.productName,
      sortable: true,
    },
    {
      name: "productCategory",
      selector: (row) => row.productCategory,
      sortable: true,
    },
    {
      name: "Link",
      selector: (row) => row.productId,
      cell: (row) => <>
      <button style={{"margin": 5}} className="btn btn-success btn-sm" onClick={() => copyProductLink(row.productId)}><i className="fa fa-link"></i></button>
    </>,
    },
    {
      name: "productPrice",
      selector: (row) => row.productPrice,
      sortable: true,
      right: true,
    },

    {
      name: "productStatus",
      selector: (row) => row.productStatus,
      sortable: true,
      right: true,
    },
    {
      name:"Image",
      button: true,
      cell: (row) => <>
        <Link to={`/cart/productImage/${row.productId}`}><img src={row.productImage} alt="productImage" width={60} style={
          {
            borderRadius: "50%",
            border: "1px solid #ccc",
            padding: "5px"
          }
      } />
          </Link>
      </>,
    },
    {
      name: "ACTION",
      button: true,
      sortable: true,
      right: true,
      cell: (row) => <>
        <Link style={{"margin": 2}} to={`/cart/updateProduct/${row.productId}`} className="btn btn-primary btn-sm"><i className="fa fa-edit"></i></Link>
        <button style={{"margin": 2}}  className="btn btn-danger btn-sm" onClick={() => deleteProduct(row.productId)}><i className="fa fa-trash"></i></button>
      </>,
    },
  ];
  return (
    <Fragment>
      <Container fluid={true}>


        <Row>
          <Col sm="12">
            {subscription ? (
              <div className="d-flex justify-content-end my-2 mx-2">
              {" "}
              <Link to="/cart/addProduct" className="btn btn-primary btn-sm">
                Add Product
                </Link>
                </div>
            ) : (
              <div className="d-flex justify-content-end my-2 mx-2">
              {" "}
                <Link to="/cart/subscriptions" className="btn btn-primary btn-sm">
                  Subscribe to add product
                  </Link>
                  </div>
              )}
    
            <Card>
              <CardHeader className="pb-0">
                {/* <H5>Transactions</H5> */}

                <DataTable
                  // title="Transactions"
                  columns={columns}
                  data={products}
                  defaultSortFieldID={1}
                  pagination
                  striped={true}
                  center={true}
                  // paginationComponent={BootyPagination}
                  // selectableRows
                  // selectableRowsComponent={BootyCheckbox}
                />
              </CardHeader>
              <CardBody>
                <p>-</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default SamplePageContain;
