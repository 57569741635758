import React, { Fragment } from "react";
import { Edit, ShoppingBag, Trash2 } from "react-feather";
import { Link } from "react-router-dom";
import { Input, Media } from "reactstrap";
import { LI, Image, UL, P, H5, H3, H4 } from "../../../AbstractElements";
import { CartBoxSvg } from "../../../Data/svgIcons";
import { toast } from "react-toastify";

const Businesses = (props) => {
  const { business_list, business } = props;

  const SwitchAccount = (index) => {
    var biz = JSON.parse(sessionStorage.getItem("business_list"));
    sessionStorage.setItem("business", JSON.stringify(biz[index]));

    window.location.reload();
    setTimeout(() => {
      toast.success("Switched Successful");
    }, 200);
  };
  return (
    <Fragment>
      <LI attrLI={{ className: "cart-nav onhover-dropdown" }}>
        <div className="cart-box">
          Switch <i className="fa fa-exchange"></i>
          <span className="badge rounded-pill badge-primary">
            {business_list.length}
          </span>
        </div>
        <div className={`cart-dropdown onhover-show-div`}>
          <div className="dropdown-title">
            <H3>Businesses</H3>
            <a className="f-right" href="#javascript">
              <ShoppingBag />
            </a>
          </div>
          <UL attrUL={{ className: `simple-list border-top custom-scrollbar` }}>
            {business_list.map((item, index) => {
              return (
                <a onClick={() => SwitchAccount(index)}>
                  <LI key={item.id}>
                    <Media>
                      <Media body>
                        <H5>{item.businessName}</H5>
                        <P attrPara={{ className: "f-w-700" }}>
                          {item.businessRegNo}
                        </P>
                      </Media>
                      <div className="close-circle">
                        <a href="#javascript">
                          <i className="fa fa-exchange"></i>
                        </a>
                      </div>
                    </Media>
                  </LI>
                </a>
              );
            })}
            <LI attrLI={{ className: "order-total" }}>
              <H5 attrH4={{ className: "mb-0 f-w-700" }}>Not in list? </H5>
              <Link
                className="btn btn-primary view-checkout"
                to={`${process.env.PUBLIC_URL}/setup`}
              >
                Add New Business
              </Link>
            </LI>

            <LI attrLI={{ className: "order-total" }}>
              <div>Current Business: {business.businessName}</div>
            </LI>
          </UL>
        </div>
      </LI>
    </Fragment>
  );
};
export default Businesses;
