import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import DevelopersContain from '../../Component/Developers/index';
import api from '../../api/jwt'
import LoadingSpinner from "./../../CommonElements/Loader/LoadingSpinner";

const Developers = () => {
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('');
  const [apiKeys, setApiKeys] = useState([]);
  const [user, setUser] = useState([]);
  const [business, setBusiness] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = 'Developers - Spendo';
    setName(sessionStorage.getItem('Name')); 
    const businesss = JSON.parse(sessionStorage.getItem('business'));
    setBusiness(businesss);
    const authenticated = JSON.parse(sessionStorage.getItem('authenticated'));
    const auth0_profile = JSON.parse(sessionStorage.getItem('auth0_profile'));
    const business_list = JSON.parse(sessionStorage.getItem('business_list'));
    const user_data = sessionStorage.getItem('user_data');
    setUser(user_data);
    setIsLoading(true)
    api.get('/api/keys/' + businesss.uuid)
      .then(res => {
        setIsLoading(false)
        setApiKeys(res.data.data);
      })
      .catch(err => {
        setIsLoading(false)

      });

  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Business" title="Developers" />
      {isLoading ? <LoadingSpinner /> : <DevelopersContain  business={business} apiKeys={apiKeys} />}

    </Fragment>
  );
};
export default Developers;