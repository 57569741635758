import React, { Fragment, useState, useEffect } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import OrderContain from "../../Component/Cart/orderView";
import api from "../../api/jwt";
import LoadingSpinner from "./../../CommonElements/Loader/LoadingSpinner";


const OrderView = () => {
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const [order, setOrder] = React.useState([]);
  const [product, setProduct] = React.useState([]);
  const [business, setBusiness] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    document.title = "Order Overview - Spendo";
    setName(sessionStorage.getItem("Name"));
    const businesss = JSON.parse(sessionStorage.getItem("business"));
    setBusiness(businesss);
    const authenticated = JSON.parse(sessionStorage.getItem("authenticated"));
    const auth0_profile = JSON.parse(sessionStorage.getItem("auth0_profile"));
    const business_list = JSON.parse(sessionStorage.getItem("business_list"));
    const token = sessionStorage.getItem("token");
       // get pathname from url
    const pathname = window.location.pathname;
    // get first part of pathname using split /
    const path = pathname.split("/");
    // get last part of pathname using split /
    const orderId = path[path.length - 2];
    const productId = path[path.length - 1];
    
    setIsLoading(true);
    api
      .get("/product/order/" + orderId + "/" + productId)
      .then((res) => {
        setOrder(res.data);
        setProduct(res.data.product);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Cart" title="Order Overview" />
      {isLoading ? <LoadingSpinner /> : <OrderContain business={business} order={order} product={product} />}
    </Fragment>
  );
};
export default OrderView;
