import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import ProfileContain from '../../../Component/Admin/Business/Profile';
import api from '../../../api/jwt'
import LoadingSpinner from "../../../CommonElements/Loader/LoadingSpinner";


export default function Profile() {
  const [business, setBusiness] = useState([]);
  const [wallet, setWallet] = useState([]);
  const [businessAccountDetails, setBusinessAccountDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  // get pathname from url
  const pathname = window.location.pathname;
  const uuid = pathname.split('/').pop();
  useEffect(() => {
    setIsLoading(true);
    api.get('/admin/business/' + uuid)
      .then(res => {
          console.log(res.data.data);
          setIsLoading(false)
          setBusiness(res.data.data.business);
          setWallet(res.data.data.walletBusiness);
          setBusinessAccountDetails(res.data.data.businessAccountDetails);
      })
      .catch(err => {
        setIsLoading(false)
      });
  }, []);

  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="Business Profile" />
      {isLoading ? <LoadingSpinner /> : <ProfileContain business={business} wallet={wallet} businessAccountDetails={businessAccountDetails} />}
    </Fragment>
  )
}
