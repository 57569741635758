import React, { Fragment, useState, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Security from './Security';
import TwoFactorInfo from './TwoFactorInfo';
import ProfileHeader from './ProfileHeader';
import ChangePassword from './ChangePassword';
import api from '../../../api/jwt'

const UsersProfileContain = (props) => {
  const { user } = props;
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <ProfileHeader user={user}/>

            <Col xl="4" md="5" lg="12" className="xl-35">
              <div className="default-according style-1 faq-accordion job-accordion">
                <Row>
                  <Security user={user} />
                </Row>
              </div>
            </Col>
            <TwoFactorInfo user={user} />


            <ChangePassword user={user} />
          </Row>

        </div>
      </Container>
    </Fragment>
  );
};
export default UsersProfileContain;