import { H5, P } from "../../AbstractElements";
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
} from "reactstrap";
import api from "../../api/jwt";
import { Formik } from "formik";
import { toast } from "react-toastify";
const NGNcurrencyFormat = require('../../utils/NGNcurrencyFormat');

const SubscriptionsContain = (props) => {
  const { business, subscriptions, subscriptions_plans } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [productDescription, setProductDescription] = useState("");

  const subscribeToPlan = (planId
  ) => {
    // confirmation alert
    var confirmation = window.confirm("Are you sure you want to subscribe to this plan?");
    if (confirmation) {

    var self = this;
    setIsLoading(true);
    const payload = {
      businessId: business.uuid,
      planId: planId,
    };

    api
      .post("/subscribe", payload)
      .then((res) => {
        setIsLoading(false);
        setTimeout(() => {
          if (res.data === "Insufficient balance") {
            toast.error(res.data);
          } else {
            toast.success(res.data);
          }
          window.location.reload();
        }, 200);

        
      })
      .catch((err) => {
        if (err) {
          setIsLoading(false);
          setTimeout(() => {
            toast.error("Oppss.." + err.response.data);
          }, 200);
        }
        // console.log(err);
      });

    }
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Subscriptions </H5>
                <span>
                  <P>
                    Subscribe to our services to get the best out of your
                    business
                  </P>
                </span>
              </CardHeader>
              <CardBody>

                {subscriptions != 'No subscription found' ? (
                  <Fragment>
                    <Row>
                        <Col sm="4">
                          <Card>
                            <CardBody>
                            <h3>Subscribed</h3>
                            
                              <p>
                                <b>Started Date: </b>
                              {new Date(subscriptions.subscriptionStartDate).toLocaleDateString()}
                              </p>
                              <p>
                              <b>End Date: </b>                            
                              {/* {subscriptions.subscriptionEndDate} */}
                              {/* format date */}
                              {new Date(subscriptions.subscriptionEndDate).toLocaleDateString()}

                              </p>
                              <p>
                                <b>Status: </b>
                                {subscriptions.subscriptionStatus}
                            </p>

                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                api
                                  .post("/cancel/subscription/" + business.uuid)
                                  .then((res) => {
                                    setIsLoading(false);
                                    setTimeout(() => {
                                      toast.success(res.data);
                                      window.location.reload();
                                    }, 200);
                                  })
                                  .catch((err) => {
                                    if (err) {
                                      setIsLoading(false);
                                      setTimeout(() => {
                                        toast.error("Oppss.." + err.response.data);
                                      }, 200);
                                    }
                                  });
                              }}
                            >
                              Cancel Subscription
                            </button>
                            

                            </CardBody>
                          </Card>
                        </Col>

                    </Row>
                  </Fragment>
                ) : (
                    <Fragment>
                      <Row>
                        <Col sm="12">
                          {/* <h5>No subscriptions available</h5> */}
                          
                          <Row>
                       
                          {subscriptions_plans.map((subscription_plan) => (
                            <Col sm="4">

                          <Card style={{"backgroundColor" : "#0694A2", "color": "white"}}>
                            <CardBody>
                                  <h3>{subscription_plan.planName}</h3>
                            
                              <p>
                                <b>Description: </b>
                                {subscription_plan.planDescription}
                              </p>
                              <p>
                              <b>Price: </b>                            
                                {NGNcurrencyFormat.default(subscription_plan.planPrice)}
                              </p>
                              <p>
                                <b>Duration: </b>
                                {subscription_plan.planDuration}
                                  </p>

                                  <p>
                                <b>Duration Type: </b>
                                {subscription_plan.planDurationType}
                                  </p>
                          
                                  <hr />
                                  <button
                                    variant="default"
                                    onClick={() => {
                                      subscribeToPlan(subscription_plan.planId);
                                    }}
                        type="button"
                        className="btn btn-block btn-default"
                        disabled={isLoading}
                      >
                        {isLoading ? "Subscribing..." : "Subscribe"}
                      </button>

                            </CardBody>
                          </Card>
                            
                          </Col>
                          ))}
                            
                            </Row>

                          

                        </Col>
                      </Row>
                    </Fragment>
                  )}

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default SubscriptionsContain;
