import { H5, P } from "../../../AbstractElements";
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
} from "reactstrap";
import api from "../../../api/jwt";
import { Formik } from "formik";
import { toast } from "react-toastify";
import {Editor, EditorState} from 'draft-js';
import 'draft-js/dist/Draft.css';

const SwitchContain = (props) => {
  const { systemSwitch } = props;
  const [isLoading, setIsLoading] = useState(false);

  const emailRoute = systemSwitch.emailRoute;

  const updatePricing = (
    smsMessage,
    smsMessageBulk,
    smsMessageInt,
    smsOtp,
    smsOtpInt,
    verificationBvn,
    verificationNin,
    verificationPhone,
    voiceOtp,
    whatsappMessage,
    whatsappMessageTemplate,
    whatsappOtp
  ) => {
    var self = this;
    setIsLoading(true);
    const payload = {
      "smsMessage": smsMessage,
      "smsMessageBulk": smsMessageBulk,
      "smsMessageInt": smsMessageInt,
      "smsOtp": smsOtp,
      "smsOtpInt": smsOtpInt,
      "verificationBvn": verificationBvn,
      "verificationNin": verificationNin,
      "verificationPhone": verificationPhone,
      "voiceOtp": voiceOtp,
      "whatsappMessage": whatsappMessage,
      "whatsappMessageTemplate": whatsappMessageTemplate,
      "whatsappOtp": whatsappOtp
    };

    api
      .put("/admin/updatePricingByBusinessId/", payload)
      .then((res) => {
        setIsLoading(false);
        setTimeout(() => {
          toast.success("Pricing updated successfully");
          window.location.href = "/admin/businessesProfile/";
        }, 200);

        
      })
      .catch((err) => {
        if (err) {
          setIsLoading(false);

          setTimeout(() => {
            toast.error("Oppss.." + err.response.data);
          }, 200);
        }
        // console.log(err.response.data);
      });
  };


  const handleCheckBox = (checked) => {
    var self = this;
    alert(checked);
  }

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Switch Services </H5>
              </CardHeader>
              <CardBody>

              <Formik
                  initialValues={{
                    // smsMessage: pricing?.smsMessage,
                    // smsMessageBulk: pricing?.smsMessageBulk,
                    // smsMessageInt: pricing?.smsMessageInt,
                    // smsOtp: pricing?.smsOtp,
                    // smsOtpInt: pricing?.smsOtpInt,
                    // verificationBvn: pricing?.verificationBvn,
                    // verificationNin: pricing?.verificationNin,
                    // verificationPhone: pricing?.verificationPhone,
                    // voiceOtp: pricing?.voiceOtp,
                    // whatsappMessage: pricing?.whatsappMessage,
                    // whatsappMessageTemplate: pricing?.whatsappMessageTemplate,
                    // whatsappOtp: pricing?.whatsappOtp,
                  }}
                  validate={(values) => {
                    const errors = {};
                    // if (!values.smsMessage) {
                    //   errors.smsMessage = "Required";
                    // } else if (!values.smsMessageBulk) {
                    //   errors.smsMessageBulk = "Required";
                    // } else if (!values.smsMessageInt) {
                    //   errors.smsMessageInt = "Required";
                    // } else if (!values.smsOtp) {
                    //   errors.smsOtp = "Required";
                    // } else if (!values.smsOtpInt) {
                    //   errors.smsOtpInt = "Required";
                    // } else if (!values.verificationBvn) {
                    //   errors.verificationBvn = "Required";
                    // } else if (!values.verificationNin) {
                    //   errors.verificationNin = "Required";
                    // } else if (!values.verificationPhone) {
                    //   errors.verificationPhone = "Required";
                    // } else if (!values.voiceOtp) {
                    //   errors.voiceOtp = "Required";
                    // } else if (!values.whatsappMessage) {
                    //   errors.whatsappMessage = "Required";
                    // } else if (!values.whatsappMessageTemplate) {
                    //   errors.whatsappMessageTemplate = "Required";
                    // } else if (!values.whatsappOtp) {
                    //   errors.whatsappOtp = "Required";
                    // }
                    
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    // setTimeout(() => {
                      updatePricing(
                      values.smsMessage,
                      values.smsMessageBulk,
                      values.smsMessageInt,
                      values.smsOtp,
                      values.smsOtpInt,
                      values.verificationBvn,
                      values.verificationNin,
                      values.verificationPhone,
                      values.voiceOtp,
                      values.whatsappMessage,
                      values.whatsappMessageTemplate,
                      values.whatsappOtp
                    );
                    setSubmitting(false);
                    // }, 400);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit} className="mt-4">

                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Service</th>
                            <th scope="col">Switches</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Email Route</td>
                            <td>
                              <tr>
                                <td>Mailjet {systemSwitch?.emailRoute === 'mailjet' ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
                                  <br />
                                  <div className="form-check form-switch">
                                    <input
                                        name="emailRoute"
                                        value="mailjet"
                                        defaultChecked={systemSwitch.emailRoute === 'mailjet' ? true : false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckDefault"
                                      />
                                  </div>
                                </td>
                                <td>Sendgrid {systemSwitch?.emailRoute === 'Sendgrid' ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
                                  <br />
                                  <div className="form-check form-switch">
                                    <input
                                        name="emailRoute"
                                        value="mailjet"
                                        defaultChecked={systemSwitch.emailRoute === 'Sendgrid' ? true : false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckDefault"
                                      />
                                  </div>
                                </td>
                              </tr>

                            </td>
                          </tr>

                          <tr>
                            <td>SMS Local Route</td>
                            <td>
                              <tr>
                                <td>Hollatags {systemSwitch?.smsLocalRoute === 'hollatags' ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
                                  <br />
                                  <div className="form-check form-switch">
                                    <input
                                        name="smsLocalRoute"
                                        value="hollatags"
                                        defaultChecked={systemSwitch.smsLocalRoute === 'hollatags' ? true : false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckDefault"
                                      />
                                  </div>
                                </td>
                                <td>Termii {systemSwitch?.smsLocalRoute === 'termii' ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
                                  <br />
                                  <div className="form-check form-switch">
                                    <input
                                        name="smsLocalRoute"
                                        value="termii"
                                        defaultChecked={systemSwitch.smsLocalRoute === 'termii' ? true : false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckDefault"
                                      />
                                  </div>
                                </td>
                              </tr>

                            </td>
                          </tr>


                          <tr>
                            <td>SMS Int Route</td>
                            <td>
                              <tr>
                                <td>Vonage {systemSwitch?.smsInternationalRoute === 'vonage' ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
                                  <br />
                                  <div className="form-check form-switch">
                                    <input
                                        name="smsInternationalRoute"
                                        value="vonage"
                                        defaultChecked={systemSwitch.smsInternationalRoute === 'vonage' ? true : false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckDefault"
                                      />
                                  </div>
                                </td>
                                <td>D7Networks {systemSwitch?.smsInternationalRoute === 'd7networks' ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
                                  <br />
                                  <div className="form-check form-switch">
                                    <input
                                        name="smsInternationalRoute"
                                        value="d7networks"
                                        defaultChecked={systemSwitch.smsInternationalRoute === 'd7networks' ? true : false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckDefault"
                                      />
                                  </div>
                                </td>
                              </tr>

                            </td>
                          </tr>

                          <tr>
                            <td>Voice Local Route</td>
                            <td>
                              <tr>
                                <td>Termii {systemSwitch?.voiceLocalRoute === 'termii' ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
                                  <br />
                                  <div className="form-check form-switch">
                                    <input
                                        name="voiceLocalRoute"
                                        value="termii"
                                        defaultChecked={systemSwitch.voiceLocalRoute === 'termii' ? true : false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckDefault"
                                      />
                                  </div>
                                </td>
                                <td>Smart SMS {systemSwitch?.voiceLocalRoute === 'smartSMS' ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
                                  <br />
                                  <div className="form-check form-switch">
                                    <input
                                        name="voiceLocalRoute"
                                        value="smartSMS"
                                        defaultChecked={systemSwitch.voiceLocalRoute === 'smartSMS' ? true : false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckDefault"
                                      />
                                  </div>
                                </td>
                              </tr>

                            </td>
                          </tr>
                          



                          <tr>
                            <td>Voice Int Route</td>
                            <td>
                              <tr>
                                <td>Vonage {systemSwitch?.voiceRoute === 'vonage' ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
                                  <br />
                                  <div className="form-check form-switch">
                                    <input
                                        name="voiceRoute"
                                        value="vonage"
                                        defaultChecked={systemSwitch.voiceRoute === 'vonage' ? true : false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckDefault"
                                      />
                                  </div>
                                </td>
                                <td>D7Networks {systemSwitch?.voiceRoute === 'd7networks' ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
                                  <br />
                                  <div className="form-check form-switch">
                                    <input
                                        name="voiceRoute"
                                        value="d7networks"
                                        defaultChecked={systemSwitch.voiceRoute === 'd7networks' ? true : false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckDefault"
                                      />
                                  </div>
                                </td>
                              </tr>

                            </td>
                          </tr>

                          <tr>
                            <td>Verification Route</td>
                            <td>
                              <tr>
                                <td>Verifyme.ng {systemSwitch?.verificationRoute === 'verifyme' ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
                                  <br />
                                  <div className="form-check form-switch">
                                    <input
                                        name="verificationRoute"
                                        value="verifyme"
                                        defaultChecked={systemSwitch.verificationRoute === 'verifyme' ? true : false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckDefault"
                                      />
                                  </div>
                                </td>
                                <td>IdentityPass {systemSwitch?.verificationRoute === 'IdentityPass' ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
                                  <br />
                                  <div className="form-check form-switch">
                                    <input
                                        name="verificationRoute"
                                        value="IdentityPass"
                                        defaultChecked={systemSwitch.verificationRoute === 'IdentityPass' ? true : false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckDefault"
                                      />
                                  </div>
                                </td>
                              </tr>

                            </td>
                          </tr>
                       

                          <tr>
                            <td>Payment Route</td>
                            <td>
                              <tr>
                                <td>Paystack {systemSwitch?.paymentRoute === 'paystack' ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
                                  <br />
                                  <div className="form-check form-switch">
                                    <input
                                        name="paymentRoute"
                                        value="paystack"
                                        defaultChecked={systemSwitch.paymentRoute === 'paystack' ? true : false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckDefault"
                                      />
                                  </div>
                                </td>
                                <td>Flutterwave {systemSwitch?.paymentRoute === 'flutterwave' ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
                                  <br />
                                  <div className="form-check form-switch">
                                    <input
                                        name="paymentRoute"
                                        value="flutterwave"
                                        defaultChecked={systemSwitch.paymentRoute === 'flutterwave' ? true : false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckDefault"
                                      />
                                  </div>
                                </td>
                              </tr>

                            </td>
                          </tr>
                       
                       
                        </tbody>
                      </table>
                      
{/*           
                      <button
                        variant="primary"
                        type="submit"
                        className="btn btn-block btn-primary"
                        disabled={isLoading}
                      >
                        {isLoading ? "Switching..." : "Switch Settings"}
                      </button> */}
                    </Form>
                  )}
                </Formik>
                
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default SwitchContain;
