import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import ProductsContain from '../../Component/Cart/products';
import api from '../../api/jwt'
import LoadingSpinner from "./../../CommonElements/Loader/LoadingSpinner";
import { Button, Card } from "reactstrap";

export default function Products() {
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('');
  const [products, setProducts] = useState([]);
  const [business, setBusiness] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    document.title = 'Products - Spendo';
    setName(sessionStorage.getItem('Name')); 
    const businesss = JSON.parse(sessionStorage.getItem('business'));
    setBusiness(businesss);
    const authenticated = JSON.parse(sessionStorage.getItem('authenticated'));
    const auth0_profile = JSON.parse(sessionStorage.getItem('auth0_profile'));
    const business_list = JSON.parse(sessionStorage.getItem('business_list'));
    const token = sessionStorage.getItem('token');

    setIsLoading(true);
    api.get('/products/' + businesss.uuid)
      .then(res => {
          setIsLoading(false)
          setProducts(res.data);
      })
      .catch(err => {
        setIsLoading(false)
      });

  }, []);
  return (
    <Fragment>
          <Breadcrumbs parent="Pages" title="Products" />
         
      {isLoading ? <LoadingSpinner /> : <ProductsContain business={business} products={products} />}
      
    </Fragment>
  )
}
