import { H5, P } from "../../AbstractElements";
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import api from "../../api/jwt";
import { Formik } from "formik";
import { toast } from "react-toastify";

const NGNcurrencyFormat = require("../../utils/NGNcurrencyFormat");

const WhatsAppSubPageContain = (props) => {
  const { business, subscriptions_plans, currentSubscriptions } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [productDescription, setProductDescription] = useState("");

  const subscribeToPlan = (planId) => {
    // confirmation alert
    var confirmation = window.confirm(
      "Are you sure you want to subscribe to this plan?"
    );
    if (confirmation) {
      var self = this;
      setIsLoading(true);
      const payload = {
        businessId: business.uuid,
        planId: planId,
      };

      api
        .post("/subscribe", payload)
        .then((res) => {
          setIsLoading(false);
          setTimeout(() => {
            if (res.data === "Insufficient balance") {
              toast.error(res.data);
            } else {
              toast.success(res.data);
            }
            window.location.reload();
          }, 200);
        })
        .catch((err) => {
          if (err) {
            setIsLoading(false);
            setTimeout(() => {
              toast.error("Oppss.." + err.response.data);
            }, 200);
          }
          // console.log(err);
        });
    }
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Current Subscriptions </H5>
                <span>
                  <P>Get list of all your current subscriptions.</P>
                </span>
              </CardHeader>
              <CardBody>
                <Row>
                  {currentSubscriptions.length !== 0 ? (
                    currentSubscriptions.map((currentSubscription) => (
                      <Col sm="4">
                        <Card
                          style={{
                            backgroundColor: "rgba(0,0,0,.03)",
                            border: "1px solid #0694A2",
                          }}
                        >
                          <CardBody>
                            <h3>{currentSubscription.planDetails.planName}</h3>

                            <p>
                              <b>Plan Type: </b> {currentSubscription.planType}
                            </p>
                            <p>
                              <b>Message Count: </b>{" "}
                              {currentSubscription.planDetails.messageCount}
                            </p>
                            <p>
                              <b>Balance: </b> {currentSubscription.balance}
                            </p>
                          </CardBody>
                        </Card>
                      </Col>
                    ))
                  ) : (
                    <Col sm="12">
                      <h5>No subscriptions available</h5>
                    </Col>
                  )}
                  <Link
                    className="btn btn-block btn-default "
                    style={{
                      backgroundColor: "#0694A2",
                      color: "#fff",
                    }}
                    to="/whatsapp/subscription/new"
                  >
                    <i className="fa fa-plus"></i> Subscribe to a plan
                  </Link>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default WhatsAppSubPageContain;
