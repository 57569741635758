import React, { Fragment, useState, useEffect } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import SettingsContain from "../../Component/Cart/settings";
import api from "../../api/jwt";

const AddProduct = () => {
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const [account, setAccount] = React.useState([]);
  const [business, setBusiness] = useState([]);
  useEffect(() => {
    document.title = "Mall Settings - Spendo";
    setName(sessionStorage.getItem("Name"));
    const businesss = JSON.parse(sessionStorage.getItem("business"));
    setBusiness(businesss);
    const authenticated = JSON.parse(sessionStorage.getItem("authenticated"));
    const auth0_profile = JSON.parse(sessionStorage.getItem("auth0_profile"));
    const business_list = JSON.parse(sessionStorage.getItem("business_list"));
    const token = sessionStorage.getItem("token");
    api
      .get("/business/account/" + businesss.uuid)
      .then((res) => {
        setAccount(res.data.data);
      })
      .catch((err) => {});
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Cart" title="Mall Settings" />
      <SettingsContain business={business} account={account} />
    </Fragment>
  );
};
export default AddProduct;
