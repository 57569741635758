import React, { Fragment } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import Contain from "../../../Component/Admin/Cart";

const CartOverview = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Cart" title="Cart Overview" />
      <Contain />
    </Fragment>
  );
};
export default CartOverview;
