import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import ProductsContain from '../../../Component/Admin/Cart/products';
import api from '../../../api/jwt'
import LoadingSpinner from "../../../CommonElements/Loader/LoadingSpinner";
import { Button, Card } from "reactstrap";

export default function Products() {
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('');
  const [products, setProducts] = useState([]);
  const [business, setBusiness] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setName(sessionStorage.getItem('Name')); 

    setIsLoading(true);
    api.get('/admin/mall/getAllProducts')
      .then(res => {
          setIsLoading(false)
          setProducts(res.data.data);
      })
      .catch(err => {
        setIsLoading(false)
      });

  }, []);
  return (
    <Fragment>
          <Breadcrumbs parent="Pages" title="Products" />
         
      {isLoading ? <LoadingSpinner /> : <ProductsContain business={business} products={products} />}
      
    </Fragment>
  )
}
