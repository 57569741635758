import React, { Fragment, useState, useEffect } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import SMSContain from "../../Component/SMS/bulk";
import api from "../../api/jwt";

const SMSOverview = () => {
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const [senderIds, setSenderId] = React.useState([]);
  const [business, setBusiness] = useState([]);
  useEffect(() => {
    document.title = 'Bulk SMS - Spendo';
    setName(sessionStorage.getItem("Name"));
    const businesss = JSON.parse(sessionStorage.getItem("business"));
    setBusiness(businesss);
    const authenticated = JSON.parse(sessionStorage.getItem("authenticated"));
    const auth0_profile = JSON.parse(sessionStorage.getItem("auth0_profile"));
    const business_list = JSON.parse(sessionStorage.getItem("business_list"));
    const token = sessionStorage.getItem("token");

    api
      .get("/sms/sender/list/" + businesss.uuid)
      .then((res) => {
        setSenderId(res.data.data);
      })
      .catch((err) => {});
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Services" title="SMS Bulk" />
      <SMSContain business={business} senderIds={senderIds} />
    </Fragment>
  );
};
export default SMSOverview;
