import React, { Fragment } from 'react';
import ApexChart from 'react-apexcharts';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { H3, H4, H6, Image } from '../../AbstractElements';
import avtar from '../../assets/images/avatar.jpg';
import { TotalEarning, WeeklyEvent } from '../../Pages/DashBoard/ChartsData/TotalEvents';
const NGNcurrencyFormat = require('../../utils/NGNcurrencyFormat');
const WalletBalance = (props) => {
  const { wallet, business } = props;
  return (
    <Fragment>
      <Col xl="3" md="6" className="dash-xl-50 box-col-6">
        <Card className='pb-0 o-hidden earning-card'>
          <CardHeader className="earning-back"></CardHeader>
          <CardBody className="p-0">
            <div className="earning-content" style={{ position: 'relative' }}>
              < Image attrImage={{ className: 'img-fluid', src: `${avtar}`, alt: '' }} />
              <Link to={`${process.env.PUBLIC_URL}/wallet`} >
                <H4>Wallet Balance</H4>
              </Link>
              <span>({business?.businessName || '***'})</span>
              <H6>{NGNcurrencyFormat.default(wallet?.balance || 0.0)}</H6>
              <ApexChart id="earning-chart" options={TotalEarning.options} series={TotalEarning.series} type='area' height={145} />
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl="3" md="6" className="dash-xl-50 box-col-6">
        <Card className='weekly-column'>
          <CardBody className="card-body p-10">
            <H3>QUICK GUIDES</H3>

            {/* list with link */}
            <ul className="list-unstyled">
              <li><a href='https://getspendo.medium.com/how-to-improve-customer-engagement-with-whatsapp-templates-bd6c896f9317' target={"_blank"}>
                How to Improve Customer Engagement with WhatsApp Templates
              </a><br/>
                {/* <span className="sub-text">Quick guide on funding your wallet</span> */}
              </li>
              <hr/>

              <li><a href='https://getspendo.medium.com/sender-id-what-it-is-why-it-matters-and-how-to-create-one-for-your-sms-messaging-campaigns-97b9157b7592' target={"_blank"}>
              How to create a Sender ID
              </a><br/>
                {/* <span className="sub-text">Quick guide on creating a sender ID</span> */}
              </li>
              <hr/>
              <li><a href='https://getspendo.medium.com/enhancing-sms-messaging-with-natural-language-processing-spendos-new-ai-feature-ddbe2e18901d' target={"_blank"}>
              Enhancing SMS Messaging with Natural Language Processing: Spendo’s New AI Feature
              </a><br/>
                {/* <span className="sub-text">Guide on how to verify your account</span> */}
              </li>
            </ul>


            <br/>
            <br/>
            {/* explore more on our blog */}
            <div className="explore right">
              <a href='https://getspendo.medium.com'>
                <span>Explore more on our blog </span> {" "}
                <i className="fa fa-angle-right"></i>
              </a>
            </div>


          </CardBody>
        </Card>
      </Col>
    </Fragment >
  );
};
export default WalletBalance;