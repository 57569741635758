import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import AdminDashboardContain from '../../../Component/Admin/Dashboard/index'
import LoadingSpinner from "../../../CommonElements/Loader/LoadingSpinner";

import api from "../../../api/jwt";

const AdminDashboard = () => {
  const [dashboardStats, setDashboardStats] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    api
      .get("/admin/dashboard/statistics")
      .then((res) => {
        setIsLoading(false);
        setDashboardStats(res.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
        
      });
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Dashboard" title="Admin Dashboard" />
      {isLoading ? <LoadingSpinner /> : <AdminDashboardContain dashboardStats={dashboardStats} />}
    </Fragment>
  );
};
export default AdminDashboard;