import React, { Fragment, useState, useEffect, useCallback } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import TemplateContain from "../../Component/Email/templatesContain";
import api from "../../api/jwt";
import { Button, Card } from "reactstrap";
import { Link } from 'react-router-dom';


export default function EmailTemplates() {
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const [senderId, setSendId] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [myTemplates, setMyTemplates] = useState([]);
  const [business, setBusiness] = useState([]);
  useEffect(() => {
    document.title = 'Email Templates - Spendo';
    setName(sessionStorage.getItem("Name"));
    const businesss = JSON.parse(sessionStorage.getItem("business"));
    setBusiness(businesss);
    const token = sessionStorage.getItem("token");

    const tempList = [
      {
        "id": 13333333333,
        "name": "Template 1",
        "image": "https://via.placeholder.com/150",
        "description": "Template 1 description",
        "created_at": "2020-12-01 12:00:00",
        "updated_at": "2020-12-01 12:00:00"
      },
      {
        "id": "gallery_13333333333",
        "name": "Template 2",
        "image": "https://via.placeholder.com/150",
        "description": "Template 2 description",
        "created_at": "2020-12-01 12:00:00",
        "updated_at": "2020-12-01 12:00:00"
      },
      {
        "id": "gallery_13333333333",
        "name": "Template 3",
        "image": "https://via.placeholder.com/150",
        "description": "Template 3 description",
        "created_at": "2020-12-01 12:00:00",
        "updated_at": "2020-12-01 12:00:00"
      },
      {
        "id": "gallery_13333333333",
        "name": "Template 4",
        "image": "https://via.placeholder.com/150",
        "description": "Template 4 description",
        "created_at": "2020-12-01 12:00:00",
        "updated_at": "2020-12-01 12:00:00"
      },
      {
        "id": "gallery_13333333333",
        "name": "Template 5",
        "image": "https://via.placeholder.com/150",
        "description": "Template 5 description",
        "created_at": "2020-12-01 12:00:00",
        "updated_at": "2020-12-01 12:00:00"
      },
      {
        "id": "gallery_13333333333",
        "name": "Template 6",
        "image": "https://via.placeholder.com/150",
        "description": "Template 6 description",
        "created_at": "2020-12-01 12:00:00",
        "updated_at": "2020-12-01 12:00:00"
      },
      {
        "id": "gallery_13333333333",
        "name": "Template 7",
        "image": "https://via.placeholder.com/150",
        "description": "Template 7 description",
        "created_at": "2020-12-01 12:00:00",
        "updated_at": "2020-12-01 12:00:00"
      }
    ]

    setTemplates(tempList);
  
    api
      .get("/email/templates/" + businesss.uuid)
      .then((res) => {
        setMyTemplates(res.data.data);
      })
      .catch((err) => {
        // window.alert(err.response.data.message);
      });
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="Email Templates" />

      <TemplateContain business={business} templates={templates} myTemplates={myTemplates} />
    </Fragment>
  );
}
