import Dashboard from "../Pages/DashBoard/index";
import Transactions from "../Pages/Transactions/index";
import Wallet from "../Pages/Wallet/index";
import WhatsappOverview from "../Pages/Whatsapp/index";
import VoiceOverview from "../Pages/Voice/index";
import VerificationOverview from "../Pages/Verification/index";
import SMSOverview from "../Pages/SMS/index";
import SMSSend from "../Pages/SMS/send";
import SMSBulk from "../Pages/SMS/bulk";
import WhatsAppSend from "../Pages/Whatsapp/send";
import WhatsAppTeamplates from "../Pages/Whatsapp/templates";
import WhatsappSubscription from "../Pages/Whatsapp/subscription";
import WhatsappSubscribe from "../Pages/Whatsapp/subscribe";
import SMSReport from "../Pages/SMS/report";
import SenderID from "../Pages/SMS/senderID";
import Profile from "../Pages/Users/UsersAccount/index";
import PricingPage from "../Pages/Pricing";
import AdminProfile from "../Pages/Admin/AdminUsers/UsersAccount/index";
import Developers from "../Pages/Developers/index";

import ErrorPage from "../Component/Pages/ErrorPages/error-page1";

// admin
import AdminUsers from "../Pages/Admin/Users/index";
import AdminSmppUsers from "../Pages/Admin/SmppUsers/index";
import AdminDashboard from "../Pages/Admin/Dashboard/index";
import AdminUsersProfile from "../Pages/Admin/Users/profile";
import AdminUsersMail from "../Pages/Admin/Users/mail";
import AdminBusiness from "../Pages/Admin/Business/index";
import AdminBusinessProfile from "../Pages/Admin/Business/profile";
import AdminTransactions from "../Pages/Admin/Transactions/index";
import AdminSenderID from "../Pages/Admin/SMS/senderID";
import AdminPricing from "../Pages/Admin/Business/pricing";

// cart
import CartOverview from "../Pages/Cart";
import AddProduct from "../Pages/Cart/addProduct";
import UpdateProduct from "../Pages/Cart/updateProduct";
import Products from "../Pages/Cart/products";
import Orders from "../Pages/Cart/orders";
import ProductImage from "../Pages/Cart/productImage";
import OrderView from "../Pages/Cart/orderView";
import Subsciptions from "../Pages/Cart/subscriptions";
import CartSettings from "../Pages/Cart/settings";
import AdminSwitch from "../Pages/Admin/Switch";

// Email
import EmailOverview from "../Pages/Email";
import EmailTemplates from "../Pages/Email/templates";
import EmailTemplatesEditor from "../Pages/Email/template-editor";
import EmailCampaigns from "../Pages/Email/campaign";

// Contacts
import ContactOverview from "../Pages/Contacts";
import ContactList from "../Pages/Contacts/list";
import AllContacts from "../Pages/Contacts/list";
import ContactView from "../Pages/Contacts/view";

// cart admin
import AdminCartOverview from "../Pages/Admin/Cart";
import AdminProducts from "../Pages/Admin/Cart/products";
import AdminOrders from "../Pages/Admin/Cart/orders";
import AdminOrderView from "../Pages/Admin/Cart/orderView";
import AdminSubsciptions from "../Pages/Admin/Cart/subscriptions";

export const routes = [
  { path: `${process.env.PUBLIC_URL}/dashboard`, Component: <Dashboard /> },
  {
    path: `${process.env.PUBLIC_URL}/transactions`,
    Component: <Transactions />,
  },
  { path: `${process.env.PUBLIC_URL}/wallet`, Component: <Wallet /> },
  {
    path: `${process.env.PUBLIC_URL}/sms/overview`,
    Component: <SMSOverview />,
  },
  {
    path: `${process.env.PUBLIC_URL}/developers`,
    Component: <Developers />,
  },
  {
    path: `${process.env.PUBLIC_URL}/whatsapp/overview`,
    Component: <WhatsappOverview />,
  },
  {
    path: `${process.env.PUBLIC_URL}/whatsapp/subscription`,
    Component: <WhatsappSubscription />,
  },
  {
    path: `${process.env.PUBLIC_URL}/whatsapp/subscription/new`,
    Component: <WhatsappSubscribe />,
  },
  {
    path: `${process.env.PUBLIC_URL}/whatsapp/message/send`,
    Component: <WhatsAppSend />,
  },
  {
    path: `${process.env.PUBLIC_URL}/whatsapp/templates`,
    Component: <WhatsAppTeamplates />,
  },
  {
    path: `${process.env.PUBLIC_URL}/voice/overview`,
    Component: <VoiceOverview />,
  },
  {
    path: `${process.env.PUBLIC_URL}/cart/overview`,
    Component: <CartOverview />,
  },
  {
    path: `${process.env.PUBLIC_URL}/cart/addProduct`,
    Component: <AddProduct />,
  },
  {
    path: `${process.env.PUBLIC_URL}/cart/updateProduct/:productId`,
    Component: <UpdateProduct />,
  },
  {
    path: `${process.env.PUBLIC_URL}/cart/subscriptions`,
    Component: <Subsciptions />,
  },
  {
    path: `${process.env.PUBLIC_URL}/cart/productImage/:product_id`,
    Component: <ProductImage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/cart/orderView/:order_id/:product_id`,
    Component: <OrderView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/cart/products`,
    Component: <Products />,
  },
  {
    path: `${process.env.PUBLIC_URL}/cart/orders`,
    Component: <Orders />,
  },
  {
    path: `${process.env.PUBLIC_URL}/cart/settings`,
    Component: <CartSettings />,
  },
  {
    path: `${process.env.PUBLIC_URL}/verification/overview`,
    Component: <VerificationOverview />,
  },
  { path: `${process.env.PUBLIC_URL}/sms/send`, Component: <SMSSend /> },
  { path: `${process.env.PUBLIC_URL}/sms/bulk`, Component: <SMSBulk /> },
  { path: `${process.env.PUBLIC_URL}/sms/report`, Component: <SMSReport /> },
  { path: `${process.env.PUBLIC_URL}/sms/senderId`, Component: <SenderID /> },
  // email
  {
    path: `${process.env.PUBLIC_URL}/email/overview`,
    Component: <EmailOverview />,
  },
  {
    path: `${process.env.PUBLIC_URL}/email/templates`,
    Component: <EmailTemplates />,
  },
  {
    path: `${process.env.PUBLIC_URL}/email/campaigns`,
    Component: <EmailCampaigns />,
  },
  {
    path: `${process.env.PUBLIC_URL}/email/template-editor/:id`,
    Component: <EmailTemplatesEditor />,
  },
  // contact
  {
    path: `${process.env.PUBLIC_URL}/contacts/overview`,
    Component: <ContactOverview />,
  },
  {
    path: `${process.env.PUBLIC_URL}/contacts/list`,
    Component: <ContactList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/contacts/all`,
    Component: <AllContacts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/contacts/view/:id`,
    Component: <ContactView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/user/account`,
    Component: <Profile />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pricing`,
    Component: <PricingPage />,
  },

  { path: `${process.env.PUBLIC_URL}/error`, Component: <ErrorPage /> },
  { path: ``, Component: <ErrorPage /> },
  { path: `*`, Component: <ErrorPage /> },
  { Component: <ErrorPage /> },

  // admin
  {
    path: `${process.env.PUBLIC_URL}/admin/dashboard`,
    Component: <AdminDashboard />,
  },
  { path: `${process.env.PUBLIC_URL}/admin/users`, Component: <AdminUsers /> },
  {
    path: `${process.env.PUBLIC_URL}/admin/smppUsers`,
    Component: <AdminSmppUsers />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/smppUsers/:businessId`,
    Component: <AdminSmppUsers />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/user/account`,
    Component: <AdminProfile />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/businesses`,
    Component: <AdminBusiness />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/sms/senderId`,
    Component: <AdminSenderID />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/businesses/:uuid`,
    Component: <AdminBusiness />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/pricing/:businessId`,
    Component: <AdminPricing />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/users/:id`,
    Component: <AdminUsersProfile />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/users/:uuid/sendmail`,
    Component: <AdminUsersMail />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/businessesProfile/:id`,
    Component: <AdminBusinessProfile />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/transactions`,
    Component: <AdminTransactions />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/transactions/:walletId`,
    Component: <AdminTransactions />,
  },

  // admin cart
  {
    path: `${process.env.PUBLIC_URL}/admin/cart/overview`,
    Component: <AdminCartOverview />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/cart/subscriptions`,
    Component: <AdminSubsciptions />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/switch`,
    Component: <AdminSwitch />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/cart/orderView/:order_id/:product_id`,
    Component: <AdminOrderView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/cart/products`,
    Component: <AdminProducts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/cart/orders`,
    Component: <AdminOrders />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/cart/settings`,
  //   Component: <CartSettings />,
  // },
];
