import React, { Fragment, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Collapse } from 'reactstrap';
import { Btn, H5, LI, P, UL } from '../../../AbstractElements';

const QuickLinks = (props) => {
  const { user } = props;
  const [aboutme, setisAboutme] = useState(true);
  const toggleClick = () => {
    setisAboutme(!aboutme);
  };
  return (
    <Fragment>
      <Col xl="12">
        <Card>
          <CardHeader>
            <H5 attrH5={{ className: 'p-0' }}>
              <Btn attrBtn={{
                className: 'btn btn-link', databstoggle: 'collapse', databstarget: '#collapseicon4'
                , ariaexpanded: 'true', ariacontrols: 'collapseicon4', color: 'transperant', onClick: toggleClick
              }} >Quick Links</Btn>
            </H5>
          </CardHeader>
          <Collapse isOpen={aboutme} id="collapseicon4">
            <CardBody>
              <UL>
                <LI>
                <Btn attrBtn={{ className: 'btn btn-primary', color: 'transperant', href: '/admin/businesses/' + user.uuid }} >
                      <span>Businesses</span>
                    </Btn>
                </LI> 
                {/* send mail */}
                <LI>
                  <Btn attrBtn={{ className: 'btn btn-primary', color: 'transperant', href: '/admin/users/' + user.uuid + '/sendmail' }} >
                    <span>Send Mail</span>
                  </Btn>
                </LI>

                {/* <LI >
                    <Btn attrBtn={{ className: 'btn btn-primary', color: 'transperant', href: '/admin/transactions/' + business.walletId }} >
                      <span>Transactions</span>
                    </Btn>
                </LI>

                <LI >
                    <Btn attrBtn={{ className: 'btn btn-primary', color: 'transperant', href: '/admin/whatsapp/numbers/' + business.uuid }} >
                      <span>Whatsapp Numbers</span>
                    </Btn>
                </LI>

                <LI >
                    <Btn attrBtn={{ className: 'btn btn-primary', color: 'transperant', href: '/admin/sms/senderId/' + business.uuid }} >
                      <span>SMS Sender ID's</span>
                    </Btn>
                </LI> */}

                {/* <LI >
                    <Btn attrBtn={{ className: 'btn btn-primary', color: 'transperant', href: '#' }} >
                      <span>API Keys</span>
                    </Btn>
                </LI> */}
                
              </UL>
            </CardBody>
          </Collapse>
        </Card>
      </Col>
    </Fragment >
  );
};
export default QuickLinks;