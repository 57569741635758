import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import WalletContain from '../../Component/Wallet';
import api from '../../api/jwt'

export default function Wallet() {
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('');
  const [wallet, setWallet] = useState([]);
  const [user, setUser] = useState([]);
  const [business, setBusiness] = useState([]);
  useEffect(() => {
    document.title = 'Fund Wallet - Spendo';
    setName(sessionStorage.getItem('Name')); 
    const businesss = JSON.parse(sessionStorage.getItem('business'));
    setBusiness(businesss);
    const authenticated = JSON.parse(sessionStorage.getItem('authenticated'));
    const auth0_profile = JSON.parse(sessionStorage.getItem('auth0_profile'));
    const business_list = JSON.parse(sessionStorage.getItem('business_list'));
    const token = sessionStorage.getItem('token');

    api.get('/business/wallet/' + businesss.walletId)
      .then(res => {
        setWallet(res.data.data);
      })
      .catch(err => {

      });

      // get user
      api.get('/user')
      .then(res => {
        setUser(res.data);
      })
      .catch(err => {

      });


  }, []);
  return (
<Fragment>
      <Breadcrumbs parent="Pages" title="Wallet" />
      <WalletContain business={business} wallet={wallet} user={user} />
    </Fragment>
  )
}
