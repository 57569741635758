import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from "reactstrap";
import { Btn, H5, UL, H4, H6, P } from "../AbstractElements";
import {
  EmailAddress,
    LoginWithJWT,
    SignIn,
  Password,
  ResetPassword,
} from "../Constant";
import { toast } from "react-toastify";
import { Link, useNavigate } from 'react-router-dom';

import { firebase_app, Jwt_token } from "../Config/Config";
import man from "../assets/images/dashboard/1.png";
import { handleResponse } from "../Services/Fack.Backend";
import api from "../api/jwt";
import { useForm } from "react-hook-form";

const ResetPasswordForm = ({ selected }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (data !== "") {
      reset(data);
    } else {
      errors.showMessages();
    }
  };

  const reset = (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      emailOrPhone: email,
    };

    api
      .post("/auth/reset", payload)
      .then((res) => {
        setLoading(false);
        setTimeout(() => {
          toast.success(
            "Your password has been successfully reset, check your email address."
          );
        }, 200);
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
          if (err) {
            setTimeout(() => {
              toast.error("Oppss.." + err.response.data.message);
            }, 200);
          }
          console.log(err.response.data.message);
        }
      });
  };
  return (
    <Fragment>
      <Form
        className="theme-form login-form needs-validation"
        noValidate=""
        onSubmit={handleSubmit(onSubmit)}
      >
  <H4>{"Reset your password"}</H4>
        <H6>
          {"Enter your email address and we'll send you a link to reset your password."}
        </H6>
        <br/>
        <br/>
        <FormGroup>
          <Label htmlFor="validationCustom01">{EmailAddress}</Label>
          <InputGroup>
            <InputGroupText>
              <i className="icon-email"></i>
            </InputGroupText>
            <Input
              className="form-control"
              type="email"
              name="EmailAddress"
              placeholder="Email Address"
              required
              {...register("EmailAddress", { required: true })}
              onChange={(e) => setEmail(e.target.value)}
              defaultValue={email}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Btn
            type="submit"
            attrBtn={{
              color: "primary",
              className: "btn-block",
              disabled: loading ? loading : loading,
              onClick: (e) => reset(e),
            }}
          >
            {loading ? "LOADING..." : ResetPassword}
          </Btn>
        </FormGroup>
        <br/>
        <br/>
        <P>Already have an account?
                    <Link to={`${process.env.PUBLIC_URL}/login`} className="ms-2">
                        {SignIn}
                    </Link>
                </P>
      </Form>
    </Fragment>
  );
};

export default ResetPasswordForm;