import React, { Fragment, useState, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import ApiKeysPage from './ApiKeys';
import api from '../../api/jwt'

const DevelopersContain = (props) => {
  const { business, apiKeys } = props;
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <ApiKeysPage business={business} apiKeys={apiKeys} />
          </Row>

        </div>
      </Container>
    </Fragment>
  );
};
export default DevelopersContain;