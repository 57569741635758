import React, { Fragment, useState, useEffect } from 'react';
import { Form, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { Btn, H5, UL, H4, H6, P } from '../../AbstractElements';
import { EmailAddress, CreateAccount, Password, SignIn, FirstName, LastName, Phone, PhoneNumber } from '../../Constant';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from "react-router-dom";
import { firebase_app, Jwt_token } from '../../Config/Config';
import man from '../../assets/images/dashboard/1.png';
import { handleResponse } from '../../Services/Fack.Backend';
import api from '../../api/jwt'
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import PinInput from "react-pin-input";

const VerifyForm = ({ selected }) => {
    const [loading, setLoading] = useState(false);
    const [timeRemain, setTimeRemain] = useState(60);
    const [togglePassword, setTogglePassword] = useState(false);
    const history = useNavigate();
  const location = useLocation();
  
  const getBusiness = () => {
    var self = this;
    setLoading(true);
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("tempToken"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "*",
      },
    };

    api
      .get("/business", axiosConfig)
      .then((res) => {
        setLoading(false);
        if (res.data.data !== undefined && res.data.data !== null) {
          sessionStorage.setItem("business", JSON.stringify(res.data.data[0]));
          sessionStorage.setItem(
            "business_list",
            JSON.stringify(res.data.data)
          );
            
            var redirect = location.search.split("=")[1];
            window.location.href = redirect == null ? "/dashboard" : redirect;
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err) {
          sessionStorage.setItem("tempToken", sessionStorage.getItem("tempToken"));
          window.location.href = `${process.env.PUBLIC_URL}/setup`;
        }
      });
  };

  const verifyOTP = async (code) => {
      setLoading(true);
        const payload = {
          code: code,
        };      
      // custom header
      const config = {
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('tempToken'),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "*",
        }
      };
      
        api.post('/auth/validate2fa',payload, config)
            .then(res => {
              setLoading(false);
              getBusiness();
                // setTimeout(() => {
                //     toast.success(
                //         'Verified Successful. Please login to access your account'
                //     );
                //     var redirect = location.search.split("=")[1];
                //     window.location.href = redirect == null ? "/dashboard" : redirect;
                // }, 200);
                return res.data;
            })
            .catch(err => {
                if (err) {
                    setLoading(false);
                    setTimeout(() => {
                        toast.error('Oppss..' + err.response.data.message);
                    }, 200);
                }
            });   
    }

  
    return (
        <Fragment>
            <Form
            className="theme-form login-form">
          <br/>
          <br/>
                <center>
                <H4>{"2factor Authentication"}</H4>
        <H6>
              {/* message to use Google authenticor */}
              {"Please enter the 6 digit code from your any of your Authenticator app"}
                    </H6>
                    <br />
                    <PinInput
                      length={6}
                      initialValue=""
                      secret
                      onChange={(value, index) => {}}
                      type="numeric"
                      inputMode="number"
                      style={{ padding: "10px" }}
                      inputStyle={{
                        width: 40,
                        height: 40,
                        borderColor: "#036672",
                        borderRadius: "100px",
                        border: "2px solid #036672",
                      }}
                      inputFocusStyle={{ borderColor: "#036672" }}
                      onComplete={(value, index) => {
                        verifyOTP(value);
                      }}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                    <br />
            <br />
            
            {/* Sign with another account redirect to login */}
            <Link to={`${process.env.PUBLIC_URL}/login`} className="ms-2">
              {"Sign in with another account"}
                    </Link>

                </center>
                
                </Form>
            <br/>
        </Fragment>
    );
};

export default VerifyForm;