import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import DefaultContain from '../../Component/DashBoard/index';
import api from '../../api/jwt'



const Default = () => {
  // change page title
  useEffect(() => {
    document.title = 'Dashboard - Spendo';
  }, []);

  const [profile, setProfile] = useState('');
  const [name, setName] = useState('');
  const [user, setUser] = useState('');
  const [wallet, setWallet] = useState([]);
  const [dashboardStart, setDashboardStats] = useState([]);
  const [business, setBusiness] = useState([]);

  useEffect(() => {
    setName(sessionStorage.getItem('Name')); 
    const business = JSON.parse(sessionStorage.getItem('business'));
    setBusiness(business);
    const authenticated = JSON.parse(sessionStorage.getItem('authenticated'));
    const auth0_profile = JSON.parse(sessionStorage.getItem('auth0_profile'));
    const business_list = JSON.parse(sessionStorage.getItem('business_list'));
    const token = sessionStorage.getItem('token');

    // get user wallet
    api.get('/business/wallet/' + business.walletId)
    .then(res => {
      setWallet(res.data.data);
        
        })
    .catch(err => {

    });

    // get business
    api.get('/business/dashboard/' + business.uuid)
      .then(ress => {
      setDashboardStats(ress.data.data);
        
        })
    .catch(err => {

    });

    // get user
    api.get('/user')
      .then(res => {
        setUser(res.data);
      })
      .catch(err => {

      });
   }, []);

  return (
    <Fragment>
      <Breadcrumbs parent="Dashboard" title="Dashboard" />
      <DefaultContain business={business} name={name} wallet={wallet} dashboardStart={dashboardStart} user={user} />
    </Fragment>
  );
};
export default Default;