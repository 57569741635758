import React, { Fragment } from 'react';
import { Card, CardBody, CardHeader, Col, Media, Container, Row } from 'reactstrap';
import { H5, H6 } from '../../../AbstractElements';
import TotalTrasactions from './TotalTrasactions';
const NGNcurrencyFormat = require('../../../utils/NGNcurrencyFormat');
const AdminDashboard = (props) => {
  const { dashboardStats } = props;
  return (
    <Fragment>
      <Container fluid={true} className="crypto-dash">
        <Row>
          <Col xl='3' md='6' className="dash-lg-50 box-col-6">
            <Card className="crypto-chart">
              <CardHeader className="card-no-border">
                <Media>
                  <Media body>
                    <H5 attrH5={{ className: 'font-primary' }}>{dashboardStats?.totalUsers || 0}</H5>
                  </Media>
                  <div className="media-end">
                    <H6>Users</H6>
                  </div>
                </Media>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="crypto-dashborad-chart">
                      <hr/>
                    </div>
                  </CardBody>
            </Card>
          </Col>
          <Col xl='3' md='6' className="dash-lg-50 box-col-6">
            <Card className="crypto-chart">
              <CardHeader className="card-no-border">
                <Media>
                  <Media body>
                    <H5 attrH5={{ className: 'font-primary' }}>{dashboardStats?.totalBusinesses || 0}</H5>
                  </Media>
                  <div className="media-end">
                    <H6>Businesses</H6>
                  </div>
                </Media>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="crypto-dashborad-chart">
                      <hr/>
                    </div>
                  </CardBody>
            </Card>
          </Col>
          <Col xl='3' md='6' className="dash-lg-50 box-col-6">
            <Card className="crypto-chart">
              <CardHeader className="card-no-border">
                <Media>
                  <Media body>
                    <H5 attrH5={{ className: 'font-primary' }}>{dashboardStats?.totalBusinesses || 0}</H5>
                  </Media>
                  <div className="media-end">
                    <H6>Businesses</H6>
                  </div>
                </Media>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="crypto-dashborad-chart">
                      <hr/>
                    </div>
                  </CardBody>
            </Card>
          </Col>
          <Col xl='3' md='6' className="dash-lg-50 box-col-6">
            <Card className="crypto-chart">
              <CardHeader className="card-no-border">
                <Media>
                  <Media body>
                    <H5 attrH5={{ className: 'font-primary' }}>{dashboardStats?.totalSmsSenderId || 0}</H5>
                  </Media>
                  <div className="media-end">
                    <H6>Sender IDs</H6>
                  </div>
                </Media>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="crypto-dashborad-chart">
                      <hr/>
                    </div>
                  </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xl='3' md='6' className="dash-lg-50 box-col-6">
            <Card className="crypto-chart">
              <CardHeader className="card-no-border">
                <Media>
                  <Media body>
                    <H5 attrH5={{ className: 'font-primary' }}>{dashboardStats?.totalTransactions || 0}</H5>
                  </Media>
                  <div className="media-end">
                    <H6>Transactions</H6>
                  </div>
                </Media>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="crypto-dashborad-chart">
                      <hr/>
                    </div>
                  </CardBody>
            </Card>
          </Col>
          <Col xl='3' md='6' className="dash-lg-50 box-col-6">
            <Card className="crypto-chart">
              <CardHeader className="card-no-border">
                <Media>
                  <Media body>
                    <H5 attrH5={{ className: 'font-primary' }}>{dashboardStats?.totalSmsHistory || 0}</H5>
                  </Media>
                  <div className="media-end">
                    <H6>SMS History</H6>
                  </div>
                </Media>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="crypto-dashborad-chart">
                      <hr/>
                    </div>
                  </CardBody>
            </Card>
          </Col>
          <Col xl='3' md='6' className="dash-lg-50 box-col-6">
            <Card className="crypto-chart">
              <CardHeader className="card-no-border">
                <Media>
                  <Media body>
                    <H5 attrH5={{ className: 'font-primary' }}>{dashboardStats?.totalWhatsappMessages || 0}</H5>
                  </Media>
                  <div className="media-end">
                    <H6>Whatsapp Messages</H6>
                  </div>
                </Media>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="crypto-dashborad-chart">
                      <hr/>
                    </div>
                  </CardBody>
            </Card>
          </Col>
          <Col xl='3' md='6' className="dash-lg-50 box-col-6">
            <Card className="crypto-chart">
              <CardHeader className="card-no-border">
                <Media>
                  <Media body>
                    <H5 attrH5={{ className: 'font-primary' }}>{dashboardStats?.totalWhatsappNumbers || 0}</H5>
                  </Media>
                  <div className="media-end">
                    <H6>Whastapp Numbers</H6>
                  </div>
                </Media>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="crypto-dashborad-chart">
                      <hr/>
                    </div>
                  </CardBody>
            </Card>
          </Col>
        </Row>


        <Row>
          <Col xl='3' md='6' className="dash-lg-50 box-col-6">
            <Card className="crypto-chart">
              <CardHeader className="card-no-border">
                <Media>
                  <Media body>
                    <H5 attrH5={{ className: 'font-primary' }}>{dashboardStats?.totalWhatsappHistory || 0}</H5>
                  </Media>
                  <div className="media-end">
                    <H6>Whatsapp History</H6>
                  </div>
                </Media>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="crypto-dashborad-chart">
                      <hr/>
                    </div>
                  </CardBody>
            </Card>
          </Col>
          <Col xl='3' md='6' className="dash-lg-50 box-col-6">
            <Card className="crypto-chart">
              <CardHeader className="card-no-border">
                <Media>
                  <Media body>
                    <H5 attrH5={{ className: 'font-primary' }}>{dashboardStats?.totalVoiceHistory || 0}</H5>
                  </Media>
                  <div className="media-end">
                    <H6>Voice History</H6>
                  </div>
                </Media>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="crypto-dashborad-chart">
                      <hr/>
                    </div>
                  </CardBody>
            </Card>
          </Col>
          <Col xl='3' md='6' className="dash-lg-50 box-col-6">
            <Card className="crypto-chart">
              <CardHeader className="card-no-border">
                <Media>
                  <Media body>
                    <H5 attrH5={{ className: 'font-primary' }}>{dashboardStats?.totalWhatsappMessages || 0}</H5>
                  </Media>
                  <div className="media-end">
                    <H6>Whatsapp Messages</H6>
                  </div>
                </Media>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="crypto-dashborad-chart">
                      <hr/>
                    </div>
                  </CardBody>
            </Card>
          </Col>
          <Col xl='3' md='6' className="dash-lg-50 box-col-6">
            <Card className="crypto-chart">
              <CardHeader className="card-no-border">
                <Media>
                  <Media body>
                    <H5 attrH5={{ className: 'font-primary' }}>{dashboardStats?.totalWhatsappNumbers || 0}</H5>
                  </Media>
                  <div className="media-end">
                    <H6>Whastapp Numbers</H6>
                  </div>
                </Media>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="crypto-dashborad-chart">
                      <hr/>
                    </div>
                  </CardBody>
            </Card>
          </Col>
        </Row>



        {/* <Row>
          <Col xl='3' md='6' className="dash-lg-50 box-col-6">
            <Card className="crypto-chart">
              <CardHeader className="card-no-border">
                <Media>
                  <Media body>
                    <H5 attrH5={{ className: 'font-primary' }}>{NGNcurrencyFormat.default(dashboardStats?.totalWalletBalance || 0.00)}</H5>
                  </Media>
                  <div className="media-end">
                    <H6>Wallet</H6>
                  </div>
                </Media>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="crypto-dashborad-chart">
                      <hr/>
                    </div>
                  </CardBody>
            </Card>
          </Col>
          <Col xl='3' md='6' className="dash-lg-50 box-col-6">
            <Card className="crypto-chart">
              <CardHeader className="card-no-border">
                <Media>
                  <Media body>
                    <H5 attrH5={{ className: 'font-primary' }}>{NGNcurrencyFormat.default(dashboardStats?.totalTransactionsAmount || 0.00)}</H5>
                  </Media>
                  <div className="media-end">
                    <H6>Transactions</H6>
                  </div>
                </Media>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="crypto-dashborad-chart">
                      <hr/>
                    </div>
                  </CardBody>
            </Card>
          </Col>
          <Col xl='3' md='6' className="dash-lg-50 box-col-6">
            <Card className="crypto-chart">
              <CardHeader className="card-no-border">
                <Media>
                  <Media body>
                    <H5 attrH5={{ className: 'font-primary' }}>{NGNcurrencyFormat.default(dashboardStats?.totalTransactionsDebit || 0.00)}</H5>
                  </Media>
                  <div className="media-end">
                    <H6>(T) Debit</H6>
                  </div>
                </Media>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="crypto-dashborad-chart">
                      <hr/>
                    </div>
                  </CardBody>
            </Card>
          </Col>
          <Col xl='3' md='6' className="dash-lg-50 box-col-6">
            <Card className="crypto-chart">
              <CardHeader className="card-no-border">
                <Media>
                  <Media body>
                    <H5 attrH5={{ className: 'font-primary' }}>{NGNcurrencyFormat.default(dashboardStats?.totalTransactionsCredit || 0.00)}</H5>
                  </Media>
                  <div className="media-end">
                    <H6>(T) Credit</H6>
                  </div>
                </Media>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="crypto-dashborad-chart">
                      <hr/>
                    </div>
                  </CardBody>
            </Card>
          </Col>
        </Row> */}

        <Row>
          <TotalTrasactions dashboardStats={dashboardStats} />
        </Row>
          
      </Container>
    </Fragment>
  );
};
export default AdminDashboard;