import { H5, P, Btn } from "../../../AbstractElements";
import React, { Fragment, useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import DataTable from "react-data-table-component";
const NGNcurrencyFormat = require('../../../utils/NGNcurrencyFormat');
const SamplePageContain = (props) => {
  const { business, transactions } = props;

  const columns = [
    {
      name: "BILL FOR",
      selector: (row) => row.billFor,
      sortable: true,
    },
    {
      name: "TYPE",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      button: true,
      cell: (row) => <>
        <Link to={`/admin/transactions/${row.walletId}`}> {row.walletId} </Link>
      </>,
      name: "WALLET ID",
      sortable: true,
      right: true
    },
    {
      name: "AMOUNT",
      selector: (row) => NGNcurrencyFormat.default(row.amount),
      sortable: true,
      right: true,
    },
    {
      name: "DATE",
      selector: (row) => row.createdAt,
      sortable: true,
      right: true,
    },
    // {
    //   name: "STATUS",
    //   selector: (row) => row.status,
    //   sortable: true,
    //   right: true,
    // },
    // {
    //   button: true,
    //   cell: () => <></>,
    // },
  ];
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                {/* <H5>Transactions</H5> */}

                <DataTable
                  // title="Transactions"
                  columns={columns}
                  data={transactions}
                  defaultSortFieldID={1}
                  pagination
                  striped={true}
                  center={true}
                  // paginationComponent={BootyPagination}
                  // selectableRows
                  // selectableRowsComponent={BootyCheckbox}
                />
              </CardHeader>
              <CardBody>
                <p>-</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default SamplePageContain;
