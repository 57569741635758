import { H5, P, Btn } from '../../AbstractElements';
import React, { Fragment, useState, useEffect } from 'react';

import { Card, CardBody, CardHeader, Container, Row, Col, Form, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, Input,  Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { usePaystackPayment } from 'react-paystack';
import api from '../../api/jwt'
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';

const SamplePageContain = (props) => {
  const { business, templates } = props;
  const { history } = props;
  const [modal, setModal] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [templateDetail, setTemplateDetail] = useState([]);
  const toggle = () => setModal(!modal);
  const toggle2 = () => setModal2(!modal2);


  const createTemplate = (values) => {
    var self = this;

    // templateName remove space and add underscore
    var templateName = values?.templateName.replace(/\s+/g, "_");
    const payload = {
      templateBody: values?.templateBody,
      templateCategory: values?.templateCategory,
      templateLanguage: values?.templateLanguage,
      templateName: templateName,
      templateType: values?.templateType,
    };
    api
      .post("/whatsapp/message/template/" + business.uuid, payload)
      .then((res) => {
        setTimeout(() => {
          toast.success(res.data.message);
          window.location.reload();
        }, 200);
        
      })
      .catch((err) => {
        if (err) {
          setTimeout(() => {
            toast.error("Oppss.." + err.response.data.message);
          }, 200);
        }
      });
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.templateName,
      sortable: true
    },
    {
      name: "Category",
      selector: (row) => row.templateCategory,
      // sortable: true
    },
    {
      name: "Language",
      selector: (row) => row.templateLanguage,
      sortable: true,
      right: true
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      right: true
    },
    {
      name: "createdAt",
      selector: (row) => new Date(row.createdAt).toLocaleString(),
      sortable: true,
      right: true
    },
    {
      button: true,
      cell: (row) => (
        <>
          <Button
            className="btn btn-primary btn-sm"
            onClick={() => {

              // loop throught row.templateBody and count params that start and ends with {{}}
              var count = 0;
              var str = row.templateBody;
              var n = str.indexOf("{{");
              while (n !== -1) {
                count++;
                n = str.indexOf("{{", n + 1);
              }
              row.totalParams = count;

              // get all params
              var params = [];
              var str = row.templateBody;
              var n = str.indexOf("{{");
              while (n !== -1) {
                var param = str.substring(n + 2, str.indexOf("}}", n));
                params.push(param);
                n = str.indexOf("{{", n + 1);
              }
              row.templateParams = params;

              setTemplateDetail(row);
              setModal2(true);
            }}
          >
              <i className="fa fa-eye"></i>
          </Button>

        </>
      )
    }
  ];


    return (
      <Fragment>
        <Container fluid={true}>
          <Row>
            <Col sm="12">

              <div className="d-flex justify-content-end my-2 mx-2">
                <Button className="primary" type="button" onClick={() => {
                   setModal(true);
                }}>
                 Create a new Template
                </Button>
              </div>


              <Card>
                <div className="table-responsive support-table">
                  <DataTable
          // title="Transactions"
          columns={columns}
          data={templates}
          defaultSortFieldID={1}
                      pagination
          striped={true}
          center={true}
          // paginationComponent={BootyPagination}
          // selectableRows
          // selectableRowsComponent={BootyCheckbox}
                    />
                    </div>  
    
                <CardBody>
                </CardBody>
              </Card>
            </Col>
          </Row>


          <Modal
            centered={true}
            fade={true}
            backdropTransition={{ timeout: 700 }}
            modalTransition={{ timeout: 700 }}

            isOpen={modal} toggle={toggle} className="">
          <ModalHeader toggle={toggle}>Create a new Whatsapp Template</ModalHeader>
              <ModalBody>
                
              <Formik
                  initialValues={{
                    templateName: "",
                    templateBody: "",
                    campaignTemplateId: "",
                    campaignScheduleDate: "",
                    isScheduled: ""
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.templateName) {
                      errors.templateName = "Required";
                    }
                    if (!values.templateBody) {
                      errors.templateBody = "Required";
                    }
                    if (!values.templateCategory) {
                      errors.templateCategory = "Required";
                    }
                    if (!values.templateLanguage) {
                      errors.templateLanguage = "Required";
                    }
                    if (!values.templateType) {
                      errors.templateType = "Required";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    // setTimeout(() => {
                    createTemplate(values);
                    setSubmitting(false);
                    // }, 400);
                  }}
                  
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit} className="mt-4">
                      <FormGroup>
                        <Label htmlFor="templateName">
                        <p>Name your message template.</p>
                        </Label>
                        <Input
                          type="input"
                          name="templateName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.templateName}
                          className="form-control"
                          defaultValue="1"
                        />

                        {errors.templateName && touched.templateName && errors.templateName}
                    </FormGroup>
                    
                    <FormGroup>
                        <Label htmlFor="templateCategory">
                          Category
                        </Label>
                        <Input
                          type="select"
                          name="templateCategory"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.templateCategory}
                          className="form-control"
                          // defaultValue="1"
                        >
                          <option>Select Category</option>
                          {/* {contactList.map((contactlist, index) => {
                            return (
                              <option key={index} value={contactlist.contactListId}>
                                {contactlist.contactListName}
                              </option>
                            );
                          })} */}
                        <option value="Marketing">Marketing</option>
                        <option value="Utility">Utility</option>
                        <option value="Promotion">Promotion</option>
                        <option value="Authentication">Authentication</option>
                        </Input>
                        {errors.templateCategory &&
                          touched.templateCategory &&
                          errors.templateCategory}
                    </FormGroup>
                    
                    <FormGroup>
                        <Label htmlFor="templateType">
                        Template Type
                        </Label>
                        <Input
                          type="select"
                          name="templateType"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.templateType}
                          className="form-control"
                          // defaultValue="1"
                        >
                          <option>Select Template Type</option>
                          {/* {templates.map((templateslist, index) => {
                            return (
                              <option key={index} value={templateslist.templateId}>
                                {templateslist.templateName}
                              </option>
                            );
                          })} */}
                        <option value="text">Text</option>
                        {/* <option value="html">Vido</option> */}
                        </Input>
                        {errors.templateType &&
                          touched.templateType &&
                          errors.templateType}
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="templateType">
                        Template Language
                        </Label>
                        <Input
                          type="select"
                          name="templateLanguage"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.templateLanguage}
                          className="form-control"
                          // defaultValue="1"
                        >
                          <option>Select Language</option>
                          {/* {templates.map((templateslist, index) => {
                            return (
                              <option key={index} value={templateslist.templateId}>
                                {templateslist.templateName}
                              </option>
                            );
                          })} */}
                        <option value="English">English</option>

                        </Input>
                        {errors.templateLanguage &&
                          touched.templateLanguage &&
                          errors.templateLanguage}
                    </FormGroup>
                        <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                        Template Body
                        <p>Enter the text for your message in the language that you've selected.</p>
                        </Label>
                        <Input
                          type="textarea"
                          row={3}
                          name="templateBody"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.templateBody}
                          className="form-control"
                          defaultValue="1"
                        />

                        {errors.templateBody && touched.templateBody && errors.templateBody}
                    </FormGroup>

                    {/* sample message */}
                    <p>
                      <b>Sample Message</b>
                      <br />
                      <span className="text-muted">
                      {"Hello {{1}}, your OTP code for *{{2}}* is *{{3}}*. Please do not share with anyone."}
                      </span>
                    </p>
                    
                      <button
                        variant="primary"
                        type="submit"
                        className="btn btn-block btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Loading..." : "Create"}
                      </button>
                    </Form>
                  )}
                </Formik>

            </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '} */}
            {/* <Button color="secondary" onClick={toggle}>Close</Button> */}
          </ModalFooter>
        </Modal>

          

        <Modal
            centered={true}
            fade={true}
            backdropTransition={{ timeout: 700 }}
            modalTransition={{ timeout: 700 }}

            isOpen={modal2} toggle={toggle2} className="">
          <ModalHeader toggle={toggle2}>Template Detail</ModalHeader>
            <ModalBody>
              
              {/* list templatedetails */}
              <div className="row">
                <div className="col-md-6">
                  <p><b>Template Name:</b></p>
                  <p>{templateDetail?.templateName}</p>
                </div>
                <div className="col-md-6">
                  <p><b>Template Category:</b></p>
                  <p>{templateDetail?.templateCategory}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p><b>Template Type:</b></p>
                  <p>{templateDetail?.templateType}</p>
                </div>
                <div className="col-md-6">
                  <p><b>Template Language:</b></p>
                  <p>{templateDetail?.templateLanguage}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <p><b>Template Body:</b></p>
                  <p>{templateDetail?.templateBody}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <p><b>Total Message Parameters:</b></p>
                  <p>({templateDetail?.totalParams})</p>
                </div>
              </div>

              
              {/* parameters table */}
              <table>
                <thead>
                  <tr>
                    <th>Parameter</th>
                  </tr>
                </thead>
                <tbody>
                  {templateDetail?.templateParams?.map((param, index) => {
                    return (
                      <tr key={index}>
                        <td>{`{{${param}}}`}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {/* NOte: */}
              <p><b>Note:</b></p>
              <p>{"1. The parameters in the template body should be in the format {{ 1}}, {{ 2}}, {{ 3}} and so on."}</p>
              <p>{"2. The parameters in the template body should be in the same order as the parameters in the table."}</p>

            
        </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '} */}
            <Button color="secondary" onClick={toggle2}>Close</Button>
          </ModalFooter>
        </Modal>

        </Container>
      </Fragment>
    );
};
export default SamplePageContain;