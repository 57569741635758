import { H5, P, Btn } from "../../../AbstractElements";
import React, { Fragment, useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import DataTable from "react-data-table-component";

const UsersContain = (props) => {
  const { users } = props;
  const columns = [
    {
      name: "systemId",
      selector: (row) => row.systemId,
      sortable: true,
    },
    {
      name: "systemType",
      selector: (row) => row.systemType,
      sortable: true,
    },
    {
      name: "businessId",
      selector: (row) => row.businessId,
      sortable: true,
      right: true,
    },
    {
      name: "addressRange",
      selector: (row) => row.addressRange,
      sortable: true,
      right: true,
    },
    // {
    //   name: "ACTION",
    //   button: true,
    //   sortable: true,
    //   right: true,
    //   cell: (row) => <>
    //     <Link to={`/admin/users/${row.uuid}`} className="btn btn-primary btn-sm"><i className="fa fa-eye"></i></Link>
    //   </>,
    // },
  ];
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                {/* <H5>Transactions</H5> */}

                <DataTable
                  // title="Transactions"
                  columns={columns}
                  data={users}
                  defaultSortFieldID={1}
                  pagination
                  striped={true}
                  center={true}
                  // paginationComponent={BootyPagination}
                  // selectableRows
                  // selectableRowsComponent={BootyCheckbox}
                />
              </CardHeader>
              <CardBody>
                <p>-</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default UsersContain;
