import { H5, P } from "../../../AbstractElements";
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
} from "reactstrap";
import api from "../../../api/jwt";
import { Formik } from "formik";
import { toast } from "react-toastify";
import {Editor, EditorState} from 'draft-js';
import 'draft-js/dist/Draft.css';

const UpdatePricingContain = (props) => {
  const { pricing, business } = props;
  const [isLoading, setIsLoading] = useState(false);

  const updatePricing = (
    smsMessage,
    smsMessageBulk,
    smsMessageInt,
    smsOtp,
    smsOtpInt,
    verificationBvn,
    verificationNin,
    verificationPhone,
    voiceOtp,
    whatsappMessage,
    whatsappMessageTemplate,
    whatsappOtp
  ) => {
    var self = this;
    setIsLoading(true);
    const payload = {
      "smsMessage": smsMessage,
      "smsMessageBulk": smsMessageBulk,
      "smsMessageInt": smsMessageInt,
      "smsOtp": smsOtp,
      "smsOtpInt": smsOtpInt,
      "verificationBvn": verificationBvn,
      "verificationNin": verificationNin,
      "verificationPhone": verificationPhone,
      "voiceOtp": voiceOtp,
      "whatsappMessage": whatsappMessage,
      "whatsappMessageTemplate": whatsappMessageTemplate,
      "whatsappOtp": whatsappOtp
    };

    api
      .put("/admin/pricing/" + business.uuid, payload)
      .then((res) => {
        setIsLoading(false);
        setTimeout(() => {
          toast.success("Pricing updated successfully");
          window.location.href = "/admin/businessesProfile/" + business.uuid;
        }, 200);

        
      })
      .catch((err) => {
        if (err) {
          setIsLoading(false);

          setTimeout(() => {
            toast.error("Oppss.." + err.response.data);
          }, 200);
        }
        // console.log(err.response.data);
      });
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Update Businesss Pricing </H5>
                <br />
                <p>
                <b>Business Name:</b> {business?.businessName}
                  <hr />
                  <b>Business Phone:</b> {business?.businessPhone}
                  <hr />
                  <b>Business Email:</b> {business?.businessEmail}
                  <hr />
                  <b>Business Address:</b> {business?.businessAddress}
                </p>
               
              </CardHeader>
              <CardBody>
                <Formik
                  initialValues={{
                    smsMessage: pricing?.smsMessage,
                    smsMessageBulk: pricing?.smsMessageBulk,
                    smsMessageInt: pricing?.smsMessageInt,
                    smsOtp: pricing?.smsOtp,
                    smsOtpInt: pricing?.smsOtpInt,
                    verificationBvn: pricing?.verificationBvn,
                    verificationNin: pricing?.verificationNin,
                    verificationPhone: pricing?.verificationPhone,
                    voiceOtp: pricing?.voiceOtp,
                    whatsappMessage: pricing?.whatsappMessage,
                    whatsappMessageTemplate: pricing?.whatsappMessageTemplate,
                    whatsappOtp: pricing?.whatsappOtp,
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.smsMessage) {
                      errors.smsMessage = "Required";
                    } else if (!values.smsMessageBulk) {
                      errors.smsMessageBulk = "Required";
                    } else if (!values.smsMessageInt) {
                      errors.smsMessageInt = "Required";
                    } else if (!values.smsOtp) {
                      errors.smsOtp = "Required";
                    } else if (!values.smsOtpInt) {
                      errors.smsOtpInt = "Required";
                    } else if (!values.verificationBvn) {
                      errors.verificationBvn = "Required";
                    } else if (!values.verificationNin) {
                      errors.verificationNin = "Required";
                    } else if (!values.verificationPhone) {
                      errors.verificationPhone = "Required";
                    } else if (!values.voiceOtp) {
                      errors.voiceOtp = "Required";
                    } else if (!values.whatsappMessage) {
                      errors.whatsappMessage = "Required";
                    } else if (!values.whatsappMessageTemplate) {
                      errors.whatsappMessageTemplate = "Required";
                    } else if (!values.whatsappOtp) {
                      errors.whatsappOtp = "Required";
                    }
                    
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    // setTimeout(() => {
                      updatePricing(
                      values.smsMessage,
                      values.smsMessageBulk,
                      values.smsMessageInt,
                      values.smsOtp,
                      values.smsOtpInt,
                      values.verificationBvn,
                      values.verificationNin,
                      values.verificationPhone,
                      values.voiceOtp,
                      values.whatsappMessage,
                      values.whatsappMessageTemplate,
                      values.whatsappOtp
                    );
                    setSubmitting(false);
                    // }, 400);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit} className="mt-4">
                      
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">SMS Message</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                        <Input
                          type="number"
                          name="smsMessage"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={pricing?.smsMessage}
                          className="form-control"
                          placeholder="Enter sms message price"
                          />
                        </div>
                      </FormGroup>
                        {errors.smsMessage &&
                          touched.smsMessage &&
                          errors.smsMessage}

                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">SMS Bulk Message</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                        <Input
                          type="number"
                          name="smsMessageBulk"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={pricing?.smsMessageBulk}
                          className="form-control"
                          placeholder="Enter sms bulk message price"
                          />
                        </div>
                      </FormGroup>
                      {errors.smsMessageBulk &&
                          touched.smsMessageBulk &&
                          errors.smsMessageBulk}

                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">SMS Message International</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                        <Input
                          type="number"
                          name="smsMessageInt"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={pricing?.smsMessageInt}
                          className="form-control"
                          placeholder="Enter sms messageInt price"
                          />
                        </div>
                      </FormGroup>

                      {errors.smsMessageInt &&
                          touched.smsMessageInt &&
                          errors.smsMessageInt}

                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">SMS OTP</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                        <Input
                          type="number"
                          name="smsOtp"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={pricing?.smsOtp}
                          className="form-control"
                          placeholder="Enter sms OTP price"
                          />
                        </div>
                      </FormGroup>

                      {errors.smsOtp &&
                          touched.smsOtp &&
                          errors.smsOtp}

                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">SMS OTP International</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                        <Input
                          type="number"
                          name="smsOtpInt"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={pricing?.smsOtpInt}
                          className="form-control"
                          placeholder="Enter sms OTP Int price"
                          />
                        </div>
                      </FormGroup>
                      {errors.smsOtpInt &&
                          touched.smsOtpInt &&
                        errors.smsOtpInt}
                      
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">BVN Verification</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                        <Input
                          type="number"
                          name="verificationBvn"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={pricing?.verificationBvn}
                          className="form-control"
                          placeholder="Enter verification Bvn price"
                          />
                        </div>
                      </FormGroup>
                      {errors.verificationBvn &&
                          touched.verificationBvn &&
                        errors.verificationBvn}
                      
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">NIN Verification</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                        <Input
                          type="number"
                          name="verificationNin"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={pricing?.verificationNin}
                          className="form-control"
                          placeholder="Enter verification NIN price"
                          />
                        </div>
                      </FormGroup>
                      {errors.verificationNin &&
                          touched.verificationNin &&
                          errors.verificationNin}

                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">Phone Verification</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                        <Input
                          type="number"
                          name="verificationPhone"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={pricing?.verificationPhone}
                          className="form-control"
                          placeholder="Enter verification Phone price"
                          />
                        </div>
                      </FormGroup>
                      {errors.verificationPhone &&
                          touched.verificationPhone &&
                        errors.verificationPhone}
                      
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">Voice OTP</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                        <Input
                          type="number"
                          name="voiceOtp"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={pricing?.voiceOtp}
                          className="form-control"
                          placeholder="Enter Voice OTP price"
                          />
                        </div>
                      </FormGroup>
                      {errors.voiceOtp &&
                          touched.voiceOtp &&
                        errors.voiceOtp}
                      
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">Whatsapp Message</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                        <Input
                          type="number"
                          name="whatsappMessage"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={pricing?.whatsappMessage}
                          className="form-control"
                          placeholder="Enter Whatsapp Message price"
                          />
                        </div>
                      </FormGroup>
                      {errors.whatsappMessage &&
                          touched.whatsappMessage &&
                          errors.whatsappMessage}

                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">Whatsapp Message Templete</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                        <Input
                          type="number"
                          name="whatsappMessageTemplate"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={pricing?.whatsappMessageTemplate}
                          className="form-control"
                          placeholder="Enter Whatsapp Message Templete price"
                          />
                        </div>
                      </FormGroup>
                      {errors.whatsappMessageTemplate &&
                          touched.whatsappMessageTemplate &&
                        errors.whatsappMessageTemplate}
                      
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">Whatsapp OTP</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                        <Input
                          type="number"
                          name="whatsappOtp"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={pricing?.whatsappOtp}
                          className="form-control"
                          placeholder="Enter Whatsapp OTP price"
                          />
                        </div>
                      </FormGroup>

                      {errors.whatsappOtp &&
                          touched.whatsappOtp &&
                          errors.whatsappOtp}
                     
                    

                      <button
                        variant="primary"
                        type="submit"
                        className="btn btn-block btn-primary"
                        disabled={isLoading}
                      >
                        {isLoading ? "Updating..." : "Update Pricing"}
                      </button>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default UpdatePricingContain;
