import { H5, P } from "../../AbstractElements";
import React, { Fragment, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
} from "reactstrap";
import { Formik } from "formik";
import { toast } from "react-toastify";
import api from "../../api/jwt";

const PageContain = (props) => {
  const { productId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
      const headers = {
      headers: { "Content-Type": "multipart/form-data" },
    }
    api
    .post("/product/image/" + productId, formData, headers)
      .then((res) => {
        setIsLoading(false);
      toast.success("Image uploaded successfully");
      window.location.href = "/cart/products";
    })
      .catch((err) => {
        setIsLoading(false);
      toast.error("Error uploading image");
    });


  }
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0])
  }

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-4">
                <H5>Upload Product Image { productId }</H5>
              </CardHeader>
              <CardBody>

              <form onSubmit={handleSubmit} className="mt-4">
                  <input type="file" onChange={handleFileSelect} accept="image/*" className="form-control" />
                  <br/>
                  <button
                        variant="primary"
                        type="submit"
                        className="btn btn-block btn-primary"
                        disabled={isLoading}
                      >
                        {isLoading ? "Uploading..." : "Upload Product Image"}
                      </button>
    </form>
               




                </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default PageContain;
