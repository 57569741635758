import React, { Fragment, useEffect, } from 'react';
import { Route, Routes, useLocation, useHistory } from 'react-router-dom';
import { routes } from './Routes';
import Layout from '../Layout/Layout';

const logOut = (location) => {
  localStorage.removeItem('token');
  localStorage.removeItem('authenticated');
  window.location.href = `/login?redirectTo=${location.pathname}`;
}
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const LayoutRoutes = () => {
  const location = useLocation();
  useEffect(() => {
    const accessToken = sessionStorage.getItem("token");
    setTimeout(() => {
      console.log("timeout checking...." + JSON.stringify(location));
      if (accessToken) {
        const decodedJwt = parseJwt(accessToken);
        if (decodedJwt.exp * 1000 < Date.now()) {
          logOut(location);
        }
      }
    }, 1000);

  }
    , [])


  return (
    <Fragment>
      <Routes>
        {routes.map(({ path, Component }, i) => (
          <Route element={<Layout />} key={i}>
            <Route path={path} element={Component} />
          </Route>
        ))}
      </Routes>
    </Fragment >
  );
};

export default LayoutRoutes;