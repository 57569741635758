import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import PricingContain from '../../Component/Pricing/index';
import api from '../../api/jwt'
import LoadingSpinner from "./../../CommonElements/Loader/LoadingSpinner";

const Pricing = () => {
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('');
  const [pricing, setPricing] = useState([]);
  const [user, setUser] = useState([]);
  const [business, setBusiness] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = 'Pricing - Spendo';
    setName(sessionStorage.getItem('Name')); 
    const businesss = JSON.parse(sessionStorage.getItem('business'));
    setBusiness(businesss);
    const user_data = sessionStorage.getItem('user_data');
    setUser(user_data);
    setIsLoading(true)
    api.get('/business/pricing/' + businesss.uuid)
      .then(res => {
        setIsLoading(false)
        setPricing(res.data.data);
      })
      .catch(err => {
        setIsLoading(false)
        // console.log(err)

      });

  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Business" title="Pricing" />
      {isLoading ? <LoadingSpinner /> : <PricingContain  business={business} pricing={pricing[0]} />}
    </Fragment>
  );
};
export default Pricing;