import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col } from 'reactstrap';
import { H4, H6, LI, UL, Image } from '../../AbstractElements';
import Img from '../../assets/images/login/bg.jpeg';

const ProfileHeader = (props) => {
  const { user, apiKeys } = props;
  return (
    <Fragment>
      <Col sm="12">
        <Card className="profile-header bg-image" style={{ backgroundImage: `url(${Img})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'block' }}>
          <div className="profile-img-wrrap">
            <Image style={{ backgroundSize: 'cover', backgroundPosition: 'center', display: 'block' }} attrImage={{ className: 'img-fluid bg-img-cover', src: `${require('../../assets/images/user-profile/bg-profile.jpg')}`, alt: '' }} /></div>
          <div className="userpro-box">
            <div className="img-wrraper">
              <div className="avatar"><Image attrImage={{ className: 'img-fluid', alt: '', src: `${require('../../assets/images/profile-image.png')}` }} /></div>
              {/* <Link className="icon-wrapper" to={`${process.env.PUBLIC_URL}/users/useredit`}> */}
                {/* <i className="icofont icofont-pencil-alt-5"></i> */}
              {/* </Link> */}
            </div>
            <div className="user-designation">
              <div className="title"><a target="_blank" href="#javascript">
                <H4>{user?.firstName + " " + user?.lastName || '***'}</H4>
                <H6>{ user?.email || '***'}</H6></a></div>
             
              <div className="follow">
                {/* <UL attrUL={{ className: 'simple-list follow-list flex-row' }}>
                  <LI>
                    <div className="follow-num counter">0</div><span>SmS Sent</span>
                  </LI>
                  <LI>
                    <div className="follow-num counter">0</div><span>Whatsapp Sent</span>
                  </LI>
                  <LI>
                    <div className="follow-num counter">0</div><span>Transactions</span>
                  </LI>
                </UL> */}
              </div>
            </div>
          </div>
        </Card>
      </Col>
    </Fragment>
  );
};
export default ProfileHeader;