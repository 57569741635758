import React, { Fragment, useState, useEffect } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import AddProductContain from "../../Component/Cart/addProduct";
import api from "../../api/jwt";

const AddProduct = () => {
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const [categories, setCategories] = React.useState([]);
  const [business, setBusiness] = useState([]);
  useEffect(() => {
    document.title = "Add Product - Spendo";
    setName(sessionStorage.getItem("Name"));
    const businesss = JSON.parse(sessionStorage.getItem("business"));
    setBusiness(businesss);
    const authenticated = JSON.parse(sessionStorage.getItem("authenticated"));
    const auth0_profile = JSON.parse(sessionStorage.getItem("auth0_profile"));
    const business_list = JSON.parse(sessionStorage.getItem("business_list"));
    const token = sessionStorage.getItem("token");
    api
      .get("/products/categories")
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {});
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Cart" title="Add Product" />
      <AddProductContain business={business} categories={categories} />
    </Fragment>
  );
};
export default AddProduct;
