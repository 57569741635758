import React, { Fragment } from 'react';
import ApexChart from 'react-apexcharts';
import { MoreHorizontal } from 'react-feather';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { H5, UL, LI } from '../../../AbstractElements';
// import { TotalTrasactionsChart } from '../../../Pages/DashBoard/ChartsData/TotalEvents';
import TotalReports from './TotalReports';

import ConfigDB from '../../../Config/Theme-Config';

const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || ConfigDB.data.color.secondary_color;

const TotalTrasactions = (props) => {
  const { dashboardStart } = props;
  const TotalTrasactionsChart = {
    series: [
      {
        name: 'Debit',
        data: [85, 80, 75, 70, 65, 60, 55]
      },
      {
        name: 'Credit',
        data: [15, 20, 25, 30, 35, 40, 45]
      },
      // {
      //   name: 'Transactions',
      //   data: [15, 20, 25, 30, 35, 40, 45]
      //   // data: dashboardStart?.totalTransactionsAmount
      // },

    ],

    options: {
      chart: {
        toolbar: {
          show: true
        },
        type: 'bar',
        height: 155,
        stacked: true,
      },
      colors: [primary, secondary],
      plotOptions: {
        bar: {
          barHeight: '60px',
          horizontal: true,
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 1,
        borderRadius: 40,
        colors: ['transparent']
      },

      grid: {
        borderColor: '#1e2f6533',
        xaxis: {
          lines: {
            show: true,
            Color: '#1e2f6533',
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        },
        padding: {
          top: -20,
          right: 0,
          bottom: -10,
          left: 0
        },

      },
      yaxis: {
        show: false,
      },
      legend: {
        show: false,
      },
      tooltip: {
        x: {
          formatter: function (val) {
            return val;
          }
        },
        y: {
          formatter: function (val) {
            return Math.abs(val) + '%';
          }
        }
      },
      states: {
        hover: {
          filter: {
            type: 'darken',
            value: 1,
          }
        }
      },
      xaxis: {
        position: 'top',
        categories: ['85+', '80-84', '75-79', '70-74', '65-69', '60-64', '55-59'
        ],
        labels: {
          formatter: function (val) {
            return Math.abs(Math.round(val));
          },
          offsetX: 0,
          offsetY: 5,
          rotate: 0,
          style: {
            colors: ['#1e2f6533'],
            fontSize: '12px',
            fontWeight: 400,
          },
        },
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false
        },
      },
    }
  };
  return (
    <Fragment>
      <Col xl='6' lg='12' className='dash-xl-100 box-col-12'>
        <Card className="total-transactions">
          <Row className="row m-0">
            <Col sm='6' md='6' className=" p-0">
              <div className="card-header card-no-border">
                <H5>Total Transactions</H5>
              </div>
              <CardBody className="pt-0">
                <div>
                  <ApexChart id="transaction-chart" options={TotalTrasactionsChart.options} series={TotalTrasactionsChart.series} type='bar' height="155" />
                </div>
              </CardBody>
            </Col>
            <Col sm='6' md='6' className="p-0 report-sec">
              <CardHeader className="card-no-border">
              </CardHeader>
              <TotalReports dashboardStart={dashboardStart} />
            </Col>
          </Row>
        </Card>
      </Col>
    </Fragment>
  );
};
export default TotalTrasactions;