import { H5, P } from "../../AbstractElements";
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
} from "reactstrap";
import api from "../../api/jwt";
import { Formik } from "formik";
import { toast } from "react-toastify";
import {Editor, EditorState} from 'draft-js';
import 'draft-js/dist/Draft.css';

const UpdatePricingContain = (props) => {
  const { pricing, business } = props;
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader  className="pb-0"yarn>
                <h3>Business Pricing</h3>
                <p>
                  Below are the current pricing for your Business, you can request for a custom pricing by contacting us.
                </p>
              </CardHeader>
              
              <br/>
              <CardBody>
               
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">SMS Message</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                    <Input
                       disabled={true}
                          type="number"
                          name="smsMessage"
                          defaultValue={pricing?.smsMessage}
                          className="form-control"
                          placeholder="sms message price"
                          />
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">SMS Bulk Message</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                    <Input
                       disabled={true}
                          type="number"
                          name="smsMessageBulk"
                          defaultValue={pricing?.smsMessageBulk}
                          className="form-control"
                          placeholder="sms bulk message price"
                          />
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">SMS Message International</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                    <Input
                       disabled={true}
                          type="number"
                          name="smsMessageInt"
                          defaultValue={pricing?.smsMessageInt}
                          className="form-control"
                          placeholder="sms messageInt price"
                          />
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">SMS OTP</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                    <Input
                       disabled={true}
                          type="number"
                          name="smsOtp"
                          defaultValue={pricing?.smsOtp}
                          className="form-control"
                          placeholder="sms OTP price"
                          />
                        </div>
                      </FormGroup>


                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">SMS OTP International</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                    <Input
                       disabled={true}
                          type="number"
                          name="smsOtpInt"
                          defaultValue={pricing?.smsOtpInt}
                          className="form-control"
                          placeholder="sms OTP Int price"
                          />
                        </div>
                      </FormGroup>
                      
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">BVN Verification</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                    <Input
                       disabled={true}
                          type="number"
                          name="verificationBvn"
                          defaultValue={pricing?.verificationBvn}
                          className="form-control"
                          placeholder="verification Bvn price"
                          />
                        </div>
                      </FormGroup>
                      
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">NIN Verification</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                    <Input
                       disabled={true}
                          type="number"
                          name="verificationNin"
                          defaultValue={pricing?.verificationNin}
                          className="form-control"
                          placeholder="verification NIN price"
                          />
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">Phone Verification</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                    <Input
                       disabled={true}
                          type="number"
                          name="verificationPhone"
                          defaultValue={pricing?.verificationPhone}
                          className="form-control"
                          placeholder="verification Phone price"
                          />
                        </div>
                      </FormGroup>
                      
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">Voice OTP</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                    <Input
                       disabled={true}
                          type="number"
                          name="voiceOtp"
                          defaultValue={pricing?.voiceOtp}
                          className="form-control"
                          placeholder="Voice OTP price"
                          />
                        </div>
                      </FormGroup>
                      
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">Whatsapp Message</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                    <Input
                       disabled={true}
                          type="number"
                          name="whatsappMessage"
                          defaultValue={pricing?.whatsappMessage}
                          className="form-control"
                          placeholder="Whatsapp Message price"
                          />
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">Whatsapp Message Templete</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                    <Input
                       disabled={true}
                          type="number"
                          name="whatsappMessageTemplate"
                          defaultValue={pricing?.whatsappMessageTemplate}
                          className="form-control"
                          placeholder="Whatsapp Message Templete price"
                          />
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">Whatsapp OTP</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            ₦
                          </span>
                    <Input
                      disabled={true}
                          type="number"
                          name="whatsappOtp"
                          defaultValue={pricing?.whatsappOtp}
                          className="form-control"
                          placeholder="Whatsapp OTP price"
                          />
                        </div>
                      </FormGroup>
                    

                      {/* <button
                        variant="primary"
                        type="submit"
                        className="btn btn-block btn-primary"
                        disabled={isLoading}
                      >
                        {isLoading ? "Updating..." : "Update Pricing"}
                      </button> */}
                <br />
                <br/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default UpdatePricingContain;
