import { BlogSvg, BonusUISvg, ButtonsSvg, ShoppingCart, CalanderSvg, ChartsSvg, ChatSvg, ContactSvg, EcomTempSVG, EcommerceSvg, EditorsSvg, EmailSvg, FAQSvg, FilemanagerSvg, FormsSvg, GallarySvg, HeaderBookmarkSvg, HomeSvg, IconsSvg, JobsearchSvg, KanbanSvg, KnowledgebaseSvg, LearningSvg, MapsSvg, OthersSvg, ProjectSvg, SamplePageSvg, SearchResultSvg, SocialappSvg, SupportTicketSvg, TablesSvg, TaskSvg, TodoSvg, UiKitsSvg, UsersComponentSvg, WidgetsSvg, WhatsappSvg } from '../../Data/svgIcons';

export const MENUITEMS = [
    {
        menutitle: 'DashBoard',
        Items: [
            { path: `${process.env.PUBLIC_URL}/admin/dashboard`, bookmark: true, icon: HomeSvg, title: 'Dashboard', type: 'link' }
        ]
    },
    {
        menutitle: 'SMS',
        Items: [
        
            {
                title: 'Sms', icon: EmailSvg, type: 'sub', active: false, children: [
                    // { path: `${process.env.PUBLIC_URL}/sms/overview`, title: 'Overview', type: 'link' },
                    // { path: `${process.env.PUBLIC_URL}/sms/send`, title: 'Send Sms', type: 'link' },
                    // { path: `${process.env.PUBLIC_URL}/sms/bulk`, title: 'Send Bulk Sms', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/admin/sms/senderId`, title: 'Sender ID', type: 'link' },
                    // { path: `${process.env.PUBLIC_URL}/sms/report`, title: 'Report', type: 'link' },
                ]
            },
        ]
    },
    // {
    //     menutitle: 'Whatsapp',
    //     Items: [
    //         {
    //             title: 'Whatsapp', icon: WhatsappSvg, type: 'sub', active: false, children: [
    //                 { path: `${process.env.PUBLIC_URL}/whatsapp/overview`, title: 'Overview', type: 'link' },
    //                 { path: `${process.env.PUBLIC_URL}/whatsapp/message/send`, title: 'Whatsapp Send', type: 'link' },
    //                 { path: `${process.env.PUBLIC_URL}/whatsapp/otp`, title: 'Whatsapp OTP', type: 'link' },
    //                 { path: `${process.env.PUBLIC_URL}/whatsapp/messages`, title: 'Messages', type: 'link' },
    //                 { path: `${process.env.PUBLIC_URL}/whatsapp/report`, title: 'Report', type: 'link' },
    //             ]
    //         },
    //     ]
    // },

    // {
    //     menutitle: 'Voice',
    //     Items: [
    //         {
    //             title: 'Voice', icon: ProjectSvg, type: 'sub', active: false, children: [
    //                 { path: `${process.env.PUBLIC_URL}/voice/overview`, title: 'Overview', type: 'link' },
    //                 { path: `${process.env.PUBLIC_URL}/voice/otp/send`, title: 'Voice OTP Send', type: 'link' },
    //                 { path: `${process.env.PUBLIC_URL}/voice/report`, title: 'Report', type: 'link' },
    //             ]
    //         },
    //     ]
    // },
    // {
    //     menutitle: 'Verification',
    //     Items: [
    //         {
    //             title: 'Verification', icon: KnowledgebaseSvg, type: 'sub', active: false, children: [
    //                 { path: `${process.env.PUBLIC_URL}/verification/overview`, title: 'Overview', type: 'link' },
    //                 { path: `${process.env.PUBLIC_URL}/verification/nin`, title: 'NIN Verification', type: 'link' },
    //                 { path: `${process.env.PUBLIC_URL}/verification/bvn`, title: 'BVN Verification', type: 'link' },
    //                 { path: `${process.env.PUBLIC_URL}/verification/report`, title: 'Report', type: 'link' },
    //             ]
    //         },
    //     ]
    // },
    
    {
        menutitle: 'Businesses',
        Items: [
            { path: `${process.env.PUBLIC_URL}/admin/businesses`, bookmark: true, icon: ProjectSvg, title: 'Businesses', type: 'link' }
        ]
    },

    {
        menutitle: 'Users',
        Items: [
            { path: `${process.env.PUBLIC_URL}/admin/users`, bookmark: true, icon: UsersComponentSvg, title: 'Users', type: 'link' }
        ]
    },
    {
        menutitle: 'SMPP Users',
        Items: [
            { path: `${process.env.PUBLIC_URL}/admin/smppUsers`, bookmark: true, icon: UsersComponentSvg, title: 'SMPP Users', type: 'link' }
        ]
    },
    {
        menutitle: 'Transactions',
        Items: [
            { path: `${process.env.PUBLIC_URL}/admin/transactions`, bookmark: true, icon: EditorsSvg, title: 'Transactions', type: 'link' }
        ]
    },
    {
        menutitle: "Cart",
        Items: [
          {
            title: "Cart",
            icon: ShoppingCart,
            type: "sub",
            active: false,
            children: [
              {
                path: `${process.env.PUBLIC_URL}/admin/cart/overview`,
                title: "Overview",
                type: "link",
              },
              {
                path: `${process.env.PUBLIC_URL}/admin/cart/products`,
                title: "Products",
                type: "link",
              },
              {
                path: `${process.env.PUBLIC_URL}/admin/cart/orders`,
                title: "Orders",
                type: "link",
              },
              {
                path: `${process.env.PUBLIC_URL}/admin/cart/subscriptions`,
                title: "Subscriptions",
                type: "link",
              },
    
              // {
              //   path: `${process.env.PUBLIC_URL}/admin/cart/settings`,
              //   title: "Settings",
              //   type: "link",
              // },
    
    
            ],
          },
        ],
      },
    {
        menutitle: 'User Account',
        Items: [
            { path: `${process.env.PUBLIC_URL}/admin/user/account`, icon: SupportTicketSvg, type: 'link', active: false, title: 'User Account' },
        ]
    },
   
    {
        menutitle: 'System Switch',
        Items: [
            { path: `${process.env.PUBLIC_URL}/admin/switch`, icon: SupportTicketSvg, type: 'link', active: false, title: 'System Switch' },
        ]
    },
];