import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import UsersProfileContain from '../../../Component/Admin/Switch';
import api from '../../../api/jwt'

const UsersProfile = () => {
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('');
  const [systemSwitch, setSystemSwitch] = useState([]);
  const [business, setBusiness] = useState([]);
  useEffect(() => {
    setName(sessionStorage.getItem('Name')); 
    api.get('/admin/getSystemSwitch')
      .then(res => {
        setSystemSwitch(res.data.data);
      })
      .catch(err => {

      });

  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Settings" title="System Switch" />
      <UsersProfileContain systemSwitch={systemSwitch} />
    </Fragment>
  );
};
export default UsersProfile;