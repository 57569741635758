import { H5, P, Btn } from "../../AbstractElements";
import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { usePaystackPayment } from "react-paystack";
import api from "../../api/jwt";
import { Formik } from "formik";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import CsvDownloadButton from 'react-json-to-csv'

const NGNcurrencyFormat = require('../../utils/NGNcurrencyFormat');
const SamplePageContain = (props) => {
  const [business, setBusiness] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(10);
  const [action] = useState({ fromUser: false });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([]);
  const [search, setSearch] = React.useState('');
  // fetch data
  const fetchData = async (page, rowsPerPage) => {
    const business = JSON.parse(sessionStorage.getItem('business'));
    setLoading(true);
    api.get(`/transactions/${business.uuid}/${page}/${rowsPerPage}/DESC/createdAt`)
      .then(res => {
        setLoading(false)
        setData(res.data.data.content);
        setTotalRows(res.data.data.size);        
      })
      .catch(err => {
        setLoading(false)
      });
  }

   // fetch data with date
   const fetchDataWithDate = async (startDate, endDate) => {
    const business = JSON.parse(sessionStorage.getItem('business'));
    setLoading(true);
    const payload = {
      "endDate": endDate,
      "startDate": startDate
    }
    api.get(`/transactions/${business.uuid}/0/100/DESC/createdAt`)
      .then(res => {
        setLoading(false)
        setData(res.data.data.content);
        setTotalRows(res.data.data.size);        
      })
      .catch(err => {
        setLoading(false)
      });
  }

  useEffect(() => {
    const business = JSON.parse(sessionStorage.getItem('business'));
    setBusiness(business)
    fetchData(0, 15);
  }, []);

const handlePageChange = (page) => {
  fetchData(page, rowsPerPage);
  setCurrentPage(page);
};
  
const handleRowsPerPageChange = async (newRowsPerPage) => {
  if (!data.length) return;
  fetchData(1, newRowsPerPage);
  setRowsPerPage(newRowsPerPage);
  setCurrentPage(1);
  setSelectedRowsPerPage([]);
};

const handleOnSelectedRowsChange = useCallback(
  ({ selectedRows }) => {
    if (!action.fromUser) return;

    selectedRowsPerPage[currentPage] = selectedRows;
    console.log(JSON.stringify(selectedRowsPerPage));
  },
  [currentPage, selectedRowsPerPage, action.fromUser]
);

const handleMouseEnter = () => {
  action.fromUser = true;
};

const handleMouseLeave = () => {
  action.fromUser = false;
};

  
const handleApplySelectedRows = (row) =>
  selectedRowsPerPage[currentPage]?.filter(
    (selectedRow) => selectedRow.id === row.id
  ).length > 0;

  const handleSearch = (event) => {
    if (event.target.value === '' || event.target.value === null) {
      setData(data);
    }
    setSearch(event.target.value);
    
    const filtered = data?.filter((filter) => {
      return (
        filter?.billFor?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        filter?.type?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        filter?.number?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        filter?.amount?.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
        new Date(filter?.createdAt).toLocaleString().toLowerCase().includes(event.target.value.toLowerCase())
      );
    }
    );
    
    setData(filtered);
  };
  const handleKeyDown = event => {
    if (event.keyCode === 8) {
      setData(data);
    }
  };

  const columns = [
    {
      name: "BILL FOR",
      selector: (row) => row?.billFor,
      sortable: true,
    },
    {
      button: true,
        cell: (row) => <>
          {row.type === 'credit' ? <span className="badge badge-success">Credit</span> : <span className="badge badge-danger">Debit</span>}
        </>,
      name: "TYPE",
      sortable: true,
      right: false
    },
    {
      name: "NUMBER",
      selector: (row) => row?.number,
      sortable: true,
    },
    {
      name: "AMOUNT",
      selector: (row) => NGNcurrencyFormat.default(row?.amount),
      sortable: true,
      right: true,
    },
    {
      name: "DATE",
      selector: (row) => new Date(row?.createdAt).toLocaleString(),
      sortable: true,
      right: true,
    }
  ];
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <Row>
                  <Col>
                <div className="d-flex justify-content-between">
                  <input
                  autoFocus
                  className="form-control"
                  margin="dense"
                  placeholder="Filter"
                  id="search" type="text"
                  onKeyDown={handleKeyDown}
                  onChange={handleSearch}
                  />
                  </div>
                  </Col>

                  <Col>
                  
                    <div className="d-flex justify-content-between">
                      <Formik
                  initialValues={{
                    dateFrom: "",
                    dateTo: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.dateFrom) {
                      errors.dateFrom = "Required";
                    }
                    if (!values.dateTo) {
                      errors.dateTo = "Required";
                    }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    fetchDataWithDate(values.dateFrom, values.dateTo)
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                            <Row>
                              <Col>
                      <FormGroup>
                        <Input
                          type="date"
                          name="dateFrom"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.dateFrom}
                          className="form-control"
                          
                        />

                        {errors.dateFrom && touched.dateFrom && errors.dateFrom}
                                </FormGroup>
                              </Col>
                              <Col>
                      <FormGroup>
                        <Input
                          type="date"
                          name="dateTo"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.dateTo}
                          className="form-control"
                          
                        />

                        {errors.dateTo && touched.dateTo && errors.dateTo}
                                </FormGroup>
                              </Col>
                              <Col>
                      <button
                        variant="primary"
                        type="submit"
                        className="btn btn-block btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Loading..." : "Filter"}
                                </button>
                                </Col>
                              
                      </Row>
                    </Form>
                  )}
                </Formik>

                    </div>
                  </Col>
                  <Col>
                    <CsvDownloadButton
                      className="btn btn-block btn-info"
                      filename={`${business?.businessName} - Transactions`}
                      data={data} />
                  </Col>

                  </Row>
                
              </CardHeader>
              <CardBody>
                
              <DataTable
                  // title="Transactions"
                  columns={columns}
                  data={data}
                  defaultSortFieldID={1}
                  striped={true}
                  center={true}
                  pagination
                  paginationServer
                  progressPending={loading}
                  paginationTotalRows={totalRows}
                  selectableRowsNoSelectAll={true}
                  paginationDefaultPage={currentPage}
                  paginationRowsPerPageOptions={[4, 8, 15]}
                  paginationPerPage={rowsPerPage}
                  onRowMouseEnter={handleMouseEnter}
                  onRowMouseLeave={handleMouseLeave}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onSelectedRowsChange={handleOnSelectedRowsChange}
                  selectableRowSelected={handleApplySelectedRows}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default SamplePageContain;
