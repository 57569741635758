import { H5, P } from '../../AbstractElements';
import React, { Fragment } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';

const SmsPageContain = () => {
    return (
      <Fragment>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="pb-0">
                  <H5>Verification API{" "}</H5>
                  <span>Verify User Identity</span>
                </CardHeader>
                {/* <CardBody>
<p><strong>Important!</strong> For customers sending messages to
              Nigeria, DND stands for Do-Not-Disturb and phone numbers with DND
              settings activated are blocked from receiving messages from the
              generic route by the Mobile Network Operators. To deliver messages
              to phone numbers on DND, the Volt DND route needs to be activated
              on your account. Kindly reach out to our support team.</p>
                </CardBody> */}
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
};
export default SmsPageContain;