import React, { Fragment } from 'react';
import { Image } from '../../../AbstractElements';
import { Btn, H5, LI, P, UL } from '../../../AbstractElements';
import { Col, Form, FormGroup,Card, Label, InputGroup, InputGroupAddon, InputGroupText, Input, Row } from 'reactstrap';
import api from '../../../api/jwt'


const ProfileContain = (props) => {
  const { user, apiKeys } = props;

  return (
    <Fragment>
      <Col xl="8" lg="12" md="7" className="xl-65">
        <Row className="row">
          <Col sm="12">
            <Card>
            
              <div className="profile-post">
              <H5 attrH5={{ className: 'p-0' }}>
              API Keys
                </H5>
                
                <Row>
          <Col md="12 mb-3">
            <Label htmlFor="validationCustom01">Public Test Key</Label>
            <input readOnly className="form-control" value={""} type="text" placeholder="*********"/>
                  </Col>
                  
                  <Col md="12 mb-3">
            <Label htmlFor="validationCustom01">Secret Test Key</Label>
            <input readOnly className="form-control" value={""} type="text" placeholder="*********"/>
                  </Col>
                  
                  {/* <Col>
                  
                  <button
                        onClick={() => generateKeys("live")}
                        className="btn btn-primary"
                      >
                        Re-generate Keys
                    </button>
                    </Col> */}
                </Row>
                

                <hr />
                
                <Row>
          <Col md="12 mb-3">
            <Label htmlFor="validationCustom01">Public Live Key</Label>
            <input readOnly className="form-control" value={""} type="text" placeholder="*********"/>
                  </Col>
                  
                  <Col md="12 mb-3">
            <Label htmlFor="validationCustom01">Secret Live Key</Label>
            <input readOnly className="form-control" value={""} type="text" placeholder="*********"/>
            
                  </Col>

                  {/* <Col>
                  
                  <button
                        onClick={() => generateKeys("live")}
                        className="btn btn-primary"
                      >
                        Re-generate Keys
                    </button>
                    </Col> */}
                </Row>
                
              </div>
            </Card>
          </Col>
        </Row>
      </Col >
    </Fragment >
  );
};
export default ProfileContain;