import { H5, P } from "../../AbstractElements";
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
} from "reactstrap";
import api from "../../api/jwt";
import { Formik } from "formik";
import { toast } from "react-toastify";
import 'draft-js/dist/Draft.css';
import CKEditors from 'react-ckeditor-component';


const UpdateProductContain = (props) => {
  const { business, categories, product } = props;
  const [isLoading, setIsLoading] = useState(false);
  
  const [content, setContent] = useState('');
  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    setContent(newContent);
  };

  useEffect(() => {
    setContent(product.productDescription);
  }, [product.productDescription]);

  const addProduct = (
    productName,
    // productDescription,
    productCategory,
    productPrice,
    productStock
  ) => {
    var self = this;
    setIsLoading(true);
    const payload = {
      businessId: business.uuid,
      productName: productName,
      productDescription: content,
      productPrice: productPrice,
      productCategory: productCategory,
      productStock: productStock,
    };

    api
      .post("/product/update/" + product.productId, payload)
      .then((res) => {
        setIsLoading(false);
        setTimeout(() => {
          toast.success("Product updated successfully");
          window.location.href = "/cart/products";
        }, 200);

        
      })
      .catch((err) => {
        if (err) {
          setIsLoading(false);

          setTimeout(() => {
            toast.error("Oppss.." + err.response.data);
          }, 200);
        }
        // console.log(err);
      });
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Update Product {product.productName} </H5>
                <span>
                  Update Products to your store and start selling to customers
                </span>
              </CardHeader>
              <CardBody>
                <Formik
                  initialValues={{
                    productName: product.productName,
                    productCategory: product.productCategory,
                    productPrice: product.productPrice,
                    productStock: product.productStock,
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.productName) {
                      errors.productName = "Required";
                    } else if (!values.productCategory) {
                      errors.productCategory = "Select Category";
                    }
                    
                    // else if (values.productDescription.length < 10) {
                    //   errors.message = "Must be at least 10 characters";
                    // }
                    if (!values.productPrice) {
                      errors.productPrice = "Required";
                    }
                    if (!values.productStock) {
                      errors.productStock = "Required";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    // setTimeout(() => {
                    // window.alert(JSON.stringify(values, null, 2));
                    addProduct(
                      values.productName,
                      // values.productDescription,
                      values.productCategory,
                      values.productPrice,
                      values.productStock
                    );
                    setSubmitting(false);
                    // }, 400);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit} className="mt-4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">Name</Label>
                        <Input
                          type="text"
                          name="productName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // value={values.productName}
                          defaultValue={product.productName}
                          className="form-control"
                          // id="exampleFormControlTextarea1"
                          // rows="3"
                          placeholder="Enter product name"
                        />
                        {/* {errors.message && touched.message && errors.message} */}
                        {errors.productName &&
                          touched.productName &&
                          errors.productName}
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlTextarea1">
                          Description
                        </Label>
                        <CKEditors
                          activeclassName="p10"
                          // defaultValue={product.productDescription}
                          content={content}
                          events={{
                            'change': onChange
                          }}
                        />
                        {/* <Input
                          type="textarea"
                          name="productDescription"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // value={values.productDescription}
                          defaultValue={product.productDescription}
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          placeholder="Enter product description"
                        />
                        {errors.productDescription &&
                          touched.productDescription &&
                          errors.productDescription} */}
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                          Product Price
                        </Label>
                        <Input
                          type="number"
                          name="productPrice"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // value={values.productPrice}
                          defaultValue={product.productPrice}
                          className="form-control"
                          // id="exampleFormControlTextarea1"
                          // rows="3"
                          placeholder="Enter product price"
                        />
                        {errors.productPrice &&
                          touched.productPrice &&
                          errors.productPrice}
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                          Product in Stock
                        </Label>
                        <Input
                          type="number"
                          name="productStock"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // value={values.productStock}
                          defaultValue={product.productStock}
                          className="form-control"
                          // id="exampleFormControlTextarea1"
                          // rows="3"
                          placeholder="Enter product Stock"
                        />
                        {errors.productStock &&
                          touched.productStock &&
                          errors.productStock}
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                          Category
                        </Label>
                        <Input
                          type="select"
                          name="productCategory"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // value={values.productCategory}
                          className="form-control"
                          // defaultValue="1"
                          // defaultValue={product.productCategory}
                        >
                          {/* <option>Select Product Category</option> */}
                          <option value={product.productCategory} defaultChecked>{product.productCategory}</option>

                          {categories.map((category, index) => {
                            return (

                              <option key={index} value={category.categoryName}>
                                {category.categoryName}
                              </option>
                            );
                          })}
                        </Input>
                        {errors.productCategory &&
                          touched.productCategory &&
                          errors.productCategory}
                      </FormGroup>

                      <button
                        variant="primary"
                        type="submit"
                        className="btn btn-block btn-primary"
                        disabled={isLoading}
                      >
                        {isLoading ? "Updating..." : "Update Product"}
                      </button>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default UpdateProductContain;
