import React, { Fragment } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { H5, H6, P } from '../../AbstractElements';
import { YearlyChart } from '../../Pages/DashBoard/ChartsData/TotalEvents';

const Yearly = (props) => {
  const { dashboardStart } = props;
  return (
    <Fragment>
      <Col xl='3' md='6' className='dash-xl-50 box-col-6'>
        <Card className="yearly-chart">
          <CardHeader className="card-no-border pb-0">
            <H5 attrH5={{ className: 'pb-2' }}>₦{dashboardStart.totalTransactionCredit}</H5>
            {/* this month date */}
            <H6 attrH6={{ className: 'font-theme-light f-14 m-0' }}>
              {/* get current month and year */}
              {new Date().toLocaleString('default', { month: 'long' })}, {new Date().getFullYear()}
              

            </H6>
          </CardHeader>
          <CardBody className="pt-0">
            <div>
              <ReactApexChart id="yearly-chart" options={YearlyChart.options} series={YearlyChart.series} height='160' type='line' />
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl='3' md='6' className='dash-xl-50 box-col-6'>
        <Card className="bg-primary premium-access">
          <CardBody>
            <H6 attrH6={{ className: 'f-22' }}>Join Our Community!</H6>
            <P>Connect with us to discover best practices, untapped solutions, helpful docs, guides, events, blogs, and more. Learn, build, and share with like-minded thinkers.</P>
            <a href='https://t.me/getspendo' className="btn btn-outline-white_color" >
              Join now for free
            </a>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};
export default Yearly;