import { H5, H2, P } from "../../AbstractElements";
import React, { Fragment } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

const CartPageContain = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-4">
                <H5>Spendo Mall</H5>
                <span>New Shopping Experience</span>
              </CardHeader>
              <CardBody>
<p>WhatsApp based shopping experience and e-commerce platform where you can directly purchase from our registered vendors through our efficiently automated chatbot on WhatsApp in an instant-to-instant fashion. 
                </p>
                
                <p>
                Say “hi” on Whatsapp to get started!
                </p>
                <H2>+234 906 901 9514</H2>

                </CardBody>
              
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default CartPageContain;
