import { H5, P, Btn } from "../../../AbstractElements";
import React, { Fragment, useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { usePaystackPayment } from "react-paystack";
import api from "../../../api/jwt";
import { Formik } from "formik";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";

const NGNcurrencyFormat = require('../../../utils/NGNcurrencyFormat');

const SamplePageContain = (props) => {
  const { subscription } = props;

  const columns = [
    {
      name: "Start Date",
      selector: (row) => row.subscriptionStartDate,
      sortable: true,
    },
    {
      name: "End Date",
      selector: (row) => row.subscriptionEndDate,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.subscriptionStatus,
      sortable: true,
      right: true,
    },
    {
      name: "ACTION",
      button: true,
      sortable: true,
      right: true,
      cell: (row) => <>
        <Link to={`/admin/businessesProfile/${row.businessId}`} className="btn btn-primary btn-sm"><i className="fa fa-eye"></i></Link>
      </>,
    },
  ];
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                {/* <H5>Transactions</H5> */}

                <DataTable
                  // title="Transactions"
                  columns={columns}
                  data={subscription}
                  defaultSortFieldID={1}
                  pagination
                  striped={true}
                  center={true}
                  // paginationComponent={BootyPagination}
                  // selectableRows
                  // selectableRowsComponent={BootyCheckbox}
                />
              </CardHeader>
              <CardBody>
                <p>-</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default SamplePageContain;
