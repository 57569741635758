import { H5, P } from "../../AbstractElements";
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
} from "reactstrap";
import api from "../../api/jwt";
import { Formik } from "formik";
import { toast } from "react-toastify";
import {Editor, EditorState} from 'draft-js';
import 'draft-js/dist/Draft.css';

const SettingContain = (props) => {
  const { business, account } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [productDescription, setProductDescription] = useState("");

  const editorState  = EditorState.createEmpty();

  const addAccount = (
    accountName,
    accountNumber,
    accountType,
    bankName,
  ) => {
    var self = this;
    setIsLoading(true);
    const payload = {
      accountName: accountName,
      accountNumber: accountNumber,
      accountType: accountType,
      bankName: bankName,
    };

    api
      .put("/business/account/" + business.uuid, payload)
      .then((res) => {
        setIsLoading(false);
        setTimeout(() => {
          toast.success("Account added successfully");
          window.location.href = "/cart/products"
        }, 200);

        
      })
      .catch((err) => {
        if (err) {
          setIsLoading(false);

          setTimeout(() => {
            toast.error("Oppss.." + err.response.data.message);
          }, 200);
        }
        // console.log(err);
      });
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Settlement Account </H5>
                <span>
                  Add a settlement account to receive payments from your
                  customers
                </span>
              </CardHeader>
              <CardBody>
                <Formik
                  initialValues={{
                    accountName: "",
                    accountNumber: "",
                    bankName: "",
                    accountType: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.accountName) {
                      errors.accountName = "Required";
                    } else if (!values.accountNumber) {
                      errors.accountNumber = "Select Category";
                    }
                    
                    if (!values.bankName) {
                      errors.bankName = "Required";
                    }
                    if (!values.accountType) {
                      errors.accountType = "Required";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    // setTimeout(() => {
                    // window.alert(JSON.stringify(values, null, 2));
                    addAccount(
                      values.accountName,
                      values.accountNumber,
                      values.accountType,
                      values.bankName,
                    );
                    setSubmitting(false);
                    // }, 400);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit} className="mt-4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">Account Name</Label>
                        <Input
                          type="text"
                          name="accountName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // value={account.accountName}
                          defaultValue={account.accountName}
                          className="form-control"
                          // id="exampleFormControlTextarea1"
                          // rows="3"
                          placeholder="Enter Account name"
                        />
                        {errors.accountName &&
                          touched.accountName &&
                          errors.accountName}
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">Account Number</Label>
                        <Input
                          type="text"
                          name="accountNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // value={values.accountNumber}
                          defaultValue={account.accountNumber}
                          className="form-control"
                          // id="exampleFormControlTextarea1"
                          // rows="3"
                          placeholder="Enter Account Number"
                        />
                        {errors.accountNumber &&
                          touched.accountNumber &&
                          errors.accountNumber}
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">Bank Name</Label>
                        <Input
                          type="text"
                          name="bankName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // value={values.bankName}
                          defaultValue={account.bankName}
                          className="form-control"
                          // id="exampleFormControlTextarea1"
                          // rows="3"
                          placeholder="Enter Bank Name"
                        />
                        {errors.bankName &&
                          touched.bankName &&
                          errors.bankName}
                      </FormGroup>
                      
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                        Account Type
                        </Label>
                        <Input
                          type="select"
                          name="accountType"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // value={values.accountType}
                          defaultValue={account.accountType}
                          className="form-control"
                          // defaultValue="1"
                        >
                          <option>Select Account Type</option>
                          <option value={account.accountType}>{account.accountType}</option>
                          <option value="Savings">Savings</option>
                          <option value="Current">Current</option>
                          
                          {/* {categories.map((category, index) => {
                            return (
                              <option key={index} value={category.categoryName}>
                                {category.categoryName}
                              </option>
                            );
                          })} */}
                        </Input>
                        {errors.accountType &&
                          touched.accountType &&
                          errors.accountType}
                      </FormGroup>

                      <button
                        variant="primary"
                        type="submit"
                        className="btn btn-block btn-primary"
                        disabled={isLoading}
                      >
                        {isLoading ? "Adding..." : "Add / Update Account"}
                      </button>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default SettingContain;
