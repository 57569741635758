import React, { Fragment, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Collapse, Form, FormGroup, Input, Label,InputGroup, InputGroupText, } from 'reactstrap';

import { Btn, H5, LI, P, UL } from '../../../AbstractElements';
import api from '../../../api/jwt'

import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

const Security = (props) => {
  const { user } = props;

  const [aboutme, setisAboutme] = useState(true);
  const [loading, setLoading] = useState(false);

  const toggleClick = () => {
    setisAboutme(!aboutme);
  };

  const disable2fa = async () => {
    setLoading(true);
      api.post('/auth/disable2fa')
          .then(res => {
              setLoading(false);
              setTimeout(() => {
                  toast.success(
                      '2factor Authentication disabled successfully!'
                  );
                window.location.reload();
              }, 200);
              return res.data;
          })
          .catch(err => {
            if (err) {
                  setLoading(false);
                  setTimeout(() => {
                      toast.error('Oppss..' + err.response.data.message);
                  }, 200);
              }
          });   
  }
  return (
    <Fragment>
      <Col xl="12">
        <Card>
          <CardHeader>
            <H5 attrH5={{ className: 'p-0' }}>
              <Btn attrBtn={{
                className: 'btn btn-link', databstoggle: 'collapse', databstarget: '#collapseicon4'
                , ariaexpanded: 'true', ariacontrols: 'collapseicon4', color: 'transperant', onClick: toggleClick
              }} >Two-Factor Authentication</Btn>
            </H5>
          </CardHeader>

          <CardBody className="post-about">
            {user?.twoFactorAuth ?
              <>
              Enroll in Two-Factor Authentication by scanning the QR Code to start. If you do not have a mobile authentication app we recommend Authy, Duo Mobile, LastPass or 1Password as they are cloud based and if your hardware device is broken you can recover access.

            <br/>
                <br />
                When enabled Two-Factor Authentication increases your account's security by requiring a second method of verification before you can login to spendoware's console.

             <br/>
          
            
              </>
              :
              "Two-Factor Authentication is enabled for your account. You can disable it by clicking the button below."
            }

            
            <br />
            <br />
            {/* show active or inactive status */}
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                {user?.twoFactorAuth ?
                  <div className="icon">
                    <i className="fa fa-circle text-success" />
                  </div>
                  :
                  <div className="icon">
                    <i className="fa fa-circle text-danger" />
                  </div>
                
              }
                <div className="ml-2">
                  <H5>2fa Status</H5>
                  <P>Two-Factor Authentication is {user?.twoFactorAuth ? 'enabled' : 'disabled'} for your account.</P>
                </div>
              </div>
            </div>

            <br />
            {user?.twoFactorAuth ?
                  // diabled button
                  <Btn attrBtn={{
                className: 'btn btn-danger', color: 'primary', onClick: () => {
                      // alert to confirm
                      if (window.confirm('Are you sure you wish to disable 2fa?')) {
                        disable2fa();

                      }
                      
                     }
                  }} >Disable 2fa</Btn>
                  :
                  // enable button
                  <></>
                }


            
         
          </CardBody>
        </Card>
      </Col>
    </Fragment >
  );
};
export default Security;