import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import UsersProfileContain from '../../../Component/Users';
import api from '../../../api/jwt'

const UsersProfile = () => {
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('');
  const [user, setUser] = useState([]);
  const [business, setBusiness] = useState([]);
  useEffect(() => {
    document.title = 'User Account - Spendo';
    setName(sessionStorage.getItem('Name')); 
    const businesss = JSON.parse(sessionStorage.getItem('business'));
    setBusiness(businesss);
    const authenticated = JSON.parse(sessionStorage.getItem('authenticated'));
    const auth0_profile = JSON.parse(sessionStorage.getItem('auth0_profile'));
    const business_list = JSON.parse(sessionStorage.getItem('business_list'));
    const user_data = sessionStorage.getItem('user_data');
    api.get('/user')
      .then(res => {
        setUser(res.data);
      })
      .catch(err => {

      });

  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="User" title="User Account" />
      <UsersProfileContain business={business} user={user} />
    </Fragment>
  );
};
export default UsersProfile;