import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import TransactionContain from '../../../Component/Admin/Transactions/index';
import api from '../../../api/jwt'
import LoadingSpinner from "../../../CommonElements/Loader/LoadingSpinner";

export default function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
   
  useEffect(() => {
    const pathname = window.location.pathname;

    if (pathname === '/admin/transactions') {
      setIsLoading(true);
      api.get('/admin/transactions/1/100/DESC/createdAt')
        .then(res => {
          setIsLoading(false)
          setTransactions(res.data.data.content);
        })
        .catch(err => {
          setIsLoading(false)
        });
    } else {
      const uuid = pathname.split('/').pop();
      setIsLoading(true);
      api.get('/admin/transactions/' + uuid)
        .then(res => {
        
          console.log(res.data.data)
          setIsLoading(false)
          setTransactions(res.data.data);
      })
        .catch(err => {
        console.log(err.response.data.message)
        setIsLoading(false)
      });
    } 
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="Transactions" />
      {isLoading ? <LoadingSpinner /> : <TransactionContain transactions={transactions} />}
      
    </Fragment>
  )
}
