import { H5, P, Btn } from "../../../AbstractElements";
import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import { useForm } from "react-hook-form";
import api from "../../../api/jwt";
import { Formik } from "formik";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";

const BusinessContain = (props) => {
  const { business } = props;

  const columns = [
    {
      name: "NAME",
      selector: (row) => row.businessName,
      sortable: true,
    },
    {
      name: "EMAIL",
      selector: (row) => row.businessRegNo,
      sortable: true,
    },
    {
      name: "PHONE",
      selector: (row) => row.businessPhone,
      sortable: true,
      right: true,
    },
    {
      name: "STATUS",
      selector: (row) => row.businessStatus,
      sortable: true,
      right: true,
    },
    {
      button: true,
      cell: (row) => (
        <>
          <Link to={`/admin/transactions/${row.walletId}`}>
            {" "}
            {row.walletId}{" "}
          </Link>
        </>
      ),
      name: "WALLET ID",
      sortable: true,
      right: true,
    },
    {
      name: "ACTION",
      button: true,
      sortable: true,
      right: true,
      cell: (row) => (
        <>
          <Link
            to={`/admin/businessesProfile/${row.uuid}`}
            className="btn btn-primary btn-sm"
          >
            <i className="fa fa-eye"></i>
          </Link>
        </>
      ),
    },
  ];
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                {/* <H5>Transactions</H5> */}

                <DataTable
                  // title="Transactions"
                  columns={columns}
                  data={business}
                  defaultSortFieldID={1}
                  pagination
                  striped={true}
                  center={true}
                  // paginationComponent={BootyPagination}
                  // selectableRows
                  // selectableRowsComponent={BootyCheckbox}
                />
              </CardHeader>
              <CardBody>
                <p>-</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default BusinessContain;
