import React, { Fragment, useState, useEffect, useCallback } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import BusinessContain from "../../../Component/Admin/Business";
import api from "../../../api/jwt";
import LoadingSpinner from "../../../CommonElements/Loader/LoadingSpinner";

export default function Business() {
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const [business, setBusiness] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setName(sessionStorage.getItem("Name"));
    const pathname = window.location.pathname;
    if (pathname === "/admin/businesses") {
      setIsLoading(true);
      api
        .get("/admin/businesses/0/100/DESC/createdAt")
        .then((res) => {
          setIsLoading(false);
          setBusiness(res.data.data.content);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      const uuid = pathname.split("/").pop();
      setIsLoading(true);
      api
        .get("/admin/business/" + uuid)
        .then((res) => {
          setIsLoading(false);
          setBusiness(res.data.data);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="Businesses" />
      {isLoading ? <LoadingSpinner /> : <BusinessContain business={business} />}
    </Fragment>
  );
}
