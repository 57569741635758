import React, { Fragment, useState, useEffect } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import AdminUserMailContain from "../../../Component/Admin/Users/mail";
import api from "../../../api/jwt";
import LoadingSpinner from "../../../CommonElements/Loader/LoadingSpinner";

const SendUserMail = () => {
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const [user, setUser] = React.useState([]);
  const [business, setBusiness] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // get uuid from url
  const pathname = window.location.pathname;
  const uuid = pathname.split("/").slice(-2)[0];

  useEffect(() => {
    setIsLoading(true);
    api
      .get("/admin/getUser/" + uuid)
      .then((res) => {
        console.log(res.data);
        setUser(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data)
        setIsLoading(false);
       });
  
  }, []);
  return (
    <Fragment>
      <Breadcrumbs parent="User" title="Send Mail" />
      {isLoading ? <LoadingSpinner /> : <AdminUserMailContain user={user} />}
    </Fragment>
  );
};
export default SendUserMail;
