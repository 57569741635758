import React, { useState, Fragment } from 'react';
import { Container, Row, Col, TabContent, TabPane } from 'reactstrap';
import VerifyForm from './VerifyForm';

const Verify = () => {
  return (
    <Fragment>
    <section>
        <Container fluid={true} className="p-0">
            <Row className="m-0">
                <Col xs='12' className="p-0">
                    <div className='login-card1'>
                        <VerifyForm />
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
</Fragment >
  );
};

export default Verify;