import React, { Fragment, useEffect } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import Contain from "../../Component/Cart/productImage";

const ProductImage = () => {
  useEffect(() => {
    document.title = "Product Image - Spendo";
  }, []);

    // get pathname from url
    const pathname = window.location.pathname;
    const productId = pathname.split('/').pop();
  return (
    <Fragment>
      <Breadcrumbs parent="ProductImage" title="ProductImage" />
      <Contain productId={productId}  />
    </Fragment>
  );
};
export default ProductImage;
