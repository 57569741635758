import React, { useState, Fragment } from 'react';
import { Container, Row, Col, TabContent, TabPane } from 'reactstrap';
import PayCard from './PayCard';

const Pay = () => {
    const [order, setOrder] = React.useState([
        {
            "createdAt": "2022-12-01T14:26:01Z",
            "updatedAt": "2022-12-01T14:26:01Z",
            "id": 43,
            "amount": 122050,
            "recipientPhone": "2347037716490",
            "recipientName": "Samiul Haqq",
            "orderId": 954306308,
            "paymentStatus": "pending",
            "paymentMethod": "cash_on_delivery",
            "orderTotal": 1,
            "orderStatus": "pending",
            "deliveryStatus": "pending",
            "deliveryAddress": "Azare, Bauchi Emir of Katagum's Palace",
            "businessId": "981a09f3-c00d-4527-a4a4-485611c6801e",
            "productId": 1662938849735
        }
    ]);
  return (
    <Fragment>
    <section>
        <Container fluid={true} className="p-0">
            <Row className="m-0">
                <Col xs='12' className="p-0">
                    <div className='login-card1'>
                        <PayCard order={order} />
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
</Fragment >
  );
};

export default Pay;