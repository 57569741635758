import React, { Fragment, useEffect } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import ContactsContain from '../../Component/Contacts';

const ContactsOverview = () => {
  useEffect(() => {
    document.title = 'Contacts Overview - Spendo';
  }, []);

  return (
    <Fragment>
      <Breadcrumbs parent="Services" title="Contacts Overview" />
      <ContactsContain />
    </Fragment>
  );
};
export default ContactsOverview;